<template>
  <div>
    <Header />
    <Bannerfull1
      imageName1="fastmonitorbg.png"
      imageName2="fasttaxmoniimage2.svg"
      mobimageName="fasttaxmoniimage2.svg"
      mobimageName1="mobilebg.png"
      :desc="desc1"
      block="rightblockwhite1"
      headert="headertext"
      content="contentprodtextright"
    />
    <div class="smallmonblock hidden-md-and-down">
      <v-row>
        <v-col lg="3" sm="12">
          <v-card
            :elevation="hover ? 6 : 0"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="300"
          >
            <v-card-text>
              <img class="LogoAnimationmonblock" src="../image/portfolio.svg" alt="Portfolio"/>
              <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                Portfolio Assignment & Personalized Dashboard
              </p>
              <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                Seamless process to create your individual loan portfolios
                (Investor/ Lender/State) and our personalized dashboard gives
                quick health check on your servicing portfolios.
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="3" sm="12">
          <v-card
            :elevation="hover ? 6 : 0"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="300"
          >
            <v-card-text>
              <img
                class="LogoAnimationmonblock"
                src="../image/tax_retreival.svg"
                alt="Tax"
              />
              <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                Tax Retrieval & Maintenance
              </p>
              <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                Multiple features to monitor your portfolios, never miss your
                critical dates (Bill Release Dates, Due Dates, ELD & Delinquency
                tracker)
              </div>
            </v-card-text>
          </v-card> </v-col
        ><v-col lg="3" sm="12">
          <v-card
            :elevation="hover ? 6 : 0"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="300"
          >
            <v-card-text>
              <img class="LogoAnimationmonblock" src="../image/tax_watch.svg" alt="Tax Watch" />
              <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                Tax Watch
              </p>
              <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                Forecast tax payment dues and plan on your pay-outs. Evidence
                based tracker.
              </div>
            </v-card-text>
          </v-card> </v-col
        ><v-col lg="3" sm="12">
          <v-card
            :elevation="hover ? 6 : 0"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            max-width="300"
          >
            <v-card-text>
              <img
                class="LogoAnimationmonblock"
                src="../image/customized.svg"
                alt="Customized"
              />
              <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                Customized Reporting – Milestone Based
              </p>
              <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                You can customize your reports at org level. Some report types
                delinquency alert report, Portfolio health report, Critical tax
                status, Payout report.
              </div>
            </v-card-text>
          </v-card>
        </v-col></v-row
      >
    </div>

    <div class="smallmonblock hidden-lg-and-up">
      <v-row>
        <v-col lg="3" sm="12">
          <v-hover open-delay="200">
            <v-card class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationmonblock"
                  src="../image/portfolio.svg"
                  alt="Portfolio"
                />
                <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                  Portfolio Assignment & Personalized Dashboard
                </p>
                <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                  Seamless process to create your individual loan portfolios
                  (Investor/ Lender/State) and our personalized dashboard gives
                  quick health check on your servicing portfolios.
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col> </v-row
      ><v-row>
        <v-col lg="3" sm="12"
          ><v-hover open-delay="200">
            <v-card
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="300"
            >
              <v-card-text>
                <img
                  class="LogoAnimationmonblock"
                  src="../image/tax_retreival.svg"
                  alt="Tax"
                />
                <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                  Tax Retrieval & Maintenance
                </p>
                <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                  Multiple features to monitor your portfolios, never miss your
                  critical dates (Bill Release Dates, Due Dates, ELD &
                  Delinquency tracker)
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover open-delay="200">
            <v-card
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="300"
            >
              <v-card-text>
                <img
                  class="LogoAnimationmonblock"
                  src="../image/tax_watch.svg"
                  alt="Tax watch"
                />
                <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                  Tax Watch
                </p>
                <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                  Forecast tax payment dues and plan on your pay-outs. Evidence
                  based tracker.
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover open-delay="200">
            <v-card
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              max-width="300"
            >
              <v-card-text>
                <img
                  class="LogoAnimationmonblock"
                  src="../image/customized.svg"
                  alt="Customixed"
                />
                <p class="block-monitortitle" :class="{ 'on-hover': hover }">
                  Customized Reporting – Milestone Based
                </p>
                <div class="block-monitordesc" :class="{ 'on-hover': hover }">
                  You can customize your reports at org level. Some report types
                  delinquency alert report, Portfolio health report, Critical
                  tax status, Payout report.
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      >
    </div>

    <Design14
      header="FASTTAX MONITORING"
      desc1="Our automated life of loan tax tracking solution offers you a complete suite of tax tracking modules to have an adequate check on the portfolios that you service."
      desc2="Get a complete visibility on how your portfolios are performing through personalized dashboards that meet your requirements."
      imagepath="fasttaxmoniillu.svg"
      button="Ask for a quote"
    />
    <Taxmonitorrange />
    <!-- <Design24 /> -->
    <!-- <Design17 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfull1 from "../components/Bannerfull1.vue";
import Design10 from "../components/Design10.vue";
import Design14 from "../components/Design14.vue";
// import Design24 from "../components/Design24.vue";
import Header from "../components/Header.vue";
import Taxmonitorrange from "../components/Range_Titlemonitor.vue";
export default {
  name: "Taxcert",
  data() {
    return {
      header1: "FastTax Monitor",
      desc1: "",
      button1: "Ask for a Quote",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Products");
  },
  components: {
    Bannerfull1,
    Design10,
    Design14,
    // Design24,
    Header,
    Taxmonitorrange,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}

.smallmonblock {
  margin: 70px 8em 20px;
}

.smallmonblock .v-card {
  height: 400px;
}

.LogoAnimationmonblock {
  width: 200px;
  height: 155px;
}

@media only screen and (max-width: 960px) {
  .smallmonblock {
    margin: 20px 10px;
  }
}

/* .v-card.on-hover { */
/* background-color: rgb(236, 246, 248); */
/* cursor: pointer;
} */

.block-monitortitle {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.42px;
  padding-top: 30px;
  color: #000;
}

.block-monitordesc {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.42px;
  color: #000;
}

/* .v-card.on-hover {
  color: #d7fff6;
} */
</style>
