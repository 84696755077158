import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import vuetify from "@/plugins/vuetify";
import VueRouter from "vue-router";
import router from "./router.js";
import store from "./store.js";
import Vuex from "vuex";
import "animate.css";
import cookieconsent from "vue-cookieconsent-component";
import vSelectMenu from "v-selectmenu";
import VueAnalytics from "vue-analytics";
import VueMeta from "vue-meta";

const axios = require("axios");
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
Vue.use(vSelectMenu);
Vue.component("cookie-consent", cookieconsent);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(VueMeta);
Vue.use(VueAnalytics, {
  id: "UA-12345678-1",
  router,
});
Vue.config.productionTip = false;

new Vue({ vuetify, router, store, render: (h) => h(App) }).$mount("#app");
