<template>
  
  <div id="banner">
    <v-row no-gutters>
      <v-col class="hidden-md-and-down" lg="12" sm="12">
        <v-card flat tile color="transparent">
          <img
            class="bannerimage1 animate__animated animate__slideInDown animate__faster"
            :src="require('../image/' + imageName1 + '')"
            :alt="imageName1"
          />
          <img
            class="bannerimage2 animate__animated animate__fadeIn animate__faster"
            :src="require('../image/' + imageName2 + '')"
            :alt="imageName2"
          />

          <v-btn  v-if="ulrsbutton"
                                    class="bannerdemobutton"
                                     rounded  
                                     color="#FA7E5C" 
                                     small 
                                      @click="redirecttoulrs()">
                                      Explore Now
                                    </v-btn>

          <v-card :class="block" flat tile color="transparent">
            <p v-if="header" :class="headert">
              {{ header }}
            </p>
            <p v-if="desc" :class="content">
              {{ desc }}
            </p>
            <v-btn
              v-if="button"
              :class="bannerbutton"
              rounded
              height="35px"
              color="#FA7E5C"
              dark
              small
              :to="route"
            >
              <v-icon left dark> mdi-account </v-icon>
              {{ button }}
            </v-btn>
          </v-card>
        </v-card>
      </v-col>
      <v-col class="hidden-lg-and-up" lg="12" sm="12">
        <v-card flat tile color="transparent">
          <img
            class="mobbannerimage"
            :src="require('../image/' + mobimageName1 + '')"
            :alt="mobimageName1"
          />
          <img
            class="mobbannerimage2"
            :src="require('../image/' + mobimageName + '')"
            :alt="mobimageName"
          />
          <v-btn  v-if = "ulrsbutton" class="bannermobdemobutton" @click="redirecttoulrs()" rounded color="#FA7E5C" small>
            <!-- <v-icon left dark> mdi-account </v-icon> -->
            Explore Now
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="header || desc || button" class="hidden-lg-and-up">
      <v-col sm="12">
        <v-card flat tile color="transparent">
          <p v-if="header" :class="header">
            {{ header }}
          </p>
          <p v-if="desc" :class="content">
            {{ desc }}
          </p>
          <v-btn
            v-if="button"
            :class="bannerbutton"
            rounded
            color="#FA7E5C"
            dark
            small
          >
            <v-icon left dark> mdi-account </v-icon>
            {{ button }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data: () => ({
    alignments: ["start", "center", "end"],
  }),
  props: [
    "imageName1",
    "imageName2",
    "imageName3",
    "mobimageName",
    "mobimageName1",
    "header",
    "desc",
    "button",
    "ulrsbutton",
    "block",
    "route",
    "headert",
    "content",
    "bannerbutton",
  ],
  computed: {},
  methods: {
    redirecttoulrs() {
      window.open(`https://ulrs.stellaripl.com`, "_blank");
        },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bannerimage1 {
  height: 419px;
  width: 100%;
}

.bannerimage2 {
  position: absolute;
  left: 140px;
  max-height: 380px;
  top: 50px;
}

.bannerimage3 {
  height: 419px;
  width: 100%;
}

.mobbannerimage {
  height: 200px;
  width: 100%;
}

.mobbannerimage2 {
  height: 170px;
  /* width: 100%; */
  position: absolute;
  left: 0px;
  right:0px;
  margin-left: auto;
  margin-right: auto;
}
.bannerbutton {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
  margin-left: 15px;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: 25px;
}
.bannermobdemobutton {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
position: initial;
  /* float: right; */
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
margin-top:-70px;

}

.bannerdemobutton {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
  margin-right: 28em;
  float: right;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: -72px;
}

.animate__animated.animate__slideInRight {
  --animate-duration: 3s;
}

.bannerbuttonright {
  margin-left: 15px;
  float: right;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #186e7e;
  opacity: 1;
  margin-top: 25px;
}
.leftblock {
  position: absolute;
  top: 100px;
  max-width: 800px;
  padding-left: 8em;
}
.rightblock {
  position: absolute;
  top: 100px;
  max-width: 700px;

  right: 8em;
}

.rightblockwhite1 {
  color: #186e7e;
  position: absolute;
  top: 270px;
  max-width: 800px;
  padding-left: 100px;
  padding-right: 100px;
  right: 40px;
}

.rightblockwhite1 .contenttext1 {
  color: #186e7e;
  text-align: left;
  padding: 0px;
}

.headertextright {
  padding: 0px;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-size: 44px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #1c1c1c;
  opacity: 1;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.contenttextright {
  padding: 0px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: right;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: #1c1c1c;
  opacity: 1;
}

.headertext1 {
  padding: 0px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 46px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #1c1c1c;
  opacity: 1;
  padding-bottom: 0px;
  padding-right: 60px;
  margin-bottom: 0px;
}

.rightblockwhite1 .headertext1 {
  color: #186e7e;
  text-align: right;
  padding-right: 8em;
}

.colorinherit {
  background-color: #bebebe1d;
}

.contentprodtextright {
  padding: 0px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.24px;
  color: #186e7e;
  opacity: 1;
  margin-right: 58px;
}

.contenttext1 {
  padding: 0px 0px;
  position: absolute;
  top: 100px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: #1c1c1c;
  opacity: 1;
  padding-right: 100px;
}

#banner {
  /* margin-top: 60px; */
  background-color: White;
}

@media only screen and (max-width: 960px) {
  #banner {
    /* margin-top: 60px; */
  }

  .bannerbutton {
    float: none;
    width: 80%;
  }

  .bannerbuttonright {
    float: none;
    width: 80%;
  }

  .headertext1 {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #1c1c1c;
    opacity: 1;
  }

  .headertext1,
  .contenttext1,
  .contenttextright,
  .headertextright {
    padding: 0px 10px;
    text-align: justify;
  }

  .contentprodtextright {
    /* display: none; */
    padding: 0px 16px;
    margin-right: 0px;
  }

  .bannerimage1 {
    max-width: -webkit-fill-available;
  }
}

@media only screen and (max-width: 1280px) {
  .bannerimage2 {
    max-width: 450px;
  }
}
</style>
