<template>
  <div>
            <Design12 />
    <Design11 />

    <div class="design4 contentimage">
      <div class="sectiondesc">
        <v-row style="width: 100%;">
          <v-col lg="6" sm="12" class="imageleft hidden-md-and-down">
            <img class="imagewidth" src="../image/mortgagemimage1.png" alt="Mortgage Services"/>
          </v-col>
          <v-col lg="6" sm="12">
            <h2 class="contenthead">
              Why choose ‘Stellar Innovations’ as your ‘Strategic Sourcing Partner’?
            </h2>
            <p class="contentdesc">
              Transform and optimize your mortgage processes leveraging Steller’s Digital Mortgage Solutions coupled with domain expertise and trained workforce.  
            </p>

            <ul class="contentlist">
              <li>
                <span class="contentlisthead"
                  >Technology enabled services customised based on client’s requirements</span
                >
              </li>
              <li>
                <span class="contentlisthead">End to End service offering including Origination, Servicing & Due Diligence</span>
              </li>
              <li>
                <span class="contentlisthead">Ability to manage multiple products including QM, Jumbo, Non-QM, HELOC, Bridge, Fix & Flip, Reverse mortgages</span>
              </li>
              <li>
                <span class="contentlisthead"
                  >Trained staff with 20% bench capacity ensuring quick ramp-up</span
                >
              </li>
               <li>
                <span class="contentlisthead"
                  >Flexible pricing model with no minimum volume commitment</span
                >
              </li>
               <li>
                <span class="contentlisthead"
                  >4 delivery centres including onshore and offshore active BCP sites</span
                >
              </li>
                <li>
                <span class="contentlisthead"
                  >ISO 27001 Certified –International information security standard</span
                >
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-btn
          class="cardtextbutton"
          to="/contact-us"
          rounded
          dark
          height="35px"
        >
          Contact us
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Design11 from "../components/Design11.vue";
import Design12 from "../components/Design12.vue";
export default {
  name: "Design4",
  props: {},
  components: { Design11 ,  Design12},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design4 {
  /* background-image: url(../image/banner/triangle.png); */
  padding: 35px 8em 30px;
  background-color: white;
  /* background-position: center 25px;
  background-size: 70% 700px; */
}

.iconview {
  text-align: left;

  /* box-shadow: 0 0 3px 2px #fff; */
}

.iconview .v-icon {
  font-size: 55px !important;
}
.titlecontent {
  width: 50%;
  margin: auto;
}

.smallbutton {
  /* padding: 0px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 250px; */
  display: none;
  text-align: left;
}

.LogoAnimation {
  max-width: 55px;
}

.cardcontent {
  /* margin: auto; */
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
}

.sectiondesc {
  margin-top: 70px;
}
.roundcontent {
  /* margin-top: -120px; */
  /* margin: auto; */

  /* background: transparent
    radial-gradient(
      closest-side at 50% 42%,
      #4a7e88 0%,
      #78b2bed1 0%,
      #3a565b00 100%
    )
    0% 300% no-repeat padding-box; */
}

.sectiondesc .row{
margin:0px;
}

.contentpadding {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}
.roundcontenttext {
  margin: auto;
  text-align: center;
  opacity: 1;
}

.sectiontitle {
  padding-top: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 0.5;
}

.cardtitle {
  padding-top: 20px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtitletext {
  padding-top: 12px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.v-card__text {
  padding: 0px !important;
}

.imagewidth {
  /* box-shadow: 0px 0px 40px #0000003c; */
  width: 95%;
  max-height: 400px;
  border-radius: 10px;
  padding-top: 30px;
}

.imageleft {
  margin-top: 30px;
  text-align: center;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: #FA7E5C;
  opacity: 1;
  padding-bottom: 22px;
}

.contentlisthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.contentlistdesc {
  padding-top: 8px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtextbutton {
  height: 35px;
  width: 120px;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-top: 24px !important;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin: auto;
}
.contentdesc {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.27px;
  color: rgba(0, 0, 0, 0.6);
  /* padding-bottom: 40px; */
}

.contentlist {
  margin-top: 40px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}
ul {
  list-style: none;
  padding-left: 0px;
}
li {
  margin: 10px 10px 10px 0px;
  padding: 0px 0px 0px 42px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: 0px -2px;
  background-size: 25px;
  /* height: 40px; */
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 960px) {
  .design4 {
    padding: 20px 20px;
    background-position: top 50% left 50%;
    background-image: none;
  }

  .cardtextbutton {
    width: 100%;
  }

  .titlecontent {
    width: 100%;
  }

  .cardcontent {
    max-width: 100% !important;
  }

  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .contentpadding {
    padding-top: 12px;
  }

  .iconview,
  .cardtitle {
    text-align: center;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: justify;
  }
}
</style>
