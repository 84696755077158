<template>
  <div>
    <Bannerfulltrans
      imageName="nocimage1.svg"
      imageName1="nocimage2.svg"
      mobimageName="nocimage3.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />
    <Design14
      header="NOC & SOC Services"
      desc1="Your IT infrastructure grows when your business grows and it needs a meticulous plan to monitor and manage. Outages in IT infrastructure leads to impact on business and adds cost to company. Stellar Innovations NOC and SOC services provide an all-round approach to make your infrastructure ready for growing business demand, competition and make it available all times."
      desc2="Cyber threats are evolving faster than ever. With new vulnerabilities, zero-days and threats are being discovered every day and are evolving. Addressing this can be a big challenge for IT teams. Stellar Innovations brings in new generation of orchestrated security operations that are flexible, on-demand and deliver significant services for your business."
      imagepath="noc1.svg"
    />
    <Nocsocbenefit />
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design14 from "../components/Design14.vue";
import Nocsocbenefit from "../components/Nocsocbenefit.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Title",
  data() {
    return {
      header1: "NOC & SOC Services",
      desc1: "",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design14,
    Nocsocbenefit,
    // Design16,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
