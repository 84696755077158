<template>
    <div>
      <Header />
      <Bannerfull1
        imageName1="Ulrsbg.png"
        imageName2="UlrsBanner.svg"
        mobimageName="UlrsMobBanner.svg"
        mobimageName1="mobilebg.png"
        block="rightblockwhite1"
        headert="headertext"
        content="contentprodtextright"
        ulrsbutton=true
      />
      <DesignUlrsMortagage
      header="ABOUT ULRS"
      desc1="‘ULRS – Universal Loan Review System’, an AI based self-learning platform, is capable of understanding Mortgage documents, data set & business rules. It can facilitate complete automation of below loan onboarding functions:"      
      desc2="It is highly scalable to configure and drive any given review scope and product line for the flexibility it offers with configurable embedded features."
      imagepath="Ulrsmortgage.svg"
    />
  
    <UlrsServices />
    <Design11 />
    <UlrsPortfolio />
    <Design10 />
    </div>
  </template>
  
  <script>
  import Bannerfull1 from "../components/Bannerfull1.vue";
  import UlrsServices from "../components/UlrsServices.vue";
import UlrsPortfolio from "../components/UlrsPortfolio.vue";
import Design10 from "../components/Design10.vue";
import Design11 from "../components/Design11.vue";
import DesignUlrsMortagage from "../components/DesignUlrsMortagage.vue";
 
  import Header from "../components/Header.vue";
 
  export default {
  name: "Home",
  data() {
    return {
      isLoading: true,
      header1: "Extensive Title Production Services",
      desc1:
        "Detailed title production for informed decision-making while issuing Title policy, Re-sale, Re-finance, or Foreclosure Auction and much more.",
      button1: "Learn More",
      header2: "End-to-end Escrow & Mortgage Services",
      desc2:
        "Ease of property transaction at its best with an end-to-end reviewed, recorded, & customized tracking of escrow and mortgage activities.",
      button2: "Learn More",
      header3: "Verified Appraisal Services",
      desc3:
        "Verified property appraisal ensuring precise valuation based on the key market indicators from credible sources and cross-checked by experts.",
      button3: "Ask for a Quote",
      blocktitle1: "TIME",
      blockdesc1:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.",
      blocktitle2: "ACCURACY",
      blockdesc2:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.",
      blocktitle3: "TASK COMPLETION",
      blockdesc3:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.",
    };
  },

  components: {
    Bannerfull1,
    Header,
    UlrsServices,
    UlrsPortfolio,
    Design10,
    Design11,
    DesignUlrsMortagage
},
  };
  </script>
  
  <style>
  @media only screen and (max-width: 960px) {
    .v-window__next,
    .v-window__prev {
      top: calc(70% - 20px);
    }
  }
  
  .smallmonblock {
    margin: 70px 8em 20px;
  }
  
  .smallmonblock .v-card {
    height: 400px;
  }
  
  .LogoAnimationmonblock {
    width: 200px;
    height: 155px;
  }
  
  @media only screen and (max-width: 960px) {
    .smallmonblock {
      margin: 20px 10px;
    }
  }
  
  /* .v-card.on-hover { */
  /* background-color: rgb(236, 246, 248); */
  /* cursor: pointer;
  } */
  
  .block-monitortitle {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.42px;
    padding-top: 30px;
    color: #000;
  }
  
  .block-monitordesc {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.42px;
    color: #000;
  }
  
  /* .v-card.on-hover {
    color: #d7fff6;
  } */
  </style>
  