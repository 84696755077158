<template>
  <div>
    <Bannerfulltrans
      imageName="duediligence.svg"
      imageName1="duebanner.svg"
      mobimageName="mobdue.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />

    <Design28
      header="Let us save you time and cost:"
      desc1="Mortgage due diligence is of paramount importance in the mortgage industry as it involves a comprehensive review and analysis of mortgage loan documents and data"
      header1=" Stellar Due Diligence Services"
      desc3="Stellar Innovations is a forward-thinking technology and financial services company that specializes in providing cutting-edge solutions for the mortgage industry. One of the critical services offered by Stellar Innovations is mortgage due diligence."
     
      desc2="Our process ensures the accuracy, compliance, and quality of mortgage loans, reducing the risk for lenders, servicers, and investors. It helps identify potential issues, such as fraud, misrepresentations, or errors in loan documentation, which can have significant financial and legal consequences. 
Stellar Innovations' expertise in mortgage due diligence helps streamline operations, improve loan quality, and mitigate risks in the complex world of mortgage lending.
"
      button="Contact Us"
      :bulletinfo="bulletinfo"
      imagepath="duediligence3.svg"
    />
    <Design11 />
    <Design29
      header="Our services include:"
      desc1="We offer securitization, bulk, and flow mortgage due diligence services, which can all include a reliance letter, data tape, and/or attorney letter."      
      imagepath="duediligencesplit.svg"
    />
<Design10/>

  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design28 from "../components/Design28.vue";
import Design29 from "../components/Design29.vue";

import Design11 from "../components/Design11.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Duediligence",
  data() {
    return {
      header1: "Due Diligence Services",
      desc1:
        "Acceptable third-party due-diligence firm",
      button1: "",
      bulletinfo: [
        "Our software is designed to help clients acquire/originate more loans, lower costs, reduce time to close, and make smarter decisions.",
        "Our team is trained to help clients minimize risk and maximize outcomes.",
        "Services to identify potential issues or deal breakers early to enable informed decisions that covers both domestic and cross-border transactions.",
        "Platform provides clients access with Interactive Data Upload, Loan Review, Buyer & Seller Responses, and Timely Transparent and Accurate results.",
        "Trusted by clients to efficiently evaluate underwriting guidelines, regulatory compliance requirements, transaction data tape, and representations and warranties of any transaction.",
        "Platform provides all parties the independence, transparency and disclosure required by rating agencies and industry regulation.",
        "Services that combine the expertise of seasoned mortgage industry professionals with the efficiency of state-of-the-art technology.",
        "Services customized to meet individual client needs. We even have the ability to offer additional choices to our clients through staff augmentation and innovative technology."
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design28,
    Design29,
    Design10,
    Design11
  }
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
