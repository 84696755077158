<template>
  <div id="contactus">

    <!-- <img class="gimagewidth" src="../image/gmap.svg" /> -->
    <div class="hidden-md-and-down text-center">
      <v-row>
        <v-col lg="9" sm="12">
        <img class="gimagewidth" src="../image/gmap.svg" alt="Address"/>
        </v-col>
        <v-col lg="3" sm="12">
        <v-col lg="12" sm="12">
          <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="270">
            <v-card-text>
              <v-btn @click="openGoogletinyusaMap()" class="mx-2" fab dark x-small color="primary">
                <v-icon dark> mdi-map-marker </v-icon>
              </v-btn>
              <span class="countrytext">Florida, USA</span>
              <div class="addresstext">2605 Maitland Center <br>PKWY - STE. F Maitland,<br> FL 32751-4131 USA.</div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="12" sm="12">
          <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="270">
            <v-card-text>
              <v-btn @click="openGoogletinydubaiMap()" class="mx-2" fab dark x-small color="pink">
                <v-icon dark> mdi-map-marker </v-icon>
              </v-btn>
              <span class="countrytext">Dubai, UAE</span><br>
              <div class="addresstext">Office number 823 & 825 <br>Business Village, Port Saeed - Dubai, United Arab Emirates.</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" sm="12">
          <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="270">
            <v-card-text>
              <v-btn @click="openGoogletinybloreMap()" class="mx-2" fab dark x-small color="indigo">
                <v-icon dark> mdi-map-marker </v-icon>
              </v-btn>
              <span class="countrytext">Bangalore, India</span>
              <div class="addresstext">TIRUMALA, #27,Doddanekundi, <br>Phase II Industrial Area, <br> Mahadevepura Post, <br> Bangalore-560048.</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" sm="12">
          <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="270">
            <v-card-text>
              <v-btn @click="openGoogletinysholinghurMap()" class="mx-2" fab dark x-small color="teal">
                <v-icon dark> mdi-map-marker </v-icon>
              </v-btn>
              <span class="countrytext">Sholinghur, India</span>
              <div class="addresstext">#25/2/2, Arakkonam Road,<br>  Near SBI, Sholinghur, <br>Ranipet District, <br>Vellore– 631102</div>
            </v-card-text>
          </v-card>
        </v-col>
        </v-col>
      </v-row>
    </div>
    <div class="hidden-lg-and-up text-center">
      <v-row>
        
        <v-col class="mobview">
          <v-btn @click="openGoogletinyusaMap()" class="mobview ma-2" fab dark x-small color="primary">
            <v-icon dark> mdi-map-marker </v-icon>
          </v-btn>
          <span class="countrytext">Florida, USA</span>
          <div class="Mobaddresstext">2605 Maitland Center  <br>PKWY - STE. F  <br>Maitland, FL 32751-4131 USA.</div>
        </v-col></v-row>
      <v-row><v-col class="mobview">
          <v-btn @click="openGoogletinydubaiMap()" class="mx-2" fab dark x-small color="pink">
            <v-icon dark> mdi-map-marker </v-icon>
          </v-btn>
          <span class="countrytext">Dubai, UAE</span> 
          <div class="Mobaddresstext">Office number 823 & 825 <br>Business Village, <br>Port Saeed - Dubai, <br>United Arab Emirates.</div>
                     
        </v-col></v-row>
      <v-row><v-col class="mobview">
          <v-btn @click="openGoogletinybloreMap()" class="mx-2" fab dark x-small color="indigo">
            <v-icon dark> mdi-map-marker </v-icon>
          </v-btn>
          <span class="countrytext">Bangalore, India</span>
          <div class="Mobaddresstext">TIRUMALA, #27,Doddanekundi, <br>Phase II Industrial Area, <br>Mahadevepura Post, <br>Bangalore-560048.</div>
            
        </v-col></v-row>
      <v-row><v-col class="mobview">
          <v-btn @click="openGoogletinysholinghurMap()" class="mx-2" fab dark x-small color="teal">
            <v-icon dark> mdi-map-marker </v-icon>
          </v-btn>
          <span class="countrytext">Sholinghur, India</span>
          <div class="Mobaddresstext">#25/2/2, Arakkonam Road, <br>  Near SBI, <br> Sholinghur, Ranipet District, <br>Vellore – 631102</div>
            
        </v-col></v-row>
    </div>
    <div class="hidden-md-and-down text-center contactinfo">
      <v-icon> mdi-email </v-icon>
      <a href="mailto:contact@stellaripl.com?subject=Enquiry&body=Hello!"
        class="countrysmalltext">contact@stellaripl.com</a>
      <v-icon> mdi-phone </v-icon>
      <span class="countrysmalltext">1-(888) 778-3121</span>
    </div>
    <div class="hidden-lg-and-up text-left contactinfo">
      <v-row><v-col>
          <v-icon> mdi-email </v-icon>
          <a href="mailto:contact@stellaripl.com?subject=Enquiry&body=Hello!"
            class="countrysmalltext">contact@stellaripl.com</a></v-col></v-row>
      <v-row><v-col>
          <v-icon> mdi-phone </v-icon>
          <span class="countrysmalltext">1-(888) 778-3121</span></v-col></v-row>
    </div>
    <v-row>
      <v-col lg="6" sm="12" class="hidden-md-and-down">
        <img class="imagewidth" src="../image/Contactus.png" alt="Contact" /> </v-col><v-col lg="6" sm="12" class="contentinfo">
        <p class="titlevalue">CONTACT US</p>
        <p class="titlevaluedesc">
          To discuss your requirements or for a quick consultation, please fill
          in the contact form.
        </p>

        <img class="imagewidth hidden-lg-and-up" src="../image/contact.webp" alt="Contact Us" />

        <v-form ref="form" @submit.prevent="submitContact">
          <v-text-field v-model="name" background-color="#EBEBEB" id="textfield" placeholder="Name" solo
            :rules="nameRules" label="Name" required @input="changefield"></v-text-field>

          <v-text-field background-color="#EBEBEB" v-model="email" solo :rules="emailRules" label="E-mail" required
            @input="changefield"></v-text-field>

          <v-textarea v-model="comment" background-color="#EBEBEB" placeholder="Comment" solo :rules="commentRules"
            label="Comment" required @input="changefield"></v-textarea>

          <v-alert v-if="successVal" outlined type="success" text>
            Thank you for contacting us. We will review your request and get
            back to you at the earliest.
          </v-alert>

          <v-btn :disabled="!valid" color="#fa7e5c" class="cardtextbutton mr-4" rounded type="submit">
            Submit
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
const axios = require("axios");
export default {
  name: "Design9",
  props: {},
  data() {
    return {
      successVal: false,
      bangalore: "12.9149947,77.624631",
      sholingur: "13.106037, 79.424402",
      florida: "28.635384,-81.39674",
      dubai: "25.095297,55.1666674",
      valid: true,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          v.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "E-mail must be valid",
      ],
      comment: "",
      commentRules: [(v) => !!v || "Comment is required"],
    };
  },
  watch: {},
  mounted() { },
  methods: {
    changefield() {
      this.successVal = false;
    },
    // openGoogleMap(item) {
    //   window.open(
    //     `https://www.google.com/maps/search/?api=1&query=${item}`,
    //     "_blank"
    //   );
    // },
    openGoogletinybloreMap() {
      window.open(`https://goo.gl/maps/wu9Gk61adqggn7tY8`, "_blank");
    },
    openGoogletinysholinghurMap()
    {
      window.open(`https://goo.gl/maps/sNcuEQweAxHMXbZr6`, "_blank");
    },
    openGoogletinyusaMap() {
      window.open(`https://goo.gl/maps/mcn3skRhuChH5LTT7`, "_blank");
    },
    openGoogletinydubaiMap() {
      window.open(`https://goo.gl/maps/hbJn9gu9KgzxWSit8`, "_blank");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submitContact() {
      const datav = {};
      datav.name = this.name;
      datav.email = this.email;
      datav.comments = this.comment;
      emailjs
        .send(
          "service_unrcd8a",
          "template_x114bcv",
          { name: this.name, email: this.email, comments: this.comment },
          "user_JAriCGaLhJ5Tts2LqutKQ"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );

      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",

        "Content-Type": "application/json",
      };

      axios
        .post(
          `https://stellaripl.com/carrerapi/api/add-contact-us?name=${this.name}&email=${this.email}&comments=${this.comment}`,
          { headers }
        )
        .then(() => {
          this.successVal = true;
          this.name = "";
          this.email = "";
          this.comment = "";
          this.resetValidation();
        })
        .catch((error) => {
          console.log("Error ========>", error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contactus {
  background-color: white;
  padding: 120px 8em 40px 8em;
  text-align: left;
}

.contentinfo {
  padding: 0px 70px;
}

.imagewidth {
  height: 420px;
  width: 620px;
}

.v-card__text{
  padding:0px !important;
  text-align: initial;
}

.gimagewidth {
  height: 500px;
  width: 100%;
  margin-top:35px;

}

.mobview {
  text-align: left;
  margin-top:50px;
}

.countrytext {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 800;
  color: #151515;
  opacity: 1;
  padding-right: 50px;
}

.contactinfo {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 35px;
}

.countrysmalltext {
  padding: 0px 50px 0px 5px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 800;
  color: #151515;
  opacity: 1;
}

.formfield {
  width: 80%;
  margin: auto;
}

#textfield {
  border: none;
}

.titlevalue {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.45px;
  color: #FA7E5C;
  opacity: 1;
}



.cardtextbutton {
  width: 120px;
  font-size: 0.9em !important;
  font-weight: bold;
  margin-top: 15px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
  color: #fff;
}

.addresstext
{
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #151515;
  padding: 12px;
}

.Mobaddresstext
{
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #151515;
  padding: 16px 32px 16px 8px;
}


.v-label {
  color: #186e7e !important;
}

.centerbutton {
  text-align: center;
}

.titlevaluedesc {
  text-align: center;
  /* font: normal normal normal 22px/30px Montserrat; */
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.33px;
  color: #151515;
  opacity: 1;
}

.v-input__slot {
  border-color: null;
}

.v-text-field.v-label {
  color: #4c7595 !important;
}

@media only screen and (max-width: 960px) {
  #contactus {
    padding: 30px 30px;
  }

  .cardtextbutton {
    width: 100%;
  }

  .imagewidth {
    width: -webkit-fill-available;
    height: unset;
    padding-bottom: 20px;
  }

  .contentinfo {
    padding: 0px 20px;
  }
}
</style>
