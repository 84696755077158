<template>
  <div class="sectioncontent">
    <v-card class="cardinfo mx-auto hidden-md-and-down" max-width="700">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                class="fieldinput"
                v-model="designationval"
                label="Job title / Keyword"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                class="fieldinput"
                label="location"
                v-model="locationvalue"
                prepend-inner-icon="mdi-map-marker"
              ></v-text-field>
            </v-col>
            <v-col cols="4"
              ><v-card-actions>
                <v-btn
                  class="cardtextbutton"
                  height="35px"
                  rounded
                  dark
                  @click="submitSearch"
                >
                  Search
                </v-btn>
              </v-card-actions></v-col
            >
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <v-card class="cardinfo mx-auto hidden-lg-and-up" max-width="700">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="fieldinput"
                v-model="designationval"
                label="Job title / Keyword"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                class="fieldinput"
                v-model="locationvalue"
                label="location"
                prepend-inner-icon="mdi-map-marker"
              ></v-text-field>
            </v-col>
            <v-col cols="12"
              ><v-card-actions>
                <v-btn
                  class="cardtextbutton"
                  height="35px"
                  rounded
                  dark
                  @click="submitSearch"
                >
                  Search
                </v-btn>
              </v-card-actions></v-col
            >
          </v-row>
        </v-container>
      </v-form>
    </v-card>
    <div
      class="mobileblock hidden-lg-and-up"
      v-for="item in jobitems"
      :key="item.index"
    >
      <v-row
        ><v-col>
          <img class="imagewidth"  :src= "require('../image/suitcase.png')" alt="Suitcase Icon"/>
          <div class="contentdes">{{ item.designation }}</div>
          <div class="contentloc">
            <v-icon x-small color="green darken-2"> mdi-map-marker </v-icon
            >{{ item.location }}
          </div></v-col
        ></v-row
      >

      <v-row
        ><v-col
          ><div class="contentloc">{{ item.job_description }}</div></v-col
        ></v-row
      >

      <v-row
        ><v-col
          ><v-row justify="center">
            <v-btn
              class="cardtextbuttonorange"
              href="https://candidatereg.stellarapps.net/login.php"
              target="_blank"
              dark
              height="30"
            >
              Apply Now
            </v-btn>
          </v-row>
        </v-col></v-row
      >
    </div>

    <v-progress-circular
      v-if="loading"
      :size="30"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-simple-table
      v-else
      class="hidden-md-and-down"
      fixed-header
      height="auto"
    >
      <template v-slot:default>
        <v-alert class="alertwindow" width="700" v-if="!jobitems"
          >No jobs matching your request. Please try later.</v-alert
        >
        <v-alert class="alertwindow" width="700" v-else-if="sucesssubmit"
          >Candidate information submitted successfully!!</v-alert
        >
        <tbody v-else>
          <tr v-for="item in jobitems" :key="item.index">
            <td min>
              <img class="imagewidth" 
              :src= "require('../image/suitcase.png')"
              alt="Suitcase Icon"/>
              <div class="contentdes">{{ item.designation }}</div>
              <div class="contentloc">
                <v-icon x-small color="green darken-2"> mdi-map-marker </v-icon
                >{{ item.location }}
              </div>
            </td>
            <td>
              <div class="contentloc">{{ item.job_description }}</div>
            </td>
            <td>
              <v-row justify="center">
                <!-- <v-dialog
                  v-model="dialog"
                  :retain-focus="false"
                  max-width="550px"
                >
                  <template v-slot:activator="{ on, attrs }"> -->
                <v-btn
                  class="cardtextbuttonorange"
                  dark
                  height="30"
                  href="https://candidatereg.stellarapps.net/login.php"
                  target="_blank"
                >
                  Apply Now
                </v-btn>
                <!-- </template>
                  <v-card class="cardinfoalert">
                    <v-card-title>
                      <span class="text-h5">Candidate Information</span>
                    </v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        v-model="firstname"
                        background-color="#EBEBEB"
                        id="textfield"
                        placeholder="First Name"
                        solo
                        :rules="nameRules"
                        label="First Name"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="lastname"
                        background-color="#EBEBEB"
                        id="textfield"
                        placeholder="Last Name"
                        solo
                        :rules="lastnameRules"
                        label="Last Name"
                        required
                      ></v-text-field>

                      <v-text-field
                        background-color="#EBEBEB"
                        v-model="email"
                        solo
                        :rules="emailRules"
                        label="E-mail"
                        required
                      ></v-text-field>

                      <v-textarea
                        v-model="comment"
                        background-color="#EBEBEB"
                        placeholder="Comment"
                        solo
                        :rules="commentRules"
                        label="Comment"
                        required
                      ></v-textarea>

                      <v-btn
                        :disabled="!valid"
                        color="#fa7e5c"
                        class="cardtextbutton mr-4"
                        @click="submitCandidate()"
                        rounded
                      >
                        Submit
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-dialog> -->
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
 
<script>
const axios = require("axios");

export default {
  name: "Design23",
  props: {},
  mounted() {
    this.loading = true;
    axios
      .get("https://stellaripl.com/carrerapi/api/career-search")
      .then((response) => {
        this.jobitems = response.data.data;
        this.loading = false;
      });
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    submitSearch() {
      this.sucesssubmit = false;
      this.loading = true;
      axios
        .get(
          `https://stellaripl.com/carrerapi/api/career-search?location=${this.locationvalue}&designation=${this.designationval}`
        )
        .then((response) => {
          this.jobitems = response.data.data;
          this.loading = false;
        });
    },
    submitCandidate() {
      this.dialog = false;
      this.sucesssubmit = true;
    },
    closeDialog() {
      this.dialog = false;
      // this.sucesssubmit = true;
    },
  },
  data() {
    return {
      disablebutton: true,
      firstname: "",
      lastname: "",
      email: "",
      successubmitVal: false,
      sucesssubmit: false,
      comment: "",
      valid: true,
      nameRules: [(v) => !!v || "First Name is required"],
      lastnameRules: [(v) => !!v || "Last Name is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      commentRules: [(v) => !!v || "Comment is required"],
      locationvalue: "",
      designationval: "",
      loading: false,
      dialog: false,
      isFetching: true,
      jobitems: [
        //   {
        //     location: "Bangalore",
        //     designation: "VueJS - Software Engineer",
        //     jobdesc:
        //       "Senior software engineer who brings considerable experience with application-layer development for both web and mobile web applications, using a broad range of technologies. Responsible for building user interfaces, both web and mobile web applications (Vue JS or other relevant frontend frameworks). Collaborate with development team in Agile Scrums.",
        //   },
        //   {
        //     location: "Sholingur",
        //     designation: "Software Engineer",
        //     jobdesc:
        //       "Proven data entry work experience, as a Data Entry Operator or Office Clerk. Experience with MS Office and data programs. Familiarity with administrative duties. Experience using office equipment, like fax machine and scanner. Typing speed and accuracy. Excellent knowledge of correct spelling, grammar and punctuation",
        //   },
        //   {
        //     location: "Bangalore",
        //     designation: "PHP - Software Engineer",
        //     jobdesc:
        //       "Good knowledge of SQL and related databases, with a preference for those with MySQL experience. Excellent knowledge of the basic PHP 7 or web server exploits along with their solutions. Experience building or maintaining a CMS. Knowledge of MVC frameworks. A detailed understanding of database design and administration.",
        //   },
        //   {
        //     location: "Bangalore",
        //     designation: "UI Developer",
        //     jobdesc:
        //       "Modern Front End JavaScript frontend framework libraries (such as ReactJS or AngularJS) NodeJS / JavaScript / NPM / JSON / HTML5 / CSS / CSS3, etc.Experience with frontend tools such as Gulp, Grunt, Brunch, Babel, Webpack, LESS or SASS. Good knowledge of Web responsiveness, scalability, performance, cross browser testing and debugging.",
        //   },
        //   {
        //     location: "Bangalore",
        //     designation: "Network Engineer",
        //     jobdesc:
        //       "Maintaining and administering computer networks and related computing environments including systems software, applications software, hardware and configurations. Performing disaster recovery operations and data backups when required. Protecting data, software and hardware by coordinating, planning and implementing network security measures. Troubleshooting, diagnosing and resolving hardware, software, and other network and system problems.",
        //   },
        //   {
        //     location: "Bangalore",
        //     designation: ".Net Developer",
        //     jobdesc:
        //       "Write clean, scalable code using .NET programming languages. Test and deploy applications and systems. Revise, update, refactor and debug code. Improve existing software. Develop documentation throughout the software development life cycle (SDLC). Serve as an expert on applications and provide technical support",
        //   },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sectioncontent {
  margin-left: 8em;
  margin-right: 8em;
  padding-bottom: 25px;
  margin-top: 100px;
}

.cardinfo {
  margin-bottom: 50px;
}

.slotinfo {
  border-bottom: 1px solid #c9c9c9;
}
.fieldinput {
  max-width: 100%;
}

td:first-child {
  min-width: 325px;
}

.mobileblock {
  margin-top: 25px;
}

.cardinfoalert {
  padding: 10px;
}

td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.alertwindow {
  margin: auto;
  background-color: #fa7e5c;
  font-size: 20px;
  color: white;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.imagewidth {
  width: 40px;
  float: left;
  margin-left: 50px;
}
.v-icon {
  top: -2px;
}
.contentdes {
  padding-left: 100px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.21px;
  color: #151515;
  opacity: 1;
}
.contentloc {
  padding: 0 50px 0 0px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  letter-spacing: 0.21px;
  color: #151515;
  opacity: 0.8;
}

.cardtextbutton {
  width: 100px;
  font-size: 0.7em !important;
  font-weight: bold;
  color: white;
  margin-top: 15px;
  background: #016479 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
}

.cardtextbuttonorange {
  width: 100px;
  font-size: 0.7em !important;
  font-weight: bold;
  margin-top: 15px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .cardtextbutton {
    width: 100%;
  }
}
</style>
