<template>
  <div class="rightbanner hidden-md-and-down">
    <span class="connect"></span>
  </div>
</template>

<script>
export default {
  name: "Design11",
};
</script>

<style scoped>
.connect {
  background: transparent url("../image/rightdotbanner.svg") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  height: 112px;
  width: 405px;
  float: right;
  margin-top: -65px;
}

.rightbanner {
  /* height: 112px; */
}

@media only screen and (max-width: 960px) {
  .rightbanner {
    /* height: 65px; */
  }

  .connect {
    height: 65px;
    margin-top: 0px;
  }
}
</style>
