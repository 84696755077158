<template>
  <div class="design33">
    <v-row 
      ><v-col lg="6" sm="12" class="hidden-md-and-down"
        ><img class="bannerimageai" src="../image/aiwhychoose.svg" alt="Fast Tax"
      /></v-col><v-col class="design33content" lg="6" sm="12">
        <v-card class="leftcard" color="white" flat>
          <v-card-title class="cardtitle">
            <span>Why Choose Us?</span>
          </v-card-title>
       
          
              <v-card flat>
                <p class="cardtextcontent">
                <ul>
                  <li >
                    <p class="cardheader"> {{ header1 }} </p>
                    
                  </li>
              
                    <p class="carddesc"> {{ desc1 }} </p>
                    
             
                  <li >
                    <p class="cardheader"> {{ header2 }} </p>
                    
                  </li>
    
                    <p class="carddesc"> {{ desc2 }} </p>
                    <li >
                    <p class="cardheader"> {{ header3 }} </p>
                    
                  </li>
    
                    <p class="carddesc"> {{ desc3 }} </p>
                    
              
                </ul>
                </p>

                
                <!-- <v-row align="left"> -->
                 
                <!-- </v-row> -->
              </v-card>
        
        </v-card> </v-col
      >
    </v-row>

    <div class="sectiontitle">Let's Innovate Together</div>
          <div class="sectionbodyai">
            Ready to transform your operations with Al and ML? Contact Stellar Innovations today to discuss your needs and how we can partner to achieve your goals. From initial consultation to deployment and support, we're here to ensure your success every step of the way.</div>
 
            <v-btn class="cardtextbutton" to="/contact-us" rounded dark height="35px">
        Contact us
      </v-btn>

          </div>



                    <!-- <v-btn class="cardtextbutton" :to="`/${route}`" height="2.1875em" rounded dark>
                     {{ button }}
                    </v-btn>
         -->
</template>

<script>
export default {
  name: "Design33",
  props: {},
  data() {
    return {
      tab: null,
 
          menu: "Healthcare Solutions",
          header1:"Industry Expertise",
          header2:"Innovative Solutions",
          header3:"Proven Results",
          desc1:"Enhancing patient care through comprehensive analysis and insights derived from vast healthcare datasets.",
          desc2:"Utilizing ML algorithms to predict disease outbreaks, patient admissions, and other critical healthcare trends.",
          desc3:"A track record of delivering measurable improvements and competitive advantage.",
          button: "Get Started",
          route: "taxcert",
        

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design33 {
  padding: 2em 8em 0px ;
  background-color: white;
  /* margin-top: 30px; */
}

.design33 .row {
  margin: 0px;
}
.cardtext {
  width: 100% !important;
  /* height: 31em; */
}

.sectiontitle {
  /* padding-top: 30px; */
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.45px;
  color: #FA7E5C;
  opacity: 1;
  /* padding-top:35px; */
}

.sectionbodyai {
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #656565;
}


.leftcard {
  padding-right: 20px;
}

.cardheader{
  text-align: left;
font-weight: bold;
font-size: 16px;

letter-spacing: 0px;
color: #343434;
opacity: 1;
margin-bottom: 10px;
}

.carddesc{
  text-align: left;
  margin-left:30px;
font-size: 16px;
letter-spacing: 0px;
color: #656565;
opacity: 1;
  
}
.design33content {
  padding: 35px;
}
.bannerimageai {
  /* margin-top: 60px; */
  max-width: 100%;
}
.cardtitle {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0px;
  color: #FA7E5C;
  opacity: 1;
  padding: 1em 0px;
}

.Testtab {
  margin-right: 25px;
  text-transform: none;
}

.tablemenu {
  box-shadow: none !important;
  border-bottom: #151515;
  margin-top: 10px;
  float: left;
  padding-left:16px;
}

.contenttext {
  padding: 0px 0px;
  margin-bottom: 1em;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515 !important;
}

.cardtextcontent {
  /* padding-left:16px; */
  margin-top: 2em;
  text-align: justify;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-bottom: 0px;
}

/* ul {
  list-style: none;
}

li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */
ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 2px 0px 0px 30px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
}

.v-card__actions {
  padding: 0px;
}
.v-tab.v-tab {
  color: #fa7e5c;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  opacity: 1;
  padding: 0px;
}

.v-tab.v-tab--active {
  font-weight: bold;
}

.v-toolbar__content {
  float: left;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
   font-family: "Montserrat", sans-serif;
  letter-spacing: 0.33px;
  color: #fa7e5c;
  opacity: 1;
}

.cardtextbutton {
  font-size: 14px !important;
  /* font-weight: bold; */
  margin-top: 10px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
  padding: 5px 12px !important;
}

@media only screen and (max-width: 960px) {
  .design33 {
    padding: 20px 20px 40px;
    margin-bottom:40px;
  }

  .cardtextbutton {
    width: 100%;
  }

  .cardtitle span {
    word-break: break-word;
  }

  .cardtitle {
    text-align: center;
  }

  .tablemenu {
    float: none;
  }

  .leftcard {
    padding-right: 0px;
  }

  .tablemenu {
    max-width: 850px;
  }
}
</style>
