<template>
  <div class="block"><span class="connect"></span></div>
</template>

<script>
export default {
  name: "Design12",
};
</script>

<style scoped>
/* .block {
  background-color: white;
  height: 94px;
} */
.connect {
  background: white url("../image/rightdotbanner.svg") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  height: 112px;
  width: 305px;
  float: left;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}

@media only screen and (max-width: 960px) {
  .block {
    height: 65px;
  }

  .connect {
    height: 65px;
  }
}
</style>
