<template>
  <div class="sectioncontent">
    <v-row>
      <v-col lg="6" sm="12">
        <h1 class="contenthead">
          {{ header }}
        </h1>
        <p class="contentdesc">
          {{ desc1 }}
        </p>
        <p v-if="desc2" class="contentdesc">
          {{ desc2 }}
        </p>

         <p v-if="bulletinfo" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>

        <!-- <v-btn
          v-if="button"
          class="bannerbutton"
          rounded
          color="#FA7E5C"
          dark
          small
          height="35px"
          @click="redirecttocontact()"
        >
          <v-icon left dark> mdi-account </v-icon>
          {{ button }}
        </v-btn> -->
      </v-col>
      <v-col lg="6" sm="12" class="imageleft hidden-md-and-down">
        <img class="imagewidth" :src="require('../image/' + imagepath + '')" alt="Dynamic Banner"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design25",
  props: [
    "header",
    "desc1",
    "desc2",
    "imagepath",
    "button",
    "buttonval",
    "bulletinfo",
  ],
  data() {
    return {};
  },
  methods: {
    redirecttocontact() {
      this.$store.dispatch("changeHeader", "Contactus");
      return this.$router.push(`./contact-us`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sectioncontent {
  margin-top: 35px;
  margin-left: 8em;
  margin-right: 8em;
  padding-bottom: 20px;
}
.imagewidth {
  /* box-shadow: 0px 0px 40px #0000003c; */
  width: 80%;
  border-radius: 10px;
  margin-left: 60px;
}

.imageleft {
  margin-top: 25px;
  text-align: center;
}

.bannerbutton {
  margin-left: 0px;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.42px;
  font-size: 30px;
  color: #FA7E5C;
  opacity: 1;
  padding-bottom: 22px;
  padding-top: 20px;
}

.cardtextcontent {
  /* padding-left:16px; */
  margin-top: 30px;
  text-align: justify;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-bottom: 0px;
}

ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 0px 0px 4px 40px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px;
}

.contentdesc {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  padding-bottom: 10px;
}

.contentlist {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .imagewidth {
    /* width: -webkit-fill-available; */
  }
  .bannerbutton {
    width: 100%;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: center;
  }
}
</style>
