<template>
  <div id="blueblock">
    <div class="sectiontitle">Our Mission</div>
          <div class="sectionbody">
            At Stellar Innovations Private Limited, we're dedicated to transforming industry standards by integrating advanced Al and ML technologies into the core of healthcare, title, and mortgage and genomics sectors. Our mission is to enable smarter decision-making, optimize operational efficiencies, and forecast industry trends with unparalleled accuracy.
          </div>
    </div>
</template>

<script>
export default {
  name: "Design6",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#blueblock {
  padding: 0 8em;
  /* margin-top: 25px; */
  height: 14em;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background: #ECF6F8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  /* border-radius: 25px; */
}

.sectiontitle {
  /* padding-top: 30px; */
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.45px;
  color: #FA7E5C;
  opacity: 1;
  padding-top:35px;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #656565;
}

#blueblockcard {
  /* padding: 0px 60px; */
}

#blueblockfield {
  padding: 0px 4em;
  margin-top: 4em;
  text-align: left;
}

#blueblockfieldend {
}

.imageicon {
  max-width: 55px;
  position: absolute;
  left: 0px;
  top: 6px;
}

#blueblockfield .row {
  margin-top: 0px;
}

.testerai {
  padding-right: 25px;
  margin-top: 50px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #FA7E5C;
  opacity: 1;
}

.testeraicontent {
  padding-right: 25px;
  margin-top: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #7b7b7b;
  opacity: 1;
}

.cardtitleval {
  background-image: url("../image/logoicon.svg");
  vertical-align: middle;
  text-align: left;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: #147587;
  opacity: 1;
  padding: 10px 20px;
}

.cardtitleinfo {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #494949;
  opacity: 1;
  padding-left: 20px;
}

@media only screen and (max-width: 960px) {
  #blueblock {
    height: unset;
    padding: 0 30px;
  }

  #blueblockfield {
    padding: 0px 30px;
    margin-top: 20px;
  }

  .cardtitleval {
    font-size: 16px;
  }
  .imageicon {
    position: absolute;
    left: -20px;
    top: 15px;
    width: 140px;
  }
}

@media only screen and (max-width: 1280px) {
  #blueblockfield {
    padding: 0px 10px;
    margin-top: 20px;
  }

  .cardtitleval,
  .cardtitleinfo {
    padding-left: 55px;
  }
}
</style>
