<template>
  <div class="containerview">
    <v-container class="design1 lighten-5">
      <v-row>
        <v-col lg="12" sm="12">
          <v-card flat color="white">
            <h1 class="headerorange">{{ header1 }}</h1>
            <div class="headerblack">
              {{ title1 }}
            </div>
            <div class="contentgrey">
              {{ desc }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Design1",
  props: ["header1", "title1", "desc"],
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.containerview {
  background-color: white;
}
.design1 {
  /* margin-top: 20px; */
  background-color: white;
  padding: 30px;
}
.headerorange {
  font-family: "Montserrat", sans-serif;
  color: #fa7e5c;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.24px;
  opacity: 1;
}

.headerblack {
  font-family: "Montserrat", sans-serif;
  padding: 0px 70px;
  text-align: center;
  letter-spacing: 0.42px;
  color: #fa7e5c;
  opacity: 1;
  font-size: 30px;
  font-weight: bold;
}

.contentgrey {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515;
  padding: 14px 20px;
}

@media only screen and (max-width: 960px) {
  .headerblack {
    padding: 14px 25px;
  }

  .contentgrey {
    padding: 0px;
  }
}
</style>
