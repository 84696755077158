<template>
  <div>
    <Bannerfulltrans
      imageName="mortbanner.svg"
      imageName1="mortgageimage2.svg"
      mobimageName="mortgageimage3.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />
    <Design25
      header="Mortgage Services"
      desc1="‘Stellar Innovations’ offers end to end technology-enabled services in Mortgage Origination, Underwriting Quality Control, Servicing, Default Management and Due Diligence space."
      desc2="With its vast domain expertise perfectly blended with optimum use of automation, Stellar has helped its client across multiple channels including Consumer Direct, Retail, Wholesale, Correspondent lending & Capital market."
      
      imagepath="mortgage2.png"
      button="Contact Us"
      :bulletinfo="bulletinfo"
    />
    <Mortgagerange />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design25 from "../components/Designmortgage.vue";
import Mortgagerange from "../components/Mortgage_range.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Mortgage",
  data() {
    return {
      header1: "Mortgage Services",
      desc1:
        "End to End Mortgage Fulfilment and Support Services spanning across Origination, Servicing & Due Diligence space.",
      button1: "",
      bulletinfo: [
        "Portfolio Analytics",
        "Loan Processing ",
        "Underwriting Services ",
        "Closing Support",
        "Post Close Processing & Document Management",
        "Loan Portfolio Management & Due Diligence Services  ",
        "Loan Servicing  ",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design25,
    Mortgagerange,
    // Design16,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}

.v-expansion-panel-content__wrap{
padding: 0px !important;
padding-left:20px !important;
}
</style>
