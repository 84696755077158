import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    headerbutton: "Home",
  },
  mutations: {
    CHANGE_HEADER(state, payload) {
      state.headerbutton = payload;
    },
  },
  actions: {
    changeHeader({ commit }, payload) {
      commit("CHANGE_HEADER", payload);
    },
  },
});

export default store;
