<template>
  <div class="servicemenu">
    <vue-file-toolbar-menu
      :content="my_menu"
      class="topmenu hidden-md-and-down"
    />
    <vue-file-toolbar-menu
      :content="my_mobmenu"
      class="topmenu hidden-lg-and-up"
    />
    <!-- <p>
      <v-selectmenu title="" :data="menu" type="regular" align="left">
        <v-btn
          key="Our Services"
          color="#36393E"
          text
          class="headerbutton d-flex flex-row-reverse"
        >
          Our Services
        </v-btn>
      </v-selectmenu>
    </p> -->
  </div>
</template>

<script>
// import Headerbutton from "./Headerbutton";
import VueFileToolbarMenu from "vue-file-toolbar-menu";
import { mapState } from "vuex";
export default {
  components: { VueFileToolbarMenu },

  data() {
    return {
      happy: false,
    };
  },
  methods: {
    printval(val) {
      if (val == "Title Production") {
        this.$router.push(`./title`);
      } else if (val == "Mortgage Solutions") {
        this.$router.push(`./mortgage`);
      }
    },
    changeheader(item) {
      this.$store.dispatch("changeHeader", item);
    },
  },
  computed: {
    ...mapState({
      selectedheader: (state) => state.headerbutton,
    }),
    my_mobmenu() {
      return [
        {
          text: "OUR SERVICES",
          active: this.selectedheader == "Our Services" ? true : false,
          menu_width: 280,
          update_color: "red",
          chevron: true,
          menu: [
            {
              text: "Technology Solutions ",
              click: () => {
                this.$store.dispatch("changeHeader", "Our Services");
                this.$router.push(`./technology`);
              },
            },
            {
              text: "NOC and SOC Services",
              click: () => {
                this.$router.push(`./noc-soc`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Mortgage Solutions",
              click: () => {
                this.$router.push(`./mortgage`);
              },
            },
            {
              text: "Title & Settlement Services ",
              click: () => {
                this.$router.push(`./title-settlement`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Real Estate Tax Solutions",
              click: () => {
                this.$router.push(`./realestate-tax`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            // {
            //   text: "Title Plant Indexing",
            //   click: () => {
            //     this.$router.push(`./title`);
            //     this.$store.dispatch("changeHeader", "Our Services");
            //   },
            // },
            {
              text: "Appraisal Services",
              click: () => {
                this.$router.push(`./appraisal`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Due Diligence",
              click: () => {
                this.$router.push(`./duediligence`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "AI & ML Innovation",
              click: () => {
                this.$store.dispatch("changeHeader", "Our Services");
                this.$router.push(`./ai-innovation`);
              },
            },
            {
              text: "Creative Hub",
              click: () => {
                // this.$router.push(`./title`);
                window.open('https://stellarcreativehub.com', '_blank');
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Genomics",
              click: () => {
                // this.$router.push(`./title`);
                window.open('https://genomics.stellaripl.com', '_blank');
                this.$store.dispatch("changeHeader", "Our Services");
              },
            }
          ],
        },
      ];
    },
    my_menu() {
      return [
        {
          text: "OUR SERVICES",
          active: this.selectedheader == "Our Services" ? true : false,
          menu_width: 250,
          update_color: "red",
          chevron: true,
          mobilemenu: [
            {
              text: "Technology Solutions ",
              click: () => {
                this.$store.dispatch("changeHeader", "Our Services");
                this.$router.push(`./technology`);
              },
            },
            {
              text: "NOC and SOC Services",
              click: () => {
                this.$router.push(`./noc-soc`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Mortgage Solutions",
              click: () => {
                this.$router.push(`./mortgage`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Title & Settlement Services ",
              click: () => {
                this.$router.push(`./title-settlement`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Real Estate Tax Solutions",
              click: () => {
                this.$router.push(`./realestate-tax`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Title Plant Indexing",
              click: () => {
                this.$router.push(`./title`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Appraisal Services",
              click: () => {
                this.$router.push(`./appraisal`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
          ],
          menu: [
            {
              text: "Technology Solutions ",
              click: () => {
                this.$store.dispatch("changeHeader", "Our Services");
                this.$router.push(`./technology`);
              },
            },
            {
              text: "NOC and SOC Services",
              click: () => {
                this.$router.push(`./noc-soc`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Mortgage Solutions",
              click: () => {
                this.$router.push(`./mortgage`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Title & Settlement Services ",
              click: () => {
                this.$router.push(`./title-settlement`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Real Estate Tax solutions",
              click: () => {
                this.$router.push(`./realestate-tax`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            // {
            //   text: "Title Plant Indexing",
            //   click: () => {
            //     this.$router.push(`./title`);
            //     this.$store.dispatch("changeHeader", "Our Services");
            //   },
            // },

            {
              text: "Appraisal Services",
              click: () => {
                this.$router.push(`./appraisal`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Due Diligence",
              click: () => {
                this.$router.push(`./duediligence`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "AI & ML Innovation",
              click: () => {
                this.$router.push(`./ai-innovation`);
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Creative Hub",
              click: () => {
                // this.$router.push(`./title`);
                window.open('https://stellarcreativehub.com', '_blank');
                this.$store.dispatch("changeHeader", "Our Services");
              },
            },
            {
              text: "Genomics",
              click: () => {
                // this.$router.push(`./title`);
                window.open('https://genomics.stellaripl.com', '_blank');
                this.$store.dispatch("changeHeader", "Our Services");
              },
            }
          ],
        },
      ];
    },
  },
};
</script>


<style lang="scss" scoped>
.topmenu {
  height: 60px !important;
  justify-content: center;
  color: #858585 !important;
}

.headerbutton {
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 25px;
  padding-left: 25px;
  height: 60px !important;
  background-color: unset;
  text-align: left;
  letter-spacing: 0px;
  color: #858585 !important;
  opacity: 1;
}

/* @media only screen and (max-width: 960px) {
  .headerbutton .v-btn__content {
    padding-top: 30px !important;
  }
} */
.headerbutton:hover {
  /* transform: scale(1.05); */
}
.headerbutton_changecolor {
  background-color: black;
  color: white;
}

.headerbutton_changecolor:hover {
  // background-color: #16eae5;
  color: #FA7E5C !important;
}

.headerbutton:hover {
  color: #FA7E5C !important;
  font-family: "Montserrat", sans-serif;
  // background: #186e7e 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
  opacity: 1;
}

.active_header {
  color: #FA7E5C !important;
  text-decoration: underline;
text-underline-offset: 6px;
text-decoration-thickness:2px !important;
}
</style>