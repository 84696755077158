<template>
    <div id="AboutUs" class="sectioncontent">
      <v-row> 
        <v-col lg="12" sm="12">
          <h1 class="contenthead">
            {{ header }}
          </h1>
          <p class="contentdesc">
            {{ desc1 }}
          </p>
          <p v-if="desc2" class="contentdesc">
            {{ desc2 }}
          </p>
  
          <v-row justify="center" class="accordiancont">
            <v-col>
  
              <p class="headcontent"> ULRS Key Functions: </p>
  
              <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>{{items1[0]}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="bulletinfo1" class="cardtextcontent">
                  <ul>
                    <li v-for="item in bulletinfo1" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  </p>
                  </v-expansion-panel-content>
        </v-expansion-panel>
   
        <v-expansion-panel>
          <v-expansion-panel-header>{{items1[1]}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="bulletinfo1" class="cardtextcontent">
                  <ul>
                    <li v-for="item in bulletinfo2" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  </p>
                  </v-expansion-panel-content>
        </v-expansion-panel>
      
        <v-expansion-panel>
          <v-expansion-panel-header>{{items1[2]}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="bulletinfo1" class="cardtextcontent">
                  <ul>
                    <li v-for="item in bulletinfo3" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  </p>
                  </v-expansion-panel-content>
        </v-expansion-panel>
     
        <v-expansion-panel>
          <v-expansion-panel-header>{{items1[3]}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="bulletinfo1" class="cardtextcontent">
                  <ul>
                    <li v-for="item in bulletinfo4" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  </p>
                  </v-expansion-panel-content>
        </v-expansion-panel>
     
        <v-expansion-panel>
          <v-expansion-panel-header>{{items1[4]}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="bulletinfo1" class="cardtextcontent">
                  <ul>
                    <li v-for="item in bulletinfo5" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  </p>
                  </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>{{items1[5]}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p v-if="bulletinfo1" class="cardtextcontent">
                  <ul>
                    <li v-for="item in bulletinfo6" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                  </p>
                  </v-expansion-panel-content>
        </v-expansion-panel>
        
      </v-expansion-panels>
            </v-col>
            <v-col lg="4" sm="12" class="imageleft hidden-md-and-down">
              <img class="imagewidth" :src="require('../image/' + imagepath + '')" alt="Dynamic"/>
            </v-col>
            <v-col>
              <p class="headcontent"> ULRS Key Configurable Features:</p>
  
              <p v-if="items2" class="cardtextcontent">
              <ul>
                <li v-for="item in items2" :key="item">
                  {{ item }}
                </li>
              </ul>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: "Design25",
    props: [
      "header",
      "desc1",
      "desc2",
      "imagepath",
      "button",
      "buttonval",
  
    ],
    data() {
      return {
        items1: [
          "Document indexing & stacking",
          "Data extraction & validation",
          "Data integrity & completeness check",
          "Rule based validation",
          "Eligibility check & compliance check ",
          "Collection comments & key word search",
        ],
        bulletinfo1:["Auto index to identify & bookmark/stack documents as per your stacking schema "],
          bulletinfo2:["Auto extract and validate key data points for consistency and accuracy "],
          bulletinfo3:["Automate your data integrity and completeness check based on pre-defined configurable business rules."],
          bulletinfo4:["Automate your checklist-based reviews to configure and drive rule-based validation"],
          bulletinfo5:["Automate eligibility check to configure eligibility matrix for different products. Auto capture compliance data set to run compliance report"],
          bulletinfo6:["Automate review of payment history, collections comments and keyword search"],
           
        items2: [
          "Document list & stacking schema",
          "Data set & data integrity",
          "Product matrix & Process scope ",
          "Review Checklist & business rules",
          "Finding categorization & grading",
          "Customised reports and dashboards",
          "Auto notification & real time reporting",
        ],
  
      };
    },
    methods: {
      redirecttocontact() {
        this.$store.dispatch("changeHeader", "Contactus");
        return this.$router.push(`./contact-us`);
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .sectioncontent {
    margin-top: 35px;
    margin-left: 8em;
    margin-right: 8em;
    padding-bottom: 20px;
  }
  
  .imagewidth {
    /* box-shadow: 0px 0px 40px #0000003c; */
    width: 80%;
    border-radius: 10px;
    padding-top: 36px;
    /* margin-left: 60px; */
  }
  
  .imageleft {
    margin: auto;
    text-align: center;
  }
  
  .bannerbutton {
    margin-left: 0px;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
  }
  
  .contenthead {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    letter-spacing: 0.42px;
    font-size: 30px;
    color: #FA7E5C;
    opacity: 1;
    padding-bottom: 22px;
    padding-top: 20px;
  }
  
  .cardtextcontent {
    /* padding-left:16px; */
    /* margin-top: 30px; */
    text-align: justify;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
    padding-bottom: 0px;
  }
  
  ul {
    list-style: none;
    padding-left: 0px;
  }
  
  li {
    padding: 8px 15px 0px 30px;
    list-style: none;
    background-image: url("../image/tickbullet.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
  }
  
  .accordiancont {
    margin-top: 40px;
  }
  
  .headcontent {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.24px;
    color: #151515;
    padding-top: 24px;
    padding-bottom: 10px;
  }
  
  .contentdesc {
    text-align: justify;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
    padding-bottom: 10px;
  }
  
  
  
  .contentlist {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
  }
  
  @media only screen and (max-width: 960px) {
    .sectioncontent {
      margin-left: 20px;
      margin-right: 20px;
    }
  
    .imagewidth {
      /* width: -webkit-fill-available; */
    }
  
    .bannerbutton {
      width: 100%;
    }
  
    .contenthead {
      text-align: center;
    }
  
    .contentdesc {
      text-align: justify;
    }
  
    .contentlist {
      text-align: center;
    }
  }
  </style>
  