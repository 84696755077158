var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-carousel',{staticClass:"bannercarousal",attrs:{"hide-delimiter-background":"","cycle":"","show-arrows":true,"height":"auto","interval":"10000"}},[_c('v-carousel-item',[_c('div',{staticClass:"smallblock hidden-md-and-down"},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/UXOPTIMIZED.png"),"alt":"Ux"}}),_c('p',{staticClass:"block-title"},[_vm._v("UX OPTIMIZED")]),_c('div',{staticClass:"block-desc"},[_vm._v(" Products offer intuitive and minimalized designs which addresses every business requirement. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/MLDRIVENEXTRACTION.png"),"alt":"Verification"}}),_c('p',{staticClass:"block-title"},[_vm._v("ML DRIVEN EXTRACTION")]),_c('div',{staticClass:"block-desc"},[_vm._v(" UNIDEX products leverage cutting edge Machine learning solutions to achieve high accuracy curation and decision making. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/MLDRIVENSTACKING.png"),"alt":"ML DRIVEN STACKING"}}),_c('p',{staticClass:"block-title"},[_vm._v("ML DRIVEN STACKING")]),_c('div',{staticClass:"block-desc"},[_vm._v(" UNIDEX is the sole provider of efficient document stacking, reprocessing and indexing features in the market. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/HIGHACCURACY.png"),"alt":"HIGH ACCURACY"}}),_c('p',{staticClass:"block-title"},[_vm._v("HIGH ACCURACY")]),_c('div',{staticClass:"block-desc"},[_vm._v(" The coverage of all UNIDEX products continuously challenges the existing thresholds of accuracy, and is constantly improving via constant training and reinforcement. ")])])],1)]}}])})],1)],1)],1)]),_c('v-carousel-item',[_c('div',{staticClass:"smallblock hidden-md-and-down"},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/CUSTOMIZABLETEMPLATES.png"),"alt":"Template"}}),_c('p',{staticClass:"block-title"},[_vm._v("CUSTOMIZABLE TEMPLATES")]),_c('div',{staticClass:"block-desc"},[_vm._v(" Every Application comes with Customizable features and extensibility, as per user requirements. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/AUTOMATEDDATASOURCING.png"),"alt":"Auto"}}),_c('p',{staticClass:"block-title"},[_vm._v("AUTOMATED DATA SOURCING")]),_c('div',{staticClass:"block-desc"},[_vm._v(" Users have the option of automating the data sourcing/gathering pipeline via the UNIDEX interface. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/REPORTS&DASHBOARDS.png"),"alt":"Reports"}}),_c('p',{staticClass:"block-title"},[_vm._v("INSIGHTFUL REPORTS AND DASHBOARDS")]),_c('div',{staticClass:"block-desc"},[_vm._v(" Intuitive Dashboards are integrated at every step of the application for better visualization of every step of the process. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/EFFICIENTORDER.png"),"alt":"Efficent"}}),_c('p',{staticClass:"block-title"},[_vm._v("EFFICIENT ORDER MANAGEMENT")]),_c('div',{staticClass:"block-desc"},[_vm._v(" From managed order, process, user operations tracking to access control and elevations. UNIDEX provides end-to-end control in terms of management and fulfilment of Orders. ")])])],1)]}}])})],1)],1)],1)])],1),_c('div',{staticClass:"smallblock hidden-lg-and-up"},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/UXOPTIMIZED.png"),"alt":"Ux"}}),_c('p',{staticClass:"block-title"},[_vm._v("UX OPTIMIZED")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Products offer intuitive and minimalized designs which addresses every business requirement. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/MLDRIVENEXTRACTION.png"),"alt":"ML"}}),_c('p',{staticClass:"block-title"},[_vm._v("ML DRIVEN EXTRACTION")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" UNIDEX products leverage cutting edge Machine learning solutions to achieve high accuracy curation and decision making. ")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/MLDRIVENSTACKING.png"),"alt":"ML DRIVEN STACKING"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v("ML DRIVEN STACKING")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" UNIDEX is the sole provider of efficient document stacking, reprocessing and indexing features in the market. ")])])],1)]}}])})],1)],1),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/HIGHACCURACY.png"),"alt":"HIGH ACCURACY"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" HIGH ACCURACY ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" The coverage of all UNIDEX products continuously challenges the existing thresholds of accuracy, and is constantly improving via constant training and reinforcement. ")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/AUTOMATEDDATASOURCING.png"),"alt":"Auto"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" AUTOMATED DATA SOURCING ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Users have the option of automating the data sourcing/gathering pipeline via the UNIDEX interface. ")])])],1)]}}])})],1),_vm._v(" "),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/REPORTS&DASHBOARDS.png"),"alt":"Report"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" INSIGHTFUL REPORTS AND DASHBOARDS")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Intuitive Dashboards are integrated at every step of the application for better visualization of every step of the process. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/CUSTOMIZABLETEMPLATES.png"),"alt":"Template"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" CUSTOMIZABLE TEMPLATES ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Every Application comes with Customizable features and extensibility, as per user requirements. ")])])],1)]}}])})],1)],1),_c('v-row'),_c('v-row'),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"elevation":hover ? 0 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationblock",attrs:{"src":require("../image/EFFICIENTORDER.png"),"alt":"Efficent"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v("EFFICIENT ORDER MANAGEMENT")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" From managed order, process, user operations tracking to access control and elevations. UNIDEX provides end-to-end control in terms of management and fulfilment of Orders. ")])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }