<template>
    <div>
      <Header />
      <Bannerfull1
        imageName1="Unidexbg.png"
        imageName2="Unidex1.png"
        mobimageName="Unidex1.png"
        mobimageName1="mobilebg.png"
      /> 
      <Features />
      <Design14
        header="UNIDEX"
        desc1="Processing of documents for carrying out data extraction/indexing, validation and delivery is cumbersome to set up and maintain.  It involves time consuming processes, expensive resources and servers for managing the IT requirements, and is prone to human errors and inconsistencies."
        desc2="UNIDEX addresses these challenges as a platform. It provides and easy to set-up and manage alternative to businesses, that is driven through highly scalable pipelines and state-of-the-art ML solutions."
        desc3="In addition to drastically reducing the operations cost and TAT, UNIDEX provides a compliant,secure  and streamlined infrastructure for all processes in adherence to ISO and other global standards."
        imagepath="taxcert_image.webp"
        button="Ask for a quote"
        demobutton = "Login"
        logo="true"
        logoimage="unidex.svg"
        demourl="https://unidex.stellaripl.com/"
      />
      <Range />
      <Benefits />
      <!-- <Design17 /> -->
      <Design10 />
    </div>
  </template>
  
  <script>
  import Bannerfull1 from "../components/Bannerfull1.vue";
  import Features from "../components/Unidex_feature.vue";
  import Design10 from "../components/Design10.vue";
  import Header from "../components/Header.vue";
  import Design14 from "../components/Design14.vue";
  import Benefits from "../components/Unidex_Benefits.vue";
  import Range from "../components/Unidex_Range.vue";
  export default {
    name: "Taxcert",
    data() {
      return {
        header1: "unidex",
        desc1: "Lorem Ipusum Lorem Ipusum",
        button1: "",
      };
    },
    mounted() {
      window.scrollTo(0, 0);
      this.$store.dispatch("changeHeader", "Products");
    },
    components: {
      Header,
      Bannerfull1,
      Features,
      Design10,
      Design14,
      Benefits,
      Range
    },
  };
  </script>
  
  <style>
  @media only screen and (max-width: 960px) {
    .v-window__next,
    .v-window__prev {
      top: calc(70% - 20px);
    }
  }
  
  .smalluniblock {
    margin: 70px 200px 20px;
  }
  
  .smalluniblock .v-card {
    height: 380px;
  }
  
  .LogoAnimationuniblock {
    width: 250px;
    height: 175px;
  }
  
  @media only screen and (max-width: 960px) {
    .smalluniblock {
      margin: 20px 10px;
    }
  }
  
  .v-card.on-hover {
    /* background-color: rgb(236, 246, 248); */
    /* cursor: pointer; */
  }
  
  .block-title {
    font-family: "Montserrat", sans-serif;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.42px;
    padding-top: 30px;
    color: #000;
  }
  
  .block-desc {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.42px;
    color: #000;
  }
  
  /* .v-card.on-hover {
    color: #d7fff6;
  } */
  </style>
  