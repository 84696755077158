<template>
  <div id="blueblock">
    <v-row class="hidden-md-and-down">
      <v-col>
        <v-card id="blueblockcard" flat color="transparent">
          <div class="tester">Specialized Services</div>
          <div class="testercontent">
            Here are a few specialized services that can be availed on-demand
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card id="blueblockfield" flat color="transparent">
          <v-row>
            <img class="imageicon" src="../image/hrms.svg" alt="HRMS Implementation" />
            <v-col>
              <span class="cardtitleval">HRMS Implementation</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="cardtitleinfo">
                As a specialized service, Stellar provides custom-created HRMS
                dashboards and solution portals for businesses with complex HR
                processes and engagement models.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card id="blueblockfield" flat color="transparent">
          <v-row>
            <img class="imageicon" src="../image/software_dev.svg" alt="Software Development" />
            <v-col>
              <span class="cardtitleval">Software Development</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="cardtitleinfo">
                Design and engineering of software being some of our core
                competencies, Stellar develops cross-platform web applications
                to be integrated or worked in silos.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="hidden-lg-and-up">
      <v-col>
        <v-card id="blueblockcard" flat color="transparent">
          <div class="tester">Specialized Services</div>
          <div class="testercontent">
            Here are a few specialized services that can be availed on-demand
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="hidden-lg-and-up">
      <v-col>
        <v-card id="blueblockfield" flat color="transparent">
          <v-row>
            <img class="imageicon" src="../image/hrms.svg" alt="HRMS Implementation" />
            <v-col>
              <span class="cardtitleval">HRMS Implementation</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="cardtitleinfo">
                As a specialized service, Stellar provides custom-created HRMS
                dashboards and solution portals for businesses with complex HR
                processes and engagement models.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="hidden-lg-and-up">
      <v-col>
        <v-card id="blueblockfield" flat color="transparent">
          <v-row>
            <img class="imageicon" src="../image/software_dev.svg" alt="Software Development" />
            <v-col>
              <span class="cardtitleval">Software Development</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="cardtitleinfo">
                Design and engineering of software being some of our core
                competencies, Stellar develops cross-platform web applications
                to be integrated or worked in silos.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design6",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#blueblock {
  padding: 0 8em;
  /* margin-top: 25px; */
  height: 21em;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background: #ecf6f8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  /* border-radius: 25px; */
}

#blueblockcard {
  /* padding: 0px 60px; */
}

#blueblockfield {
  padding: 0px 4em;
  margin-top: 4em;
  text-align: left;
}

#blueblockfieldend {
}

.imageicon {
  max-width: 55px;
  position: absolute;
  left: 0px;
  top: 6px;
}

#blueblockfield .row {
  margin-top: 0px;
}

.tester {
  padding-right: 25px;
  margin-top: 50px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #FA7E5C;
  opacity: 1;
}

.testercontent {
  padding-right: 25px;
  margin-top: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #7b7b7b;
  opacity: 1;
}

.cardtitleval {
  background-image: url("../image/logoicon.svg");
  vertical-align: middle;
  text-align: left;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: #147587;
  opacity: 1;
  padding: 10px 20px;
}

.cardtitleinfo {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #494949;
  opacity: 1;
  padding-left: 20px;
}

@media only screen and (max-width: 960px) {
  #blueblock {
    height: unset;
    padding: 0 30px;
  }

  #blueblockfield {
    padding: 0px 30px;
    margin-top: 20px;
  }

  .cardtitleval {
    font-size: 16px;
  }
  .imageicon {
    position: absolute;
    left: -20px;
    top: 15px;
    width: 140px;
  }
}

@media only screen and (max-width: 1280px) {
  #blueblockfield {
    padding: 0px 10px;
    margin-top: 20px;
  }

  .cardtitleval,
  .cardtitleinfo {
    padding-left: 55px;
  }
}
</style>
