<template>
  <div>
    <Header />
    <Bannerfull
      imageName="
      .jpg"
      mobimageName="mob_.webp"
      :header="header1"
      :desc="desc1"
      :button="button1"
    />
    <Design14
      header="Welcome to Stellar Innovations"
      desc1="
      Stellar Innovations is a CUSTOMER-FIRST TECHNOLOGY-DRIVEN company. Making use of the new-age techniques and emerging innovations, Stellar has been delivering solutions with a futuristic approach. With an utmost focus on quality, process adherence and global data safety and privacy practices, the company is constantly ensuring that our clients/customers stay ahead of the curve."
      desc2="Working in the Financial Services Domain, Stellar has been associated with a vast clientele of top lenders, banks, title agencies and insurance intermediaries. Helping organizations deliver the best, our customized solutions and platforms ensure flawless execution resulting in effective business outcomes."
      imagepath="aboutusilli.svg"
      button="Contact Us"
    />
    <Design18 />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfull from "../components/Bannerfull.vue";
import Design14 from "../components/Design14.vue";
import Design18 from "../components/Design18.vue";
import Header from "../components/Header.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Title",
  data() {
    return {
      header1: "",
      desc1: "",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Aboutus");
  },
  components: {
    Bannerfull,
    Design14,
    Design18,
    Header,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
