<template>
  <div>
    <Bannerfulltransai
      imageName="duediligence.svg"
      imageName1="aibannerimage.svg"
      mobimageName="mobileai.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhiteai"
      headert="headertranstextright"
      content="contenttranstextright"
    />
    <Design30
      header="Our Expertise"
      header1="Cognitive Computing"
      header2="Analytics and Modelling"
      desc1="Our machine learning solutions include state-of-the-art system integrations in the areas of speech recognition/dialogue, image/video processing, natural language processing/understanding, language modeling, etc."
      desc2="Our expertise includes the creation and implementation of numerous scalable solutions, including digital forensics, advanced pattern recognition, and forecasting analytics."
      imagepath="aiourexp.svg"
    />

    <Design31 />
    <Design32 />
    <Design34 />
    <Design33 />

    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltransai from "../components/Bannerfulltransai.vue";
import Design30 from "../components/Design30.vue";
import Design31 from "../components/Design31.vue";
import Design32 from "../components/Design32.vue";
import Design33 from "../components/Design33.vue";
import Design34 from "../components/Design34.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Title",
  data() {
    return {
      header1: "AI & ML Innovation",
      desc1:
        " ",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltransai,
    Design31,
    Design32,
    Design33,
    Design34,
    Design30,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}

@media only screen and (max-width: 1280px) {
  #bannerleftimage {
    height: 300px !important;
  }
}
</style>
