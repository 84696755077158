<template>
  <div class="deisgn50">
    <div class="roundcontent">
      <div class="roundcontenttext">
        <div class="titlecontent">
          <div class="sectiontitle">Technology Solutions</div>
          <div class="sectionbody">
            Powering efficiency and productivity through innovation, technology
            and industry experience
          </div>
        </div>

        <v-row class="contentpadding hidden-sm-and-down">
          <v-col>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <!-- <v-icon large color="teal darken-2">
                  mdi-email
                </v-icon> -->
                <img class="LogoAnimation" src="../image/Automation.svg" alt="Product Development"/>
                <div class="cardtitle">Customized Product Development</div>
                <p class="cardtitletext">
                  We connect to learn your business requirements and map it with
                  appropriate technical solution to develop products and
                  solutions.
                </p>
                <v-btn
                  class="smallbutton"
                  outlined
                  x-small
                  color="#FA7E5C"
                  text
                  rounded
                >
                  Read more
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <!-- <v-icon large color="teal darken-2">
                  mdi-atlassian
                </v-icon> -->
                <img class="LogoAnimation" src="../image/robotic.svg" alt="RPA Solutions"/>
                <div class="cardtitle">RPA Solutions</div>
                <p class="cardtitletext">
                  We develop solutions to automate repetitive tasks and manual
                  processes – enhancing work efficiency, cost savings by
                  interacting with websites, databases, business applications
                  and people.
                </p>
                <v-btn
                  class="smallbutton"
                  outlined
                  x-small
                  color="#FA7E5C"
                  text
                  rounded
                >
                  Read more
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <!-- <v-icon large color="teal darken-2">
                  mdi-assistant
                </v-icon> -->
                <img class="LogoAnimation" src="../image/AI_blue.svg" alt="AI ML Solutions"/>
                <div class="cardtitle">AI & ML Solutions</div>
                <p class="cardtitletext">
                  Leverage data engineering innovations for semantic analysis,
                  turn data into insights and efficiencies, visualization of
                  information and integrated process for decision making.
                </p>
                <v-btn
                  class="smallbutton"
                  outlined
                  x-small
                  color="#FA7E5C"
                  text
                  rounded
                >
                  Read more
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <!-- <v-icon large color="teal darken-2">
                  mdi-email
                </v-icon> -->
                <img class="LogoAnimation" src="../image/Automation.svg" alt="Product Development"/>
                <div class="cardtitle">Customized Product Development</div>
                <p class="cardtitletext">
                  We connect to learn your business requirements and map it with
                  appropriate technical solution to develop products and
                  solutions.
                </p>
                <v-btn
                  class="smallbutton"
                  outlined
                  x-small
                  color="#FA7E5C"
                  text
                  rounded
                >
                  Read more
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <!-- <v-icon large color="teal darken-2">
                  mdi-atlassian
                </v-icon> -->
                <img class="LogoAnimation" src="../image/robotic.svg" alt="RPA Solutions"/>
                <div class="cardtitle">RPA Solutions</div>
                <p class="cardtitletext">
                  We develop solutions to automate repetitive tasks and manual
                  processes – enhancing work efficiency, cost savings by
                  interacting with websites, databases, business applications
                  and people.
                </p>
                <v-btn
                  class="smallbutton"
                  outlined
                  x-small
                  color="#FA7E5C"
                  text
                  rounded
                >
                  Read more
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <!-- <v-icon large color="teal darken-2">
                  mdi-assistant
                </v-icon> -->
                <img class="LogoAnimation" src="../image/AI_blue.svg" alt="AI - ML Solutions" />
                <div class="cardtitle">AI & ML Solutions</div>
                <p class="cardtitletext">
                  Leverage data engineering innovations for semantic analysis,
                  turn data into insights and efficiencies, visualization of
                  information and integrated process for decision making.
                </p>
                <v-btn
                  class="smallbutton"
                  outlined
                  x-small
                  color="#FA7E5C"
                  text
                  rounded
                >
                  Read more
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="sectiondesc">
      <v-row>
        <v-col lg="7" sm="12">
          <h2 class="contenthead">
            Key Takeaways from Stellar’s Technology Solutions
          </h2>
          <p class="contentdesc">
            To thrive in this highly competitive world, you need a partner with
            experience, expertise to realize your business goals. Here are a few
            benefits of partnering with Stellar innovations:
          </p>

          <ul class="contentlist">
            <li>
              <span class="contentlisthead">Rich customer experience: </span>
              <p class="contentlistdesc">
                We are focused on delivering a superior customer experience. Our
                holistic approach to all your needs gives you the flexibility
                and choice you need.
              </p>
            </li>
            <li>
              <span class="contentlisthead">Rich industry expertise:</span>
              <p class="contentlistdesc">
                Stellar brings in rich industry experience in designing &
                building cutting edge technology products and services to enable
                our customers to be forefront in their businesses.
              </p>
            </li>
            <li>
              <span class="contentlisthead"
                >Innovative end-to-end solution:
              </span>
              <p class="contentlistdesc">
                Stellar brings in innovative end-to-end solutions catering to
                various business needs of the customer which is innovative and
                cost effective.
              </p>
            </li>
          </ul>
        </v-col>
        <v-col lg="5" sm="12" class="imageleft hidden-md-and-down">
          <img class="imagewidth" src="../image/techsolutionkey.svg" alt="Solutions"/>
        </v-col>
      </v-row>
      <v-btn class="cardtextbutton" to="/contact-us" rounded dark height="35px">
        Contact us
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Design15",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.deisgn50 {
  background-image: url(../image/roundback.webp);
  padding: 50px 8em 30px;
  background-color: white;
  background-position: center 48px;
  background-size: 93% 403px;
}

.LogoAnimation {
  width: 55px;
}
.iconview {
  text-align: center;

  /* box-shadow: 0 0 3px 2px #fff; */
}

.iconview .v-icon {
  font-size: 55px !important;
}
.titlecontent {
  width: 70%;
  margin: auto;
}

.smallbutton {
  /* padding: 0px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 250px; */
  display: none;
  text-align: left;
}

.cardcontent {
  /* margin: auto; */
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
}

.sectiondesc {
  margin-top: 30px;
}
.roundcontent {
  /* margin-top: -120px; */
  /* margin: auto; */

  /* background: transparent
    radial-gradient(
      closest-side at 50% 42%,
      #4a7e88 0%,
      #78b2bed1 0%,
      #3a565b00 100%
    )
    0% 300% no-repeat padding-box; */
}

.contentpadding {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}
.roundcontenttext {
  margin: auto;
  text-align: center;
  opacity: 1;
}

.sectiontitle {
  /* padding-top: 30px; */
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.42px;
  color: #FA7E5C;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
}

.cardtitle {
  padding-top: 20px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtitletext {
  padding-top: 12px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.v-card__text {
  padding: 0px !important;
}
@media only screen and (max-width: 960px) {
  .contentpadding {
    padding-top: 12px;
  }
  .deisgn50 {
    padding: 20px 20px;
    background-image: none;
    background-position: top 50% left 50%;
  }

  .iconview,
  .cardtitle {
    text-align: center;
  }

  .titlecontent {
    width: 100%;
  }

  .cardcontent {
    max-width: 100% !important;
  }
}

.imagewidth {
  /* box-shadow: 0px 0px 40px #0000003c; */
  width: 85%;
  border-radius: 10px;
}

.imageleft {
  margin-top: 30px;
  text-align: center;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 28px;
  /* letter-spacing: 0.42px; */
  color: #FA7E5C;
  opacity: 1;
  padding-top: 50px;
  padding-bottom: 22px;
}

.contentlisthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.contentlistdesc {
  padding-top: 8px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtextbutton {
  width: 120px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  margin-top: 14px !important;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin: auto;
}
.contentdesc {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  /* letter-spacing: 0.24px; */
  color: #151515;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  /* padding-bottom: 40px; */
}

.contentlist {
  margin-top: 40px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}
ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 0px 0px 0px 42px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 30px;
}

/* margin: 10 px 10 px 10 px 0 px;
padding: 0 px 0 px 4 px 40 px;
list-style: none;
background-image: url(/img/tickbullet.3152f99a.svg);
background-repeat: no-repeat;
background-position: left center;
background-size: 25 px; */

/* li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .imagewidth {
    /* width: -webkit-fill-available; */
  }

  .cardtextbutton {
    width: 100%;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: justify;
  }
}
</style>
