<template>
  <div>
    <Header />

    <v-carousel
      hide-delimiter-background
      cycle
      :show-arrows="true"
      height="auto"
      interval="6000"
      class="bannercarousal"
    >
      <v-carousel-item eager>
        <Bannerfull imageName="banner_1.gif" mobimageName="mobile_1.gif" />
      </v-carousel-item>
      <v-carousel-item eager>
        <Bannerfull imageName="banner_2.gif" mobimageName="mobile_2.gif" />
      </v-carousel-item>
      <v-carousel-item eager>
        <Bannerfull imageName="banner_3.gif" mobimageName="mobile_3.gif" />
      </v-carousel-item>
      <v-carousel-item eager>
        <Bannerfull imageName="banner_4.gif" mobimageName="mobile_4.gif" />
      </v-carousel-item>
      <v-carousel-item eager>
        <Bannerfull imageName="banner_6.gif" mobimageName="mobile_6.gif" ulrsbutton="true"/>
      </v-carousel-item>
    </v-carousel>

    <!-- <Design12 /> -->
    <Design1
    header1="Stellar Innovations"
      title1="Technology Coupled Service Offerings"
      desc="Our Managed Solutions aid diverse clientele worldwide.
Stellar offers Consulting, Solutioning & Process Re-engineering services.
We help you to stay focused on strategic, high-value activities as we take care of the rest.
"
    />

    <Design2
      imageNam1="industry.svg"
      imageNam2="techauto.svg"
      imageNam3="turnkey.svg"
      :blocktitle1="blocktitle1"
      :blockdesc1="blockdesc1"
      :blocktitle2="blocktitle2"
      :blockdesc2="blockdesc2"
      :blocktitle3="blocktitle3"
      :blockdesc3="blockdesc3"
    />

    <Design3 />
    <Design11 />
    <!-- <Design11 /> -->
    <Design4 />

    <Design6 />
    <Design7 />
    <!-- <Design9 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfull from "../components/Bannerfull.vue";
// import Bannerfullvideo from "../components/Bannerfullvideo.vue";

import Header from "../components/Header.vue";
import Design1 from "../components/Design1.vue";
import Design2 from "../components/Design2.vue";
import Design3 from "../components/Design3.vue";
import Design4 from "../components/Design4.vue";
import Design6 from "../components/Design6.vue";
import Design7 from "../components/Design7.vue";
// import Design9 from "../components/Design9.vue";
import Design10 from "../components/Design10.vue";
import Design11 from "../components/Design11.vue";
// import Design12 from "../components/Design12.vue";
export default {
  name: "Home",
  data() {
    return {
      isLoading: true,
      header1: "Extensive Title Production Services",
      desc1:
        "Detailed title production for informed decision-making while issuing Title policy, Re-sale, Re-finance, or Foreclosure Auction and much more.",
      button1: "Learn More",
      header2: "End-to-end Escrow & Mortgage Services",
      desc2:
        "Ease of property transaction at its best with an end-to-end reviewed, recorded, & customized tracking of escrow and mortgage activities.",
      button2: "Learn More",
      header3: "Verified Appraisal Services",
      desc3:
        "Verified property appraisal ensuring precise valuation based on the key market indicators from credible sources and cross-checked by experts.",
      button3: "Ask for a Quote",
      blocktitle1: "Rich Industry Experience",
      blockdesc1:
        "Stellar brings in cumulative 100+ years of industry experience in designing and building cutting edge technology products and services to enable customers to be at the forefront of their businesses.",
      blocktitle2: "Technology Excellence",
      blockdesc2:
        "Our products and solutions are built with latest technology enabling our customers to make informed decisions, increasing efficiency, cost savings through Augmented intelligence and Process automation.",
      blocktitle3: "Turnkey Solutions",
      blockdesc3:
        "Developed by experts based on their experience and competence in the field, our turnkey solutions makes it easy for businesses to meet their day to day operational requirements.",
    };
  },

  components: {
    // Bannerfullvideo,
    Bannerfull,
    Header,
    Design1,
    Design2,
    Design3,
    Design4,
    Design6,
    Design7,
    // Design9,
    Design10,
    Design11,
    // Design12,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.bannercarousal .v-responsive__content {
  /* height: 600px !important; */
  padding: 0px !important;
}

.v-window__next,
.v-window__prev {
  display: none;
}

.v-input__slot {
  box-shadow: none !important;
}

.v-label {
  color: #FA7E5C !important;
}
.v-toolbar__content {
  padding: 4px 0px;
}
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
  .v-carousel__controls {
    display: none !important;
  }
  .tablemenu .v-toolbar__content {
    /* padding: 0px !important; */
    float: none !important;
  }
}
</style>
