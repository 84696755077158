import Vue from "vue";
import Router from "vue-router";
import Home from "./view/Home";
import Aboutus from "./view/Aboutus";
import Contactus from "./view/Contactus";
// import Title from "./view/Title";
import Mortgage from "./view/Mortgage";
import Taxcert from "./view/Taxcert";
import Taxmonitor from "./view/Taxmonitor";
import Ulrs from "./view/Ulrs";
import unidex from "./view/Unidex";
import Terms from "./view/Terms";
import Careers from "./view/Careers";
import Technology from "./view/Technology";
import Aiandml from "./view/Aiandml";
import Nocsoc from "./view/Nocsoc";
import Titlesettlement from "./view/Titlesettlement";
import Realestatetax from "./view/Realestatetax";
import Appraisal from "./view/Appraisal";
import Duediligence from "./view/Duediligence";
import Easement from "./view/Easement";
import Automatedavr from "./view/Automatedavr";
import Mercurius from "./view/Mercurius";
import Fastlv from "./view/Fastlv";
import Meta from "vue-meta";
Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { x: 0, y: 0 };
  },
  base: "/",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/about-us",
      name: "Aboutus",
      component: Aboutus,
    },
    {
      path: "/contact-us",
      name: "Contactus",
      component: Contactus,
    },
    {
      path: "/careers",
      name: "Careers",
      component: Careers,
    },
    // {
    //   path: "/title",
    //   name: "Title",
    //   component: Title,
    // },
    {
      path: "/title-settlement",
      name: "Titlesettlement",
      component: Titlesettlement,
    },
    {
      path: "/realestate-tax",
      name: "Realestatetax",
      component: Realestatetax,
    },
    {
      path: "/appraisal",
      name: "Appraisal",
      component: Appraisal,
    },
    {
      path: "/duediligence",
      name: "Due Diligence",
      component: Duediligence,
    },
    {
      path: "/easement",
      name: "Easement",
      component: Easement,
    },
    {
      path: "/mortgage",
      name: "Mortgage",
      component: Mortgage,
    },
    {
      path: "/taxcert",
      name: "Taxcert",
      component: Taxcert,
    },
    {
      path: "/technology",
      name: "Technology",
      component: Technology,
    },
    {
      path: "/ai-innovation",
      name: "Aiandml",
      component: Aiandml,
    },
    {
      path: "/noc-soc",
      name: "Nocsoc",
      component: Nocsoc,
    },
    {
      path: "/taxmonitor",
      name: "Taxmonitor",
      component: Taxmonitor,
    },
    {
      path: "/ulrs",
      name: "Ulrs",
      component: Ulrs,
    },
    
    {
      path: "/unidex",
      name: "unidex",
      component: unidex,
    },
    {
      path: "/fastlv",
      name: "Fastlv",
      component: Fastlv,
    },
    {
      path: "/mercurius",
      name: "Mercurius",
      component: Mercurius,
    },
    {
      path: "/automatedavr",
      name: "Automatedavr",
      component: Automatedavr,
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms,
    },
  ],
});
