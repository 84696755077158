<template>
  <!-- <v-card flat class="headersection d-flex justify-end"> -->
  <v-toolbar
    absolute
    class="headertransblock"
    :class="{ change_color: scrollPosition > 150 }"
    flat
    dark
  >
    <img
      class="d-block d-flex headerimage"
      :src="
        isDefaultImage
          ? require('../image/footerwhite.png')
          : require('../image/logo.png')
      "
      alt="Footer Logo"
      @click="routeHome"
    />
    <v-menu class="mobilemenu hidden-lg-and-up">
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon
          class="hidden-lg-and-up"
          color="black"
          v-on="on"
        ></v-app-bar-nav-icon>
      </template>
      <v-list>
        <v-list-tile>
          <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'Home', value: 'Home' }"
              :flags="true"
            />
          </v-list-tile-content>
          <v-list-tile-content>
            <Ourservices />
          </v-list-tile-content>
          <v-list-tile-content>
            <Products />
          </v-list-tile-content>
          <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'About Us', value: 'about-us' }"
              :flags="false"
            />
          </v-list-tile-content>
          <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'Careers', value: 'careers' }"
              :flags="false"
            />
          </v-list-tile-content>
          <v-list-tile-content>
            <Headerbutton
              :label="{ text: 'Contact Us', value: 'contact-us' }"
              :flags="false"
            />
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>

    <v-toolbar-items class="hidden-md-and-down">
      <Headerbutton :label="{ text: 'Home', value: 'Home' }" :flags="true" />
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-down">
      <Ourservices />
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-down"><Products /> </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'About Us', value: 'about-us' }"
        :flags="false"
      />
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'Careers', value: 'careers' }"
        :flags="false"
      />
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-down">
      <Headerbutton
        :label="{ text: 'Contact Us', value: 'contact-us' }"
        :flags="false"
      />
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <!-- <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn> -->
  </v-toolbar>
</template>

<script>
import Headerbutton from "./Headerbutton";
import Ourservices from "./Ourservices";
import Products from "./Products";

export default {
  name: "Header",
  components: {
    Headerbutton,
    Ourservices,
    Products,
  },

  props: {},
  methods: {
    handleScroll() {
      console.log(window.scrollY);
      if (window.scrollY > 100) {
        return (this.isDefaultImage = false);
      }
      if (window.scrollY <= 100) {
        if (!this.defaultImage) {
          return (this.isDefaultImage = true);
        }
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    routeHome() {
      this.$store.dispatch("changeHeader", "Home");
      return this.$router.push(`./`);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  data() {
    return {
      scrollPosition: null,
      isDefaultImage: true,
      menu: [
        { icon: "home", title: "Link A" },
        { icon: "info", title: "Link B" },
        { icon: "warning", title: "Link C" },
      ],
    };
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.headertransblock {
  z-index: 9999 !important;
  padding: 0px 8em;
  position: fixed !important;
  background-color: transparent !important;
  max-width: 100rem;
  left:0px;
  right:0px;
margin:auto;
}

.topmenu .bar .bar-button.open:hover {
  color: #FA7E5C !important;
  font-family: "Montserrat", sans-serif;
  background: #f3ece5 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
  opacity: 1;
}

.headerimage {
  position: fixed !important;
  float: left;
  z-index: 9999;
  position: absolute;
  top: 11px;
  left: 120px;
  width: 245px;
  height: 42px;
  opacity: 1;
}

.headerimage:hover {
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .headerimage {
    left: 20px !important;
    width: 200px;
    height: 40px;
  }
}

.v-menu__content {
  top: 60px !important;
  left: unset !important;
  right: 10px;
}

@media only screen and (min-width: 960px) {
  .headerbutton .v-btn__content {
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 960px) {
  .headerbutton {
    width: 100%;
  }

  .v-menu__content {
    width: 250px;
  }

  .bar-button {
    width: 100%;
  }
  .headertransblock {
    z-index: 9999 !important;
    padding: 0px 10px;
    /* padding-right: 10px; */
  }
}

.change_color {
  background-color: white !important;
  box-shadow: 0 5px 3px -1px rgb(0 0 0 / 10%) !important;
  /* box-shadow: 0 17px 6px -6px black; */
}
</style>
