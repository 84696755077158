<template>
  <div>
    <Bannerfulltrans
      imageName="appraisalimage1.svg"
      imageName1="appraisalimage2.svg"
      mobimageName="appraisalimage3.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />

    <Design25
      header=" Appraisal Services"
      desc1="Our QC reviewers are well versed with the understanding of different appraisal report types and strike a fine balance on file rejections and delivery of a compliant report to our clients. Our reviewers are USPAP certified have the eye for details when it comes to comparable/listing properties, cost calculations & negative and positive adjustments. "
      desc2="Our skilled team of vendor managers also help our clients with order assignments, follow-ups & appraiser panel management  "
      button="Contact Us"
      :bulletinfo="bulletinfo"
      imagepath="appraisal1.svg"
    />

    <Design26 />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design25 from "../components/Design25.vue";
import Design26 from "../components/Design26.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Realestatetax",
  data() {
    return {
      header1: "Appraisal Services",
      desc1:
        "Assisting clients to attain higher service levels when it comes to quality reports and turnaround time",
      button1: "",
      bulletinfo: [
        "Administrative Appraisal Review",
        "BPO Review ",
        "Compliance Review ",
        "Property Condition Reports ",
        "Appraiser Panel Management",
        "Order Management Services ",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design25,
    Design26,
    // Design16,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
