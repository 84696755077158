<template>
  <div class="contentmissionblock">
    <v-row class="hidden-lg-and-up"
      ><v-col lg="6" sm="12">
        <v-card class="mx-auto contentinfo" max-width="100%">
          <v-card-text>
            <img class="LogoAnimation" src="../image/Our_Mission.svg" alt="Our Mission" />
            <h3 class="contenthead">Our Mission</h3>
            <div class="contenttextval">
              Stellar Innovations was founded by a group of tenured
              professionals with the mission of providing the
              best-in-the-industry IT and data management services to our valued
              clients from across various industries and sectors. We aim to
              achieve our mission by delivering quality deliverables within the
              agreed timeframes while ensuring customer satisfaction concerning
              to high level of transparency, security, feasibility, and
              affordability.
            </div>
          </v-card-text>
        </v-card></v-col
      >
    </v-row>
    <v-row class="hidden-lg-and-up">
      <v-col lg="6" sm="12">
        <v-card class="mx-auto contentinfo" max-width="100%">
          <v-card-text>
            <img class="LogoAnimation" src="../image/Our_Vision.svg" alt="Our Vision" />
            <h3 class="contenthead">Our Vision</h3>
            <div class="contenttextval">
              Stellar Innovations was incorporated with the vision of gaining
              global recognition as one of the leading data management and IT
              services companies. We aim and are working towards seeing our
              company becoming the best choice for both potential clients and
              job seekers. Our goal is to achieve our vision of delivering
              nothing-but-the-best quality services by creating work
              environments that are both challenging and rewarding for our
              employees.
            </div>
          </v-card-text>
        </v-card></v-col
      ></v-row
    >

    <v-row class="hidden-md-and-down"
      ><v-col lg="6" sm="12">
        <v-card class="mx-auto contentinfo" max-width="100%">
          <v-card-text>
            <img class="LogoAnimation" src="../image/Our_Mission.svg" alt="Our Mission" />
            <h3 class="contenthead mb-4">Our Mission</h3>
            <div class="contenttextval">
              Stellar Innovations was founded by a group of tenured
              professionals with the mission of providing the
              best-in-the-industry IT and data management services to our valued
              clients from across various industries and sectors. We aim to
              achieve our mission by delivering quality deliverables within the
              agreed timeframes while ensuring customer satisfaction concerning
              to high level of transparency, security, feasibility and
              affordability.
            </div>
          </v-card-text>
        </v-card></v-col
      >
      <v-col lg="6" sm="12">
        <v-card class="mx-auto contentinfo" max-width="100%">
          <v-card-text>
            <img class="LogoAnimation" src="../image/Our_Vision.svg" alt="Our Vision" />
            <h3 class="contenthead mb-4">Our Vision</h3>
            <div class="contenttextval">
              Stellar Innovations was incorporated with the vision of gaining
              global recognition as one of the leading data management and IT
              services companies. We aim and are working towards seeing our
              company becoming the best choice for both potential clients and
              job seekers. Our goal is to achieve our vision of delivering
              nothing-but-the-best quality services by creating work
              environments that are both challenging and rewarding for our
              employees.
            </div>
          </v-card-text>
        </v-card></v-col
      ></v-row
    >
    <img class="imagewidth" src="../image/trust1.png" alt="Trust" />
  </div>
</template>

<script>
export default {
  name: "Design17",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contentmissionblock {
  margin: 70px 8em;
}

.imagewidth {
  margin: 70px 0px;
  max-width: 100%;
}

.imagewidth1 {
  margin: 30px 0px;
  max-width: 100%;
}

.contentinfo {
  height: 420px;
  padding: 30px;
  text-align: left;
  border-radius: 30px;
  background-color: #effcff;
}

.v-sheet {
  box-shadow: none !important;
}

.LogoAnimation {
  text-align: left;
  max-width: 100px;
}

.contenthead {
  color: #016479 !important;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
  margin-top: 40px;
}

.contenttextval {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  /* padding: 26px 0px; */
  font-weight: unset important;
}

@media only screen and (max-width: 960px) {
  .contentblock {
    margin: 20px 20px;
  }
  .contentmissionblock {
    margin: 70px 20px;
  }
  .LogoAnimation {
    width: 60px;
  }

  .contenthead {
    margin-top: 10px;
  }

  .contentinfo {
    height: 465px;
    padding: 10px;
  }
}
</style>
