<template>
  <div class="sectioncontent">
    <v-row>
      <v-col lg="6" sm="12" class="imageleft hidden-md-and-down">
        <img class="imagewidthai" :src="require('../image/' + imagepath + '')" alt="Dynamic" />
      </v-col>
      <v-col lg="6" sm="12">
        <h1 class="contenthead">
          {{ header }}
        </h1>
        <h1 class="contenthead1">
          {{ header1 }}
        </h1>
        <p class="contentdesc">
          {{ desc1 }}
        </p>
        <h1 class="contenthead1">
          {{ header2 }}
        </h1>
        <p class="contentdesc">
          {{ desc2 }}
        </p>
        <v-btn
          v-if="button"
          class="bannerbutton"
          rounded
          color="#FA7E5C"
          dark
          small
          height="35px"
          @click="redirecttocontact()"
        >
          <v-icon left dark> mdi-account </v-icon>
          {{ button }}
        </v-btn>
      </v-col>
    
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design14",
  props: ["header","header1","header2", "desc1", "desc2", "desc3", "imagepath", "button", "buttonval"],
  data() {
    return {};
  },
  methods: {
    redirecttocontact() {
      this.$store.dispatch("changeHeader", "Contactus");
      return this.$router.push(`./contact-us`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sectioncontent {
  margin-top: 70px;
  margin-left: 8em;
  margin-right: 8em;
  padding-bottom: 35px;
}
.imagewidthai {
  /* box-shadow: 0px 0px 40px #0000003c; */
  width: 65%;
  border-radius: 10px;
  margin-left: 0px;
  max-height: 458px;
}

.imageleft {
  margin-top: 25px;
  text-align: center;
}

.bannerbutton {
  margin-left: 0px;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}

.contenthead1 {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.42px;
  font-size: 20px;


  padding-bottom: 36px;

  height: 24px;
/* UI Properties */

letter-spacing: 0px;
color: #343434;
opacity: 1;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.42px;
  font-size: 30px;
  color: #FA7E5C;
  opacity: 1;
  padding-bottom: 22px;
  padding-top: 20px;
}

.contentdesc {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
 
  color: #151515;
  padding-bottom: 10px;
}

.contentlist {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}
ul {
  list-style: none;
}
li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .imagewidthai {
    /* width: -webkit-fill-available; */
  }
  .bannerbutton {
    width: 100%;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: center;
  }
}
</style>
