<template>

  <div>
    <v-carousel
      hide-delimiter-background
      cycle
      :show-arrows="true"
      height="auto"
      interval="10000"
      class="bannercarousal"
    >
  
    <v-carousel-item>
       
     
    <div class="smallblock hidden-md-and-down">
      <v-row>
        <v-col lg="3" sm="12">
             <v-hover v-slot="{ hover }" open-delay="200">
               <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
                 <v-card-text>
                   <img
                     class="LogoAnimationblock"
                     src="../image/UXOPTIMIZED.png"
                     alt="Ux"
                   />
                   <p class="block-title">UX OPTIMIZED</p>
                   <div class="block-desc">
                    Products offer intuitive and minimalized designs which addresses every business requirement. 
                   </div>
                 </v-card-text>
               </v-card>
             </v-hover>
           </v-col>
        <v-col lg="3" sm="12">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/MLDRIVENEXTRACTION.png"
                  alt="Verification"
                />
                <p class="block-title">ML DRIVEN EXTRACTION</p>
                <div class="block-desc">
                  UNIDEX products leverage cutting edge Machine learning solutions to achieve high accuracy curation and decision making. 
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col lg="3" sm="12"
             ><v-hover v-slot="{ hover }" open-delay="200">
               <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
                 <v-card-text>
                   <img class="LogoAnimationblock" src="../image/MLDRIVENSTACKING.png" alt="ML DRIVEN STACKING"/>
                   <p class="block-title">ML DRIVEN STACKING</p>
                   <div class="block-desc">
                    UNIDEX is the sole provider of efficient document stacking, reprocessing and indexing features in the market.  
                   </div>
                 </v-card-text>
               </v-card>
             </v-hover></v-col
           >
        <v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img class="LogoAnimationblock" src="../image/HIGHACCURACY.png" alt="HIGH ACCURACY"/>
                <p class="block-title">HIGH ACCURACY</p>
                <div class="block-desc">
                 The coverage of all UNIDEX products continuously challenges the existing thresholds of accuracy, and is constantly improving via constant training and reinforcement. 
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      >
    </div>
  </v-carousel-item>
  <v-carousel-item>
       
     
       <div class="smallblock hidden-md-and-down">
         <v-row>
          <v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/CUSTOMIZABLETEMPLATES.png"
                  alt="Template"
                />
                <p class="block-title">CUSTOMIZABLE TEMPLATES</p>
                <div class="block-desc">
                   Every Application comes with Customizable features and extensibility, as per user requirements.
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        >
           <v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/AUTOMATEDDATASOURCING.png"
                  alt="Auto"
                />
                <p class="block-title">AUTOMATED DATA SOURCING</p>
                <div class="block-desc">
                 Users have the option of automating the data sourcing/gathering pipeline via the UNIDEX interface.  
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        >
           <v-col lg="3" sm="12"
             ><v-hover v-slot="{ hover }" open-delay="200">
               <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
                 <v-card-text>
                   <img
                     class="LogoAnimationblock"
                     src="../image/REPORTS&DASHBOARDS.png"
                     alt="Reports"
                   />
                   <p class="block-title">INSIGHTFUL REPORTS AND DASHBOARDS</p>
                   <div class="block-desc">
                    Intuitive Dashboards are integrated at every step of the application for better visualization of every step of the process.  
                   </div>
                 </v-card-text>
               </v-card>
             </v-hover></v-col
           >
           
           
           <v-col lg="3" sm="12"
             ><v-hover v-slot="{ hover }" open-delay="200">
               <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
                 <v-card-text>
                   <img
                     class="LogoAnimationblock"
                     src="../image/EFFICIENTORDER.png"
                     alt="Efficent"
                   />
                   <p class="block-title">EFFICIENT ORDER MANAGEMENT</p>
                   <div class="block-desc">
                    From managed order, process, user operations tracking to access control and elevations. UNIDEX provides end-to-end control in terms of management and fulfilment of Orders.  
                   </div>
                 </v-card-text>
               </v-card>
             </v-hover></v-col
           ></v-row
         >
       </div>
     </v-carousel-item>
    </v-carousel>

    <div class="smallblock hidden-lg-and-up">
      <v-row>

        <v-col lg="3" sm="12">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                     class="LogoAnimationblock"
                     src="../image/UXOPTIMIZED.png"
                     alt="Ux"
                   />
                   <p class="block-title">UX OPTIMIZED</p>
                   <div class="block-desc" :class="{ 'on-hover': hover }">
                    Products offer intuitive and minimalized designs which addresses every business requirement. 
                   </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col> 
        <v-col lg="3" sm="12">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/MLDRIVENEXTRACTION.png"
                  alt="ML"
                />
                <p class="block-title">ML DRIVEN EXTRACTION</p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                  UNIDEX products leverage cutting edge Machine learning solutions to achieve high accuracy curation and decision making. 
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col> </v-row
      >
      <v-row
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img class="LogoAnimationblock" src="../image/MLDRIVENSTACKING.png" alt="ML DRIVEN STACKING"/>
                   <p class="block-title"  :class="{ 'on-hover': hover }">ML DRIVEN STACKING</p>
                   <div class="block-desc"  :class="{ 'on-hover': hover }">
                    UNIDEX is the sole provider of efficient document stacking, reprocessing and indexing features in the market.  
                   </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      >
      <v-row>
        </v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img class="LogoAnimationblock" src="../image/HIGHACCURACY.png" alt="HIGH ACCURACY"/>
                <p class="block-title" :class="{ 'on-hover': hover }">
                  HIGH ACCURACY
                </p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                 The coverage of all UNIDEX products continuously challenges the existing thresholds of accuracy, and is constantly improving via constant training and reinforcement. 
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/AUTOMATEDDATASOURCING.png"
                  alt="Auto"
                />
                <p class="block-title" :class="{ 'on-hover': hover }">
                  AUTOMATED DATA SOURCING
                </p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                 Users have the option of automating the data sourcing/gathering pipeline via the UNIDEX interface.  
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        > <v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                     class="LogoAnimationblock"
                     src="../image/REPORTS&DASHBOARDS.png"
                     alt="Report"
                   />
                   <p class="block-title" :class="{ 'on-hover': hover }">
                    INSIGHTFUL REPORTS AND DASHBOARDS</p>
                   <div class="block-desc" :class="{ 'on-hover': hover }">
                    Intuitive Dashboards are integrated at every step of the application for better visualization of every step of the process.  
                   </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/CUSTOMIZABLETEMPLATES.png"
                  alt="Template"
                />
                <p class="block-title" :class="{ 'on-hover': hover }">
                  CUSTOMIZABLE TEMPLATES
                </p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                   Every Application comes with Customizable features and extensibility, as per user requirements.
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row>
        <v-row>
       </v-row
      ><v-row>
       
       
       
        </v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                     class="LogoAnimationblock"
                     src="../image/EFFICIENTORDER.png"
                     alt="Efficent"
                   />
                   <p class="block-title" :class="{ 'on-hover': hover }">EFFICIENT ORDER MANAGEMENT</p>
                   <div class="block-desc" :class="{ 'on-hover': hover }">
                    From managed order, process, user operations tracking to access control and elevations. UNIDEX provides end-to-end control in terms of management and fulfilment of Orders.  
                   </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row>
    </div>

  </div>
</template>

<script>

export default {
  name: "Taxcert",
  data() {
    return {
      items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
              
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Products");
  },
  components: {
   
  },
};
</script>

<style>


@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}

.smallblock {
  margin: 70px 8em 20px;
}

.smallblock .v-card {
  max-height: 400px;
}

.LogoAnimationblock {
  max-width: 250px;
  width: auto;
  height: 175px;
}

@media only screen and (max-width: 960px) {
  .smallblock {
    margin: 20px 10px;
  }
}

.v-card.on-hover {
  /* background-color: rgb(236, 246, 248); */
  cursor: none;
}

.block-title {
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  font-weight: bold;
  letter-spacing: 0.42px;
  padding-top: 30px;
  color: #000;
}

.block-desc {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.42px;
  color: #000;
}

/* .v-card.on-hover {
  color: #d7fff6;
} */
</style>
