<template>
  <div>
    <Bannerfulltrans
      imageName="techimage2.svg"
      imageName1="techimage3.svg"
      mobimageName="techimage4.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />
    <Design14
      header="Technology Solutions"
      desc1="Stellar innovations offer a full range of products, solutions and services to its enterprise customers. Our products and services help organizations to create new business models and revenue streams – all while ensuring security, quality, and regulatory compliance of data."
      desc2="Powered by cutting edge technologies such as Cloud, Artificial Intelligence (AI), Machine Learning (ML), web-based technology and advanced analytics, our products and solutions help enterprises enhance decision making, increase efficiency and save costs while enabling Augmented intelligence and Process automation. To discuss your requirements for an end-to-end solution contact us today."
      imagepath="techsolution_illu.svg"
    />
    <technologyrange />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design14 from "../components/Design14.vue";
import technologyrange from "../components/technologyrange.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Title",
  data() {
    return {
      header1: "Technology Solutions",
      desc1:
        "Powering efficiency and productivity through innovation, technology and industry experience",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design14,
    technologyrange,
    // Design16,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}

@media only screen and (max-width: 1280px) {
  #bannerleftimage {
    height: 300px !important;
  }
}
</style>
