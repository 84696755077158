<template>
    <div id="Services" class="design3">
        <v-row><v-col class="design3content" lg="6" sm="12">
                <v-card class="leftcard" color="white" flat>
                    <v-card-title class="cardtitle">
                        <span>SERVICES</span>
                    </v-card-title>
                    <v-card-text class="contentdesc">
                        For any given bulk loan onboarding done at Wholesale Submission, Correspondent Review or
                        Secondary Market Due Diligence,
                        ULRS can facilitates parallel processing of multiple files to deliver output at a single click.
                        <p>For the agility it offers ULRS has been successfully implemented at different stages of the
                            loan cycle including:</p>
                    </v-card-text>
                    <v-toolbar color="white" class="tablemenu" align-left height="40">
                        <template>
                            <v-tabs v-model="tab" show-arrows>
                                <v-tabs-slider color="#FA7E5C" width="175px" height="4"></v-tabs-slider>
                                <v-tab class="Testtab" v-for="item in items" :key="item.menu">
                                    {{ item.menu }}
                                </v-tab>
                            </v-tabs>
                        </template>
                    </v-toolbar>
                    <v-tabs-items class="cardtext" v-model="tab">
                        <v-tab-item v-for="item in items" :key="item.text">
                            <v-card flat>
                                <p class="cardtextcontent">
                                    {{ item.text }}
                                </p>
                                <p class="cardtextcontent">
                                    {{ item.text1 }}
                                </p>
                                <p class="cardtextcontent">
                                <ul>
                                    <li v-for="item in item.takeaways" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                                </p>
                                <!-- <v-row align="left"> -->
                                <v-card-actions>

                                    <v-btn 
                                    class="bannerbutton"
                                     rounded  
                                     dark 
                                     small 
                                      @click="redirecttocontact()">
                                        {{ item.button }}
                                    </v-btn>
                                </v-card-actions>
                                <!-- </v-row> -->
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card> </v-col><v-col lg="6" sm="12" class="hidden-md-and-down"><img class="bannerimage"
                    src="../image/UlrsService.svg" alt="Banner"/></v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Design3",
    props: {},
    data() {
        return {
            tab: null,
            items: [
                {
                    menu: "Initial loan review",
                    text: `Stacking & Set-up to validate and update key loan information on new incoming loan files into your LOS`,
                    button: "Get Started",
                    route: "taxcert",
                },
                {
                    menu: "Underwriting submissions",
                    text: `Comprehensive review of loan files before submitting it to underwriting for data integrity, completeness, eligibility, calculations, and guidelines`,
                    button: "Get Started",
                    route: "taxcert",
                },
                {
                    menu: "Underwriting quality control",
                    text: `Drive underwriting QC at pre-funding and post close stage helping you adhere to agency quality control and reporting requirements`,
                    button: "Get Started",
                    route: "taxcert",
                },
                {
                    menu: "Pre-purchase review",
                    text: `Comprehensive review of closed loan files including data integrity, completeness, rule validation before you onboard it through correspondent channel`,
                    button: "Get Started",
                    route: "taxcert",
                },
                {
                    menu: "Mortgage due diligence ",
                    text: `Comprehensive review of closed loan portfolio for documents, data and risk including credit, collateral, compliance, servicing history, collection comments, default proceedings`,
                    //  takeaways: [
                    //   "Lorem Ipsum is simply dummy text",
                    //   "Lorem Ipsum is simply dummy text",
                    //   "Lorem Ipsum is simply dummy text",
                    //   "Lorem Ipsum is simply dummy text",
                    //   "Lorem Ipsum is simply dummybannerimage text",
                    // ],
                    button: "Get Started",
                    route: "taxcert",
                },
            ],

            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        };
    },
    methods: {
        redirecttocontact() {
            this.$store.dispatch("changeHeader", "contactus");
            return this.$router.push(`./contact-us`);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design3 {
    padding: 2em 8em 0px;
    background-color: white;
    margin-top: 30px;
}

.design3 .row {
    margin: 0px;
}

.cardtext {
    width: 100% !important;
    height: 20em;
}

.leftcard {
    padding-right: 20px;
}

.design3content {
    padding: 0px;
}

.bannerimage {
    /* margin-top: 60px; */
    max-width: 100%;
}

.cardtitle {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size:30px;
    letter-spacing: 0px;
    color: #FA7E5C;
    opacity: 1;
    padding: 1em 0px;
}

.Testtab {
    margin-right: 18px;
    text-transform: none;
}

.tablemenu {
    box-shadow: none !important;
    border-bottom: #FA7E5C;
    margin-top: 10px;
    float: left;
    /* margin-left: 10px; */
    /* padding-left:16px; */
}

.contenttext {
    padding: 0px 0px;
    margin-bottom: 1em;
    text-align: justify;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
    font-weight: 600;
}

.cardtextcontent {
    /* padding-left:16px; */
    margin-top: 2em;
    text-align: justify;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
    padding-bottom: 0px;
}

.contentdesc {
    text-align: justify;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
    padding-bottom: 10px;
    padding-left: 0px !important;
}

/* ul {
  list-style: none;
}

li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */
ul {
    list-style: none;
    padding-left: 0px;
}

li {
    margin: 10px 10px 10px 0px;
    padding: 0px 0px 4px 40px;
    list-style: none;
    background-image: url("../image/tickbullet.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 25px;
}

.v-card__actions {
    padding: 0px !important;
}

.v-tab.v-tab {
    color: #FA7E5C;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.24px;
    opacity: 1;
    padding: 0px 5px;
}

.v-tab.v-tab--active {
    font-weight: bold;
}

.v-toolbar__content {
    float: left;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.33px;
    color: #FA7E5C;
    opacity: 1;
}

.bannerbutton {
  text-align: center;
  padding: 0px 20px !important;
  font-size: 14px !important;
    font-weight: bold;
    margin-top: 10px;
    background: #FA7E5C 0% 0% no-repeat padding-box !important;
    border-radius: 34px;
    opacity: 1;
    margin-left: 5px;
}

@media only screen and (max-width: 960px) {
    .design3 {
        padding: 40px 20px 0px;
    }

    .cardtitle span {
        word-break: break-word;
    }

    .cardtext {
        height: 14em;
    }

    .bannerbutton {
    width: 96%;
  }

    .cardtitle {
        text-align: center;
    }

    .tablemenu {
        float: none;
    }

    .leftcard {
        padding-right: 0px;
    }

    .tablemenu {
        max-width: 350px;
    }

    .contentdesc {
        text-align: left;
    }
}
</style>
