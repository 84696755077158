<template>
  <div>
    <Header />
    <CareerBanner
      imageName="Careers.webp"
      mobbannerimage="Careers.webp"
      :header="header1"
      :desc="desc1"
      :descnew="desc2"
      :button="button1"
      headert="headertext"
      content="contenttext"
      bannerbutton="bannerbutton"
    />
    <!-- <Design20 /> -->
    <Design11 />

    <!-- <Design21 />

    <Design22 /> -->
    <Design23 />
    <Design10 />
  </div>
</template>

<script>
// import Design9 from "../components/Design9.vue";

import Design10 from "../components/Design10.vue";
import Header from "../components/Header.vue";
import Design11 from "../components/Design11.vue";
// import Design22 from "../components/Design22.vue";
import Design23 from "../components/Design23.vue";
// import Design21 from "../components/Design21.vue";
import CareerBanner from "../components/CareerBanner.vue";
export default {
  name: "Home",
  data() {
    return {
      header1: "Explore Life at Stellar!",
      desc1:
        "Stellar offers a wide range of exciting opportunities for young and experienced professionals to be part of our team, to bring ideas, innovativeness and determination to make a difference. ",
      desc2:
        "We work with exceptional people, the latest and greatest technologies and leading companies across industries to create value for our clients, people and communities. Choose a career with us and together, let's create positive, long-lasting value.",

      button1: "",
    };
  },
  mounted() {
    this.$store.dispatch("changeHeader", "careers");
  },
  components: {
    Design10,
    Header,
    Design11,
    // Design22,
    // Design21,
    Design23,
    CareerBanner,
  },
};
</script>

<style></style>
