<template>
  <div class="design4">
    <div class="sectiondesc">
      <v-row>
        <v-col lg="5" sm="12" class="imageleft hidden-md-and-down">
          <img class="imagewidth" src="../image/titleplant2.svg" alt="Title Production" />
        </v-col>
        <v-col lg="7" sm="12">
          <h1 class="contenthead">
            Key Takeaways from Stellar’s Title Production Services
          </h1>
          <p class="contentdesc">
            Indexing 3+ million records per month, covering 1400+ counties,
            through enhanced AI, ML and computer vison, maintaining 24hrs
            turnaround time
          </p>

          <ul class="contentlist">
            <li>
              <span class="contentlisthead">Image procurement</span>
            </li>
            <li>
              <span class="contentlisthead">Robust infrastructure</span>
            </li>
            <li>
              <span class="contentlisthead">Customized deliverables</span>
            </li>
            <li>
              <span class="contentlisthead"
                >Experienced 800+ plant processors</span
              >
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-btn class="cardtextbutton" to="/contact-us" rounded dark height="35px">
        Contact us
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Design15",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design4 {
  padding: 35px 8em 30px;
  background-color: white;
}

.LogoAnimation {
  width: 55px;
}
.iconview {
  text-align: left;

  /* box-shadow: 0 0 3px 2px #fff; */
}

.iconview .v-icon {
  font-size: 55px !important;
}
.titlecontent {
  width: 70%;
  margin: auto;
}

.smallbutton {
  display: none;
  text-align: left;
}

.cardcontent {
  /* margin: auto; */
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
}

.sectiondesc {
  margin-top: 30px;
}
.roundcontent {
  /* margin-top: -120px; */
}

.contentpadding {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}
.roundcontenttext {
  margin: auto;
  text-align: center;
  opacity: 1;
}

.sectiontitle {
  /* padding-top: 30px; */
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
}

.cardtitle {
  padding-top: 20px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtitletext {
  padding-top: 12px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.v-card__text {
  padding: 0px !important;
}
@media only screen and (max-width: 960px) {
  .contentpadding {
    padding-top: 12px;
  }
  .design4 {
    padding: 20px 20px;
    background-image: none;
    background-position: top 50% left 50%;
  }

  .iconview,
  .cardtitle {
    text-align: center;
  }

  .titlecontent {
    width: 100%;
  }

  .cardcontent {
    max-width: 100% !important;
  }
}

.imagewidth {
  /* box-shadow: 0px 0px 40px #0000003c; */
  width: 85%;
  border-radius: 10px;
}

.imageleft {
  margin-top: 30px;
  text-align: center;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 28px;
  /* letter-spacing: 0.42px; */
  color: #FA7E5C;
  opacity: 1;
  padding-bottom: 22px;
}

.contentlisthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.contentlistdesc {
  padding-top: 8px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtextbutton {
  width: 120px;
  font-size: 14px !important;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin-top: 30px !important;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin: auto;
}
.contentdesc {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  /* letter-spacing: 0.24px; */
  color: #151515;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  /* padding-bottom: 40px; */
}

.contentlist {
  margin-top: 40px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}
ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 0px 0px 0px 42px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: 0px -2px;
  background-size: 30px;
  height: 40px;
}

/* margin: 10 px 10 px 10 px 0 px;
padding: 0 px 0 px 4 px 40 px;
list-style: none;
background-image: url(/img/tickbullet.3152f99a.svg);
background-repeat: no-repeat;
background-position: left center;
background-size: 25 px; */

/* li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .imagewidth {
    /* width: -webkit-fill-available; */
  }

  .cardtextbutton {
    width: 100%;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: justify;
  }
}
</style>
