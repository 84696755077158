<template>
  <div id="blueblockpanel">
    <div class="sectiontitle">Real-World Success Stories</div>
          <div class="sectionbody">
            Discover how Stellar has empowered clients with Al and ML solutions, driving significant improvements in efficiency, accuracy, and innovation. From transforming healthcare diagnostics to automating title searches and enhancing mortgage decision-making, our case studies showcase the tangible benefits of our cutting-edge technologies. </div>
    </div>
</template>

<script>
export default {
  name: "Design34",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#blueblockpanel {
  background-image: url(../image/bluebackimage.svg);
  /* background-size: 100% 100%; */
  padding: 40px 8em 70px;
  /* background-size: 100% 950px; */
  padding: 0 8em;
  margin-top: 25px;
  height: 15em;
  transform: matrix(1, 0, 0, 1, 0, 0);
  opacity: 1;
  width: 100%;

  /* border-radius: 25px; */
}



.sectiontitle {
  /* padding-top: 30px; */
  /* -webkit-text-stroke: .2px #ffffff; */
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.45px;
  color: #FA7E5C;
  opacity: 1;
  padding-top:35px;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

#blueblockcard {
  /* padding: 0px 60px; */
}

#blueblockfield {
  padding: 0px 4em;
  margin-top: 4em;
  text-align: left;
}

#blueblockfieldend {
}

.imageicon {
  max-width: 55px;
  position: absolute;
  left: 0px;
  top: 6px;
}

#blueblockfield .row {
  margin-top: 0px;
}

.testerai {
  padding-right: 25px;
  margin-top: 50px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #FA7E5C;
  opacity: 1;
}

.testeraicontent {
  padding-right: 25px;
  margin-top: 20px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #7b7b7b;
  opacity: 1;
}

.cardtitleval {
  background-image: url("../image/logoicon.svg");
  vertical-align: middle;
  text-align: left;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: #147587;
  opacity: 1;
  padding: 10px 20px;
}

.cardtitleinfo {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #494949;
  opacity: 1;
  padding-left: 20px;
}

@media only screen and (max-width: 960px) {
  #blueblockpanel {
    background-image: none;
    background-color: #267677;
    height: auto;
    padding: 20px 30px;
  }

  #blueblockfield {
    padding: 0px 30px;
    margin-top: 20px;
  }

  .cardtitleval {
    font-size: 16px;
  }
  .imageicon {
    position: absolute;
    left: -20px;
    top: 15px;
    width: 140px;
  }
}

@media only screen and (max-width: 1280px) {
  #blueblockfield {
    padding: 0px 10px;
    margin-top: 20px;
  }

  .cardtitleval,
  .cardtitleinfo {
    padding-left: 55px;
  }
}
</style>
