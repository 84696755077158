<template>
  <div class="design32">
    <v-row 
      ><v-col class="design32content" lg="6" sm="12">
        <v-card class="leftcard" color="white" flat>
          <v-card-title class="cardtitleai">
            <span  style= "word-break: break-word" >Tailored AI & ML Solutions for Every Need</span>
          </v-card-title>
          <v-toolbar color="white" class="tablemenu" align-left height="40" >
            <template>
              <v-tabs v-model="tab" show-arrows>
                <v-tabs-slider color="#FA7E5C" width="175px" height="4"></v-tabs-slider>
                <v-tab class="Testtab" v-for="item in items" :key="item.menu">
                  {{ item.menu }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items class="cardtext" v-model="tab">
            <v-tab-item v-for="item in items" :key="item.text">
              <v-card flat>
                <p class="cardtextcontent">
                <ul>
                  <li >
                    <p class="cardheader"> {{ item.header1 }} </p>
                    
                  </li>
              
                    <p class="carddesc"> {{ item.desc1 }} </p>
                    
             
                  <li >
                    <p class="cardheader"> {{ item.header2 }} </p>
                    
                  </li>
    
                    <p class="carddesc"> {{ item.desc2 }} </p>
                    
              
                </ul>
                </p>
                <!-- <v-row align="left"> -->
                  <v-card-actions>
                    <v-btn class="cardtextbutton" :to="`/contact-us`" height="2.1875em" rounded dark>
                     {{ item.button }}
                    </v-btn>
                  </v-card-actions>
                <!-- </v-row> -->
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card> </v-col
      ><v-col lg="6" sm="12" class="hidden-md-and-down"
        ><img class="bannerimage" src="../image/aitailor.svg" alt="Fast Tax"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design32",
  props: {},
  data() {
    return {
      tab: null,
      items: [
        {
          menu: "Healthcare Solutions",
          header1:"Patient Data Analysis",
          header2:"Predictive Healthcare Management",
          desc1:"Enhancing patient care through comprehensive analysis and insights derived from vast healthcare datasets.",
          desc2:"Utilizing ML algorithms to predict disease outbreaks, patient admissions, and other critical healthcare trends.",
          button: "Get Started",
          route: "contact-us",
        },
        // {
        //   menu: "Title & Mortgage Solutions",
        //   header1:"Patient Data Analysis",
        //   header2:"Patient Data Analysis",
        //   desc1:"Patient Data Analysis",
        //   desc2:"Patient Data Analysis",
        //   button: "Get Started",
        //   route: "contact-us",
        // },
      ],

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design32 {
  padding: 2em 8em 0px;
  background-color: white;
  /* margin-top: 30px; */
}

.design32 .row {
  margin: 0px;
}
.cardtext {
  width: 100% !important;
  /* height: 31em; */
}
.leftcard {
  padding-right: 20px;
}

.cardheader{
  text-align: left;
font-weight: bold;
font-size: 16px;

letter-spacing: 0px;
color: #343434;
opacity: 1;
}

.carddesc{
  text-align: left;
  margin-left:30px;
font-size: 16px;
letter-spacing: 0px;
color: #656565;
opacity: 1;
  
}
.design32content {
  padding: 0px;
}
.bannerimage {
  margin-top: 60px;
  max-width: 100%;
}
.cardtitleai {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0px;
  /* white-space: nowrap; */
  /* word-wrap:normal; */
  color: #FA7E5C;
  opacity: 1;
  padding: 1em 0px;
}

.Testtab {
  margin-right: 25px;
  text-transform: none;
}

.tablemenu {
  box-shadow: none !important;
  border-bottom: #151515;
  margin-top: 10px;
  float: left;
  padding-left:16px;
}



.cardtextcontent {
  /* padding-left:16px; */
  margin-top: 2em;
  text-align: justify;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-bottom: 0px;
}

/* ul {
  list-style: none;
}

li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */
ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 2px 0px 0px 30px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
}

.v-card__actions {
  padding: 0px;
}
.v-tab.v-tab {
  color: #fa7e5c;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  opacity: 1;
  padding: 0px;
}

.v-tab.v-tab--active {
  font-weight: bold;
}

.v-toolbar__content {
  float: left;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
   font-family: "Montserrat", sans-serif;
  letter-spacing: 0.33px;
  color: #fa7e5c;
  opacity: 1;
}

.cardtextbutton {
  font-size: 14px !important;
  /* font-weight: bold; */
  margin-top: 10px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
  padding: 5px 12px !important;
}

@media only screen and (max-width: 960px) {
  .design32 {
    padding: 40px 20px 0px;
  }



  .cardtitleai {
    text-align: center;
  }
  .cardtitleaispan {
    white-space:pre-wrap !important;
  word-break:break-word !important;
  }
  .tablemenu {
    float: none;
  }

  .leftcard {
    padding-right: 0px;
  }

  .tablemenu {
    max-width: 850px;
  }
}
</style>
