var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('Bannerfull1',{attrs:{"imageName1":"fastmonitorbg.png","imageName2":"fasttaxmoniimage2.svg","mobimageName":"fastmonitorbg.png"}}),_c('div',{staticClass:"smallflvblock hidden-md-and-down"},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/customized_reoprt.svg"),"alt":"Report"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Reports in 4 to 8 hours ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Our Automation solutions deliver your customized property reports faster ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/quick_turnaround.svg"),"alt":"TAT"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Guaranteed Accuracy ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Independent QA team performs multilevel checks ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/nationwide.svg"),"alt":"Nation"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Nationwide Coverage ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Knowledge of every State jurisdiction and sources of retrieval – City, Sewer, School, etc. ")])])],1)]}}])})],1),_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/datasecurity.svg"),"alt":"Data Security"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Data Security ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Data security is of prime importance in financial services sector, and we have you covered. Your data is in safe hands! ")])])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"smallflvblock hidden-lg-and-up"},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/customized_reoprt.svg"),"alt":"Report"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Reports in 4 to 8 hours ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Our Automation solutions deliver your customized property reports faster ")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/quick_turnaround.svg"),"alt":"TAT"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Reports in 4 to 8 hours ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Our Automation solutions deliver your customized property reports faster ")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/nationwide.svg"),"alt":"Nation"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Nationwide Coverage ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Knowledge of every State jurisdiction and sources of retrieval – City, Sewer, School, etc. ")])])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3","sm":"12"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 0,"max-width":"300"}},[_c('v-card-text',[_c('img',{staticClass:"LogoAnimationflvblock",attrs:{"src":require("../image/datasecurity.svg"),"alt":"Data Security"}}),_c('p',{staticClass:"block-title",class:{ 'on-hover': hover }},[_vm._v(" Data Security ")]),_c('div',{staticClass:"block-desc",class:{ 'on-hover': hover }},[_vm._v(" Data security is of prime importance in financial services sector, and we have you covered. Your data is in safe hands! ")])])],1)]}}])})],1)],1)],1),_c('Design14',{attrs:{"header":"FastTaxCert","desc1":"With a number of information pointers, property tax reports are not that simple and often cumbersome to prepare. Understanding the requirements of title agents, lenders, and govt. agencies, Stellar Innovation has developed a packaged solution in the form of FastTax Certificate.","desc2":"With close to a decade of experience in delivering title production and settlement services, Stellar understands the need for accurate in-time tax reports for quick closures. Moreover, the agency-specific customized mortgage tax statements are what mortgage lenders and financial institutions need for an informed handover.","imagepath":"taxcert_image.webp","button":"Ask for a quote"}}),_c('Range'),_c('Benefits'),_c('Design10')],1)}
var staticRenderFns = []

export { render, staticRenderFns }