<template>
  <div class="design3">
    <v-row 
      ><v-col class="design3content" lg="6" sm="12">
        <v-card class="leftcard" color="white" flat>
          <v-card-title class="cardtitle">
            <span>Key Products</span>
          </v-card-title>
          <v-card-text class="contenttext">
           Digital Solutions for Lending, Servicing, Title Insurance, and Real Estate. We are here for you for the life of the loan</v-card-text>
          <v-toolbar color="white" class="tablemenu" align-left height="40" >
            <template>
              <v-tabs v-model="tab" show-arrows>
                <v-tabs-slider color="#FA7E5C" width="175px" height="4"></v-tabs-slider>
                <v-tab class="Testtab" v-for="item in items" :key="item.menu">
                  {{ item.menu }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items class="cardtext" v-model="tab">
            <v-tab-item v-for="item in items" :key="item.text">
              <v-card flat>
                <p class="cardtextcontent">
                  {{ item.text }}
                </p>
                <p class="cardtextcontent">
                <ul>
                  <li v-for="item in item.takeaways" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                <!-- <v-row align="left"> -->
                  <v-card-actions>
                    <v-btn class="cardtextbutton" :to="`/${item.route}`" height="2.1875em" rounded dark>
                     {{ item.button }}
                    </v-btn>
                  </v-card-actions>
                <!-- </v-row> -->
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card> </v-col
      ><v-col lg="6" sm="12" class="hidden-md-and-down"
        ><img class="bannerimage" src="../image/Fast_tax_certificate.webp" alt="Fast Tax"
      /></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design3",
  props: {},
  data() {
    return {
      tab: null,
      items: [
        {
          menu: "FastTaxCert",
          text: `Our automated tax solution offers you a complete suite of tax information to meet your requirements throughout the life of a mortgage cycle.
`,
          takeaways: [
            "Customized Tax Templates",
            "1800+ Counties Automated",
            "Nationwide Coverage",
            "Agency Specific Reports ",
            "Integrated Feedback & Reporting Module",
            "API Plugins ",
          ],
          button: "Get Started",
          route: "taxcert",
        },
        {
          menu: "FastTax Monitoring ",
          text: `Our automated life of loan tax tracking solution offers you a complete suite of tax tracking modules to have an adequate check on the portfolios that you service. Get a complete visibility on how your portfolios are performing through personalized dashboards that meet your requirements.`,
          takeaways: [
            "Customized Reports",
            "Tax Watch",
            "Built to manage Escrow & Non-Escrow Portfolios",
            "Integrated Feedback & Reporting Module",
            "Nationwide Coverage",
            "API Plugins ",
          ],
          button: "Get Started",
          route: "taxmonitor",
        },
        {
          menu: "UniDex",
          text: `Processing of documents for carrying out data extraction/indexing, validation and delivery is cumbersome to set up and maintain.  It involves time consuming processes, expensive resources and servers for managing the IT requirements, and is prone to human errors and inconsistencies.`,
          takeaways: [
            "Typing and Indexing",
            "Search Automation",
            "Data Sourcing",
            "Doc Stacking",
            "Doc Classification",
            "Advanced reporting plugins",
            "Web integrability",
          ],
          button: "Get Started",
          route: "unidex",
        },
      ],

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design3 {
  padding: 2em 8em 0px;
  background-color: white;
  /* margin-top: 30px; */
}

.design3 .row {
  margin: 0px;
}
.cardtext {
  width: 100% !important;
  /* height: 31em; */
}
.leftcard {
  padding-right: 20px;
}
.design3content {
  padding: 0px;
}
.bannerimage {
  /* margin-top: 60px; */
  max-width: 100%;
}
.cardtitle {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0px;
  color: #FA7E5C;
  opacity: 1;
  padding: 1em 0px;
}

.Testtab {
  margin-right: 25px;
  text-transform: none;
}

.tablemenu {
  box-shadow: none !important;
  border-bottom: #151515;
  margin-top: 10px;
  float: left;
  padding-left:16px;
}

.contenttext {
  padding: 0px 0px;
  margin-bottom: 1em;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515 !important;
}

.cardtextcontent {
  /* padding-left:16px; */
  margin-top: 2em;
  text-align: justify;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-bottom: 0px;
}

/* ul {
  list-style: none;
}

li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */
ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 2px 0px 0px 40px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px;
}

.v-card__actions {
  padding: 0px;
}
.v-tab.v-tab {
  color: #fa7e5c;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  opacity: 1;
  padding: 0px;
}

.v-tab.v-tab--active {
  font-weight: bold;
}

.v-toolbar__content {
  float: left;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
   font-family: "Montserrat", sans-serif;
  letter-spacing: 0.33px;
  color: #fa7e5c;
  opacity: 1;
}

.cardtextbutton {
  font-size: 14px !important;
  /* font-weight: bold; */
  margin-top: 10px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
  padding: 5px 12px !important;
}

@media only screen and (max-width: 960px) {
  .design3 {
    padding: 40px 20px 0px;
  }

  .cardtitle span {
    word-break: break-word;
  }

  .cardtitle {
    text-align: center;
  }

  .tablemenu {
    float: none;
  }

  .leftcard {
    padding-right: 0px;
  }

  .tablemenu {
    max-width: 850px;
  }
}
</style>
