<template>
  <div class="design4">
    <div class="roundcontent">
      <div class="roundcontenttext">
        <div class="titlecontent">
          <div class="sectiontitle">
            Solution Oriented and Technology Enabled Service Portfolio
          </div>
          <div class="sectionbody">
            As a modern-day ITES company, technology adoption and integration
            has been the core essence of Stellar’s Title and Mortgage services
          </div>
        </div>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/robotic.svg" alt="RPA Solutions" />
                <h3 class="cardtitle">RPA Solutions</h3>
                <p class="cardtitletext">
                  We develop solutions to automate repetitive tasks and manual
                  processes – enhancing work efficiency, cost savings by
                  interacting with websites, databases, business applications
                  and people.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/AI.svg" alt="AI & ML Solutions" />
                <h3 class="cardtitle">AI & ML Solutions</h3>
                <p class="cardtitletext">
                  Leverage data engineering innovations for semantic analysis,
                  turn data into insights and efficiencies, visualization of
                  information and integrated process for decision making.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Automation.svg" alt="Customized Product Solutions" />
                <h3 class="cardtitle">Customized Product Solutions</h3>
                <p class="cardtitletext">
                  We connect to learn your business requirements and map it with
                  appropriate technical solution to develop products and
                  solutions
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/ProcessReeng.svg" alt="Re-engineering Consultancy" />
                <h3 class="cardtitle">Process Re-engineering Consultancy</h3>
                <p class="cardtitletext">
                  Delivering quality products through our technology led white
                  glove service. We promise 30%-40% efficiency gain through
                  process re-engineering.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Mortgage.svg" alt="Mortgage Services" />
                <h3 class="cardtitle">Mortgage Services</h3>
                <p class="cardtitletext">
                  Delivering modern and cost – effective solutions and services
                  to uplift your deliverables to your clients. Our team of
                  mortgage experts offer best service offering to address
                  factors like 24/5 coverage, overflow
                  and faster turnaround times.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/NationwideTitle.svg" alt="Title Searches" />
                <h3 class="cardtitle">Nationwide Title Searches</h3>
                <p class="cardtitletext">
                  We have a Nationwide coverage through our Online and Vendor
                  Network, our team of examiners have a thorough understanding
                  on state statues & guidelines to deliver best in class
                  verified searches.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/NationwideTax.svg" alt="Tax Solutions" />
                <h3 class="cardtitle">Nationwide Tax Solutions</h3>
                <p class="cardtitletext">
                  In line to our vision of positioning ourselves as a technology
                  lead services company, we developed FastTaxCert. This product
                  generates customized tax certs to meet your requirement for
                  Portfolio Management, Life of Loan – Tax Monitoring or Title
                  Closing.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              text-left
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/PostClose.svg"  alt="PostClose"/>
                <h3 class="cardtitle">PostClose & Recording</h3>
                <p class="cardtitletext">
                  We understand the criticality of the files when it comes to
                  closing desk, quick turnaround time and precision in getting
                  tasks completed correctly eases lot of last-minute stress at
                  the closing table.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-md-and-up">
          <v-col sm="12" md="6">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/Automation_Consultant.svg"
                  alt="Plotting"
                />
                <div class="cardtitle">Easement Plotting</div>
                <p class="cardtitletext">
                  Our team of experts understand the criticality and the
                  importance of being accurate on highlighting the exceptions
                  and easements for the property in question. Our team experts
                  are well versed with state requirements.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="contentpadding hidden-sm-and-down">
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/robotic.svg" alt="RPA Solutions" />
              <h3 class="cardtitle">RPA Solutions</h3>
              <p class="cardtitletext">
                We develop solutions to automate repetitive tasks and manual
                processes – enhancing work efficiency, cost savings by
                interacting with websites, databases, business applications and
                people.
              </p>
            </v-card-text>
          </v-card>

          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/AI.svg" alt="AI & ML Solutions" />
              <h3 class="cardtitle">AI & ML Solutions</h3>
              <p class="cardtitletext">
                Leverage data engineering innovations for semantic analysis,
                turn data into insights and efficiencies, visualization of
                information and integrated process for decision making.
              </p>
            </v-card-text>
          </v-card>

          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Automation.svg" alt="Product Development"/>
              <h3 class="cardtitle">Customized Product Development</h3>
              <p class="cardtitletext">
                We connect to learn your business requirements and map it with
                appropriate technical solution to develop products and
                solutions.
              </p>
            </v-card-text>
          </v-card>
        </div>
        <div class="contentpadding hidden-sm-and-down">
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/ProcessReeng.svg" alt="Process Re-engineering" />
              <h3 class="cardtitle">Process Re-engineering Consultancy</h3>
              <p class="cardtitletext">
                Delivering quality products through our technology led white
                glove service. We promise 30%-40% efficiency gain through
                process re-engineering.
              </p>
            </v-card-text>
          </v-card>
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Mortgage.svg" alt="Mortgage Services" />
              <h3 class="cardtitle">Mortgage Services</h3>
              <p class="cardtitletext">
                Delivering modern and cost – effective solutions and services to
                uplift your deliverables to your clients. Our team of mortgage
                experts offer best service offering to address factors like 24/5 coverage, overflow and faster turn
                times.
              </p>
            </v-card-text>
          </v-card>
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/NationwideTitle.svg" alt="Title Searches"/>
              <h3 class="cardtitle">Nationwide Title Searches</h3>
              <p class="cardtitletext">
                We have a Nationwide coverage through our Online and Vendor
                Network, our team of examiners have a thorough understanding on
                state statues & guidelines to deliver best in class verified
                searches.
              </p>
            </v-card-text>
          </v-card>
        </div>
        <div class="contentpadding hidden-sm-and-down">
          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/NationwideTax.svg" alt="Tax Solutions"/>
              <h3 class="cardtitle">Nationwide Tax Solutions</h3>
              <p class="cardtitletext">
                In line to our vision of positioning ourselves as a technology
                lead services company, we developed FastTaxCert. This product
                generates customized tax certs to meet your requirement for
                Portfolio Management, Life of Loan – Tax Monitoring or Title
                Closing.
              </p>
            </v-card-text>
          </v-card>
          <v-card
            class="cardcontent"
            text-left
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/PostClose.svg" alt="PostClose"/>
              <h3 class="cardtitle">PostClose & Recording</h3>
              <p class="cardtitletext">
                We understand the criticality of the files when it comes to
                closing desk, quick turnaround time and precision in getting
                tasks completed correctly eases lot of last-minute stress at the
                closing table.
              </p>
            </v-card-text>
          </v-card>

          <v-card
            class="cardcontent"
            flat
            max-width="21.25em"
            min-width="300px"
          >
            <v-card-text class="iconview">
              <img
                class="LogoAnimation"
                src="../image/Automation_Consultant.svg"
                alt="Easement Plotting"
              />
              <h3 class="cardtitle">Easement Plotting</h3>
              <p class="cardtitletext">
                Our team of experts understand the criticality and the
                importance of being accurate on highlighting the exceptions and
                easements for the property in question. Our team experts are
                well versed with state requirements.
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Design4",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design4 {
  background-image: url(../image/circle.svg);
  padding: 40px 8em 70px;
  background-color: white;
  background-position: center;
  background-size: 100% 950px;
}

.iconview {
  text-align: left;

  /* box-shadow: 0 0 3px 2px #fff; */
}

.iconview .v-icon {
  font-size: 55px !important;
}
.titlecontent {
  width: 70%;
  margin: auto;
  padding-bottom: 20px;
}

.cardcontent {
  /* margin: auto; */
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  padding-right: 15px;
}
.roundcontent {
  /* margin-top: -120px auto 0px auto; */
  /* margin: auto; */

  /* background: transparent
    radial-gradient(
      closest-side at 50% 42%,
      #4a7e88 0%,
      #78b2bed1 0%,
      #3a565b00 100%
    )
    0% 300% no-repeat padding-box; */
}

.LogoAnimation {
  width: 55px;
  /* transform: rotateY(560deg);
  animation: turn 3.5s ease-out forwards 1s; */
}

@keyframes turn {
  100% {
    transform: rotateY(0deg);
  }
}

.contentpadding {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}
.roundcontenttext {
  margin: auto;
  text-align: center;
  opacity: 1;
}

.sectiontitle {
  padding-top: 30px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.42px;
  color: #FA7E5C;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  /* opacity: 0.5; */
}

.cardtitle {
  padding-top: 20px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtitletext {
  padding-top: 12px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  /* padding-right: 25px; */
}

.v-card__text {
  padding: 0px !important;
}
@media only screen and (max-width: 960px) {
  .design4 {
    padding: 20px 20px;
    background-position: top 50% left 50%;
    background-image: none;
  }

  .titlecontent {
    width: 100%;
  }
  .iconview,
  .cardtitle {
    text-align: center;
  }
  .cardcontent {
    max-width: 100% !important;
  }

  .contentpadding {
    padding-top: 0px;
  }
}
</style>
