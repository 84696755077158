<template>
  <div class="sectioncontent">
    <v-row>
      <v-col lg="12" sm="12">
        <h1 class="contenthead">
          {{ header }}
        </h1>
        <p class="contentdesc">
          {{ desc1 }}
        </p>
        <p v-if="desc2" class="contentdesc">
          {{ desc2 }}
        </p>

         <v-row justify="center" class="accordiancont">
          <v-col>

            <p class="headcontent" > Mortgage Origination </p>
            <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>{{items1[0]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo1" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
 
      <v-expansion-panel>
        <v-expansion-panel-header>{{items1[1]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo2" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
    
      <v-expansion-panel>
        <v-expansion-panel-header>{{items1[2]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo3" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
   
      <v-expansion-panel>
        <v-expansion-panel-header>{{items1[3]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo4" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
   
      <v-expansion-panel>
        <v-expansion-panel-header>{{items1[4]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo5" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
          </v-col>
 <v-col lg="4" sm="12" class="imageleft hidden-md-and-down">
        <img class="imagewidth" :src="require('../image/' + imagepath + '')" alt="Dynamic image"/>
      </v-col>
          <v-col>
             <p class="headcontent" > Mortgage Servicing </p>
            <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>{{items2[0]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo6" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
  
      <v-expansion-panel>
        <v-expansion-panel-header>{{items2[1]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo7" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
 
      <v-expansion-panel>
        <v-expansion-panel-header>{{items2[2]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo8" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
 
      <v-expansion-panel>
        <v-expansion-panel-header>{{items2[3]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo9" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
   
      <v-expansion-panel>
        <v-expansion-panel-header>{{items2[4]}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="bulletinfo1" class="cardtextcontent">
                <ul>
                  <li v-for="item in bulletinfo10" :key="item">
                    {{ item }}
                  </li>
                </ul>
                </p>
                </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-col> 
  </v-row>
      </v-col>

      
     
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design25",
  props: [
    "header",
    "desc1",
    "desc2",
    "imagepath",
    "button",
    "buttonval",
   
  ],
  data() {
    return {
       items1: ["Processing Services", "Underwriting Services", "Underwriting Quality Control", "Closing / Post Closing Services", "Due Diligence Support"],
       bulletinfo1:["Indexing", "Loan Set-up", "Initial Disclosure", "Vendor Ordering", "UW Submissions"],
        bulletinfo2:["Pre-Underwriting", "Licensed Underwriting", "Condition Clearing"],
        bulletinfo3:["Pre-Funding UW QC","Post Close Underwriting QC","Pre-purchase Review","EPD Review","MSR Review","Component Review"],
        bulletinfo4:["Doc Drawing","CD Preparation","Trailing Documents","Binder Preparation"],
        bulletinfo5:["Document Classification" ,"Data Extraction","Tape Data Validation","Data Integrity check","Final Report Reconciliation"],
            items2: ["Loan Onboarding", "Payment & Escrow Management", "Default Management", "Loss Mitigation", "Investor Services"],
       bulletinfo6:["Document Indexing","Loan Set-up","Trailing Documents"],
        bulletinfo7:["Payment Processing","Account Reconciliation","Customer Support", "Escrow Set-up", "Escrow Management","Pay-Off Request"],
        bulletinfo8:["Skip Tracing","Late Payment Notices","Collections"],
        bulletinfo9:["Modification Review",
"BPO / Valuation Services",
"Bankruptcy Support",
"Foreclosure Support",
"Assets Management",
"Claims Processing",
"Property Preservation",
"Title Curative"
],
        bulletinfo10:["Investor Reporting",
"Non-Performing Asset Review (NPL)",
"Performing Asset Review (RPL)",
"MSR Review",
"Compliance Review",
"Collateral Review"
],
       

};
  },
  methods: {
    redirecttocontact() {
      this.$store.dispatch("changeHeader", "Contactus");
      return this.$router.push(`./contact-us`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sectioncontent {
  margin-top: 35px;
  margin-left: 8em;
  margin-right: 8em;
  padding-bottom: 20px;
}
.imagewidth {
  /* box-shadow: 0px 0px 40px #0000003c; */
  width: 80%;
  border-radius: 10px;
  /* margin-left: 60px; */
}

.imageleft {
  margin: auto;
  text-align: center;
}

.bannerbutton {
  margin-left: 0px;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.42px;
  font-size: 30px;
  color: #FA7E5C;
  opacity: 1;
  padding-bottom: 22px;
  padding-top: 20px;
}

.cardtextcontent {
  /* padding-left:16px; */
  /* margin-top: 30px; */
  text-align: justify;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-bottom: 0px;
}

ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin-left: 20px;
  padding: 0px 0px 0px 30px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
}

.accordiancont{
  margin-top:40px;
}
.headcontent{
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.24px;
  color: #151515;
  padding-bottom: 10px;
}
.contentdesc {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515;
  padding-bottom: 10px;
}



.contentlist {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .imagewidth {
    /* width: -webkit-fill-available; */
  }
  .bannerbutton {
    width: 100%;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: center;
  }
}
</style>
