<template>
  <div class="contentblock">
    <div class="titlecontent">
      <div class="sectiontitle">
        Offering the best user experience & customer satisfaction through our
        products and services
      </div>

      <div class="sectioncard hidden-md-and-down">
        <v-card class="cardinfo mx-auto" max-width="80%">
          <v-row
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">46+</p>
                <p class="desccontent">States</p>
              </v-card-text></v-col
            >
            <v-divider class="hidden-md-and-down" inset vertical></v-divider
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">2300+</p>
                <p class="desccontent">Counties</p>
              </v-card-text></v-col
            >
            <v-divider class="hidden-md-and-down" inset vertical></v-divider
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">1 MILLION+</p>
                <p class="desccontent">Tax Searched (from 2019 )</p>
              </v-card-text></v-col
            >
            <v-divider class="hidden-md-and-down" inset vertical></v-divider
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">1800+</p>
                <p class="desccontent">Govt Agencies</p>
              </v-card-text></v-col
            ></v-row
          >
        </v-card>
      </div>
      <div class="sectioncard hidden-lg-and-up">
        <v-card class="cardinfo mx-auto" max-width="70%">
          <v-row
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">46+</p>
                <p class="desccontent">States</p>
              </v-card-text></v-col
            >
          </v-row>
          <v-divider></v-divider>
          <v-row
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">2300+</p>
                <p class="desccontent">Counties</p>
              </v-card-text></v-col
            >
          </v-row>
          <v-divider></v-divider>
          <v-row
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">1 MILLION+</p>
                <p class="desccontent">Tax Searched (from 2019 )</p>
              </v-card-text></v-col
            >
          </v-row>
          <v-divider></v-divider>
          <v-row
            ><v-col lg="3" sm="6"
              ><v-card-text
                class="font-weight-medium text-center text-subtitle-1"
              >
                <p class="colorcontent">1800+</p>
                <p class="desccontent">Govt Agencies</p>
              </v-card-text></v-col
            ></v-row
          >
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Design17",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contentblock {
  padding: 0px 8em;
  margin: auto;
}

.cardinfo {
  height: 120px;
}

.v-sheet {
  box-shadow: none !important;
  border: 1px solid #dddddd;
  border-radius: 25px;
}
.sectiontitle {
  padding-top: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 0.5;
}

.sectioncard {
  padding: 45px 0px 70px 0px;
}

.v-divider--vertical {
  margin-top: 12px;
}
.colorcontent {
  color: #fa7e5c;
  font-weight: bold;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 6px;
}

.v-card__text {
  padding: 0px !important;
  font-size: 16px;
}

.desccontent {
  color: black;
  font-size: 15px;
}

@media only screen and (max-width: 960px) {
  .cardinfo {
    height: 460px;
  }

  .contentblock {
    padding: 0px 20px;
    margin: auto;
  }

  .sectioncard {
    padding: 45px 0px 30px 0px;
  }
}
</style>
