<template>
  <div id="bannertrans">
    <Headertrans />
    <v-row no-gutters>
      <v-col class="hidden-md-and-down" lg="12" sm="12">
        <v-card flat tile color="transparent">
          <img
            class="bannertransimage animate__animated animate__slideInDown animate__faster"
            :src="require('../image/' + imageName + '')"
            :alt="imageName"
          />
          <img
            class="bannerleftimage animate__animated animate__fadeIn animate__faster
            "
            :src="require('../image/' + imageName1 + '')"
            :alt="imageName1"
          />
          <v-card :class="block" flat tile color="transparent">
            <div
              v-if="header"
              class="
                hidden-md-and-down
                textduration
                animate__animated animate__fadeInUp animate__faster
              "
              :class="headert"
            >
              {{ header }}
            </div>
            <p v-if="desc"
              class="
                hidden-md-and-down
                textduration
                animate__animated animate__fadeInUp animate__faster
              "
              :class="content"
            >
              {{ desc }}
            </p>
            <v-btn
              v-if="button"
              :class="bannerbutton"
              rounded
              height="35px"
              color="#FA7E5C"
              dark
              small
              :to="route"
            >
              <v-icon left dark> mdi-account </v-icon>
              {{ button }}
            </v-btn>
          </v-card>
        </v-card>
      </v-col>
      <v-col class="hidden-lg-and-up" lg="12" sm="12">
        <v-card flat tile color="transparent">
          <img
            class="mobbannertransimage"
            :src="require('../image/' + mobimageName + '')"
            :alt="mobimageName"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Headertrans from "../components/Headertrans.vue";
export default {
  name: "Banner",
  data: () => ({
    alignments: ["start", "center", "end"],
  }),
  components: { Headertrans },
  props: [
    "imageName",
    "mobimageName",
    "imageName1",
    "mobimageName1",
    "header",
    "desc",
    "button",
    "block",
    "route",
    "headert",
    "content",
    "bannerbutton",
  ],
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bannertransimage {
  /* height: 500px; */
  width: 100%;
}

.bannerleftimage {
  max-height: 300px;

  position: absolute;
  left: 7em;
  top: 140px;
}

.mobbannertransimage {
  /* height: 200px; */
  width: 100%;
}

.bannerbutton {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
  margin-left: 15px;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: 25px;
}

.bannertransimage .animate__animated.animate__slideInRight {
  --animate-duration: 8s;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 6s;
}

/* .animate__animated.animate__slideInRight {
  --animate-duration: 8;
} */

.bannerbuttonright {
  margin-left: 15px;
  float: right;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: 25px;
}
.leftblock {
  position: absolute;
  top: 100px;
  max-width: 800px;
  padding-left: 8em;
}

.rightblock {
  position: absolute;
  top: 100px;
  max-width: 700px;

  right: 8em;
}

.righttransblockwhite {
  color: #fff;
  position: absolute;
  top: 150px;
  max-width: 800px;
  padding-left: 40px;
  padding-right: 8em;
  right: 40px;
}

.righttransblockwhite .headertext {
  color: #fff;
  text-align: right;
  padding-right: 0px;
}

.righttransblockwhite .contenttext {
  color: #fff;
  text-align: right;
  padding: 0px;
}

.headertranstextright {
  padding: 0px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #fff;
  opacity: 1;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.contenttranstextright {
  padding: 0px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0.24px;
  color: #fff;
  opacity: 1;
}

.headertext {
  padding: 0px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #1c1c1c;
  opacity: 1;
  padding-bottom: 0px;
  padding-right: 60px;
  margin-bottom: 0px;
}

.colorinherit {
  background-color: #bebebe1d;
}

.contenttext {
  padding: 0px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: #1c1c1c;
  opacity: 1;
  padding-right: 100px;
}

#bannertrans {
  /* margin-top: 60px; */
  background-color: White;
}

@media only screen and (max-width: 960px) {
  #bannertrans {
    /* margin-top: 60px; */
  }

  .bannerbutton {
    float: none;
    width: 80%;
  }

  .bannerbuttonright {
    float: none;
    width: 80%;
  }

  .headertext {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #1c1c1c;
    opacity: 1;
  }

  .headertext,
  .contenttext,
  .contenttranstextright,
  .headertranstextright {
    padding: 0px 10px;
    text-align: justify;
  }

  .bannertransimage {
    max-width: -webkit-fill-available;
  }
}

@media only screen and (min-width: 1281px) {
  .bannerleftimage {
    max-height: 380px;
  }
}

@media only screen and (max-width: 1350px) {
  .bannerleftimage {
    max-width: 350px;
  }
}
</style>
