<template>
  <div class="sectioncontent">
    <v-row>
      <v-col lg="6" sm="12">
        <h2 class="contenthead">Why Partner with Stellar Innovations</h2>
        <p class="contentdesc">
          Stellar has been offering excellent customer service through its
          technology and service offerings from past 7+ years. You can count on
          us when it comes to strategic alliance, efficiency gain & cost
          optimization. Your need for one stop solution partner ends here.
        </p>

        <ul class="contentlist">
          <li><b>Dedicated Project Management Team </b></li>
          <li><b>SOC 2 Certified </b></li>
          <li><b>24/5 Operations </b></li>
          <li><b>Aggressive Ramp-Up Model </b></li>
          <li><b>Transactional Model </b></li>
          <li><b>Onshore, Offshore & Hybrid - per client requirements</b></li>
        </ul>
        <div class="text-left">
          <v-btn
            @click="returntocontact"
            class="cardtextbutton"
            height="35px"
            rounded
            dark
          >
            Request Quote
          </v-btn>
        </div>
      </v-col>
      <v-col lg="6" sm="12">
        <v-row>
          <v-col lg="6" sm="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card :elevation="hover ? 6 : 0" class="alignleft" flat>
                <img class="imagelogo" src="../image/global_presence.svg" alt="Global Presence" />
                <h3 class="headercontent">Global Presence</h3>
                <p class="contentdesc_block">
                  With a satisfied clientele across the globe, Stellar has a
                  global presence – Maitland, USA, UAE, and India.
                </p>
                <v-btn class="smallbutton" x-small color="#FA7E5C" text rounded>
                  Learn more
                </v-btn>
              </v-card>
            </v-hover>
          </v-col>
          <v-col lg="6" sm="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card :elevation="hover ? 6 : 0" class="alignleft" flat>
                <img class="imagelogo" src="../image/skilled_resource.svg" alt="Skilled Resources" />
                <h3 class="headercontent">Skilled Resources</h3>
                <p class="contentdesc_block">
                  Stellar has over 2000+ skilled employees catering to
                  technology and business process requirements.
                </p>
                <v-btn class="smallbutton" x-small color="#FA7E5C" text rounded>
                  Learn more
                </v-btn>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="6" sm="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card :elevation="hover ? 6 : 0" class="alignleft" flat
                ><img class="imagelogo" src="../image/iso_certified.svg" alt="Certified & Compliant"/>
                <h3 class="headercontent">Certified & Compliant</h3>
                <p class="contentdesc_block">
                  ISO 27001, ISO 9001 certified, SOC 2 certified are the
                  testimonials for our data security policies and practices.
                </p>

                <v-btn class="smallbutton" x-small color="#FA7E5C" text rounded>
                  Learn more
                </v-btn>
              </v-card>
            </v-hover>
          </v-col>
          <v-col lg="6" sm="6">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card class="alignleft" :elevation="hover ? 6 : 0" flat
                ><img class="imagelogo" src="../image/secured.svg" alt="Secured Work Environment" />
                <h3 class="headercontent">Secured Work Environment</h3>
                <p class="contentdesc_block">
                  Stellar has infrastructure, policies and practices in place to
                  secure and protect client data.
                </p>

                <v-btn class="smallbutton" x-small color="#FA7E5C" text rounded>
                  Learn more
                </v-btn>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <h2 class="contenthead">Our Testimonials</h2>
    <v-row class="hidden-md-and-down"
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/testi1.webp"
            alt="Tesimonials 01"
          /> </v-card></v-col
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/testi3.webp"
            alt="Tesimonials 02"
          /> </v-card></v-col
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/testi2.webp"
            alt="Tesimonials 03"
          /> </v-card></v-col
      ><v-col>
        <v-card outlined color="transparent" max-width="374">
          <img
            class="testiimagelogo"
            src="../image/testi4.webp"
            alt="Tesimonials 04"
          /> </v-card></v-col
    ></v-row>

    <v-row class="hidden-lg-and-up"
      ><v-col>
        <v-card  outlined color="transparent" max-width="auto">
          <img class="testiimagelogo" src="../image/testi1.webp" alt="Testimonial 01"/> </v-card
      ></v-col> </v-row
    ><v-row class="hidden-lg-and-up"
      ><v-col>
        <v-card outlined color="transparent" max-width="auto">
          <img
            class="testiimagelogo"
            src="../image/testi2.webp"
            alt="Testimonial 02"
          /> </v-card></v-col></v-row
    ><v-row class="hidden-lg-and-up"
      ><v-col>
        <v-card outlined color="transparent" max-width="auto">
          <img
            class="testiimagelogo"
            src="../image/testi3.webp"
            alt="Testimonial 03"
          /> </v-card></v-col></v-row
    ><v-row class="hidden-lg-and-up"
      ><v-col>
        <v-card outlined color="transparent" max-width="auto">
          <img
            class="testiimagelogo"
            src="../image/testi4.webp"
            alt="Testimonial 04"
          /> </v-card></v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  name: "Design7",
  props: {},
  data() {
    return {};
  },
  methods: {
    returntocontact() {
      this.$store.dispatch("changeHeader", "Contactus");
      return this.$router.push(`./contact-us`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
  opacity: 0.38;
}

.v-card.on-hover.theme--dark.v-card__text {
  color: #000;
}

.sectioncontent {
  background-color: white;
  text-align: left;
  /* margin-top: 25px;
  margin-bottom: 25px; */
  padding: 70px 8em 30px;
}

.cardtextbutton {
  font-size: 0.7em !important;
  font-weight: bold;
  margin-top: 15px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
}

.headercontent {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  color: #FA7E5C;
  opacity: 1;
  padding-top: 20px;
}

.imagelogo {
  height: 35px;
  width: 35px;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}
.testiimagelogo {
  /* height: 25%; */
  width: 100%;
}
.smallbutton {
  display: none;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  bottom: 15px;
}

.alignleft {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: left;
  padding: 20px;
  height: auto;
  max-height: 280px;
  width: 330px;
  margin: auto;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

.imagewidth {
  width: 706px;
  height: 406px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.imageleft {
  margin: auto;
}

.contenthead {
  text-align: left;
  font-size: 30px;
  font-family: "Montserrat Sans", sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  color: #FA7E5C;
  opacity: 1;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 30px;
}

.contentdesc {
  text-align: justify;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
}

.contentdesc_block {
  text-align: left;
  margin-top: 10px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
}

.contentlist {
  padding-top: 14px;
  margin-left: 10px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151515;
  opacity: 1;
}

ul {
  list-style-type: none;
}

ul li:before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  background-image: url("../image/tickbullet.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -35px;
}

li {
  padding-bottom: 10px;
}

li b {
  position: relative;
  left: 10px;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    padding: 15px 30px;
  }

  .cardtextbutton {
    width: 100%;
  }

  .alignleft {
    height: auto;
    max-height: 280px;
  }
}

@media only screen and (max-width: 900px),
  (height: 1024px) and (width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape),
  (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}
</style>
