import { render, staticRenderFns } from "./Designmortgage.vue?vue&type=template&id=b4ddfe2a&scoped=true&"
import script from "./Designmortgage.vue?vue&type=script&lang=js&"
export * from "./Designmortgage.vue?vue&type=script&lang=js&"
import style0 from "./Designmortgage.vue?vue&type=style&index=0&id=b4ddfe2a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4ddfe2a",
  null
  
)

export default component.exports