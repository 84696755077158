<template>
  <div>
    <v-carousel
      class="design2 hidden-md-and-down"
      :show-arrows="false"
      cycle
      hide-delimiter-background
    >
      <v-carousel-item>
        <div class="carditemblock">
          <v-card class="carditem" outlined flat>
            <v-avatar class="profile" width="100%" height="auto" tile>
              <img
                class="imageheader"
                :src="require('../image/' + imageNam1 + '')"
                :alt="blocktitle1"
              />
            </v-avatar>
            <h3 class="headerblack">{{ blocktitle1 }}</h3>
            <div class="contentgray">
              {{ blockdesc1 }}
            </div>
          </v-card>

          <v-card class="carditem" outlined flat>
            <v-avatar class="profile" width="100%" height="auto" tile>
              <img
                class="imageheader"
                :src="require('../image/' + imageNam2 + '')"
                :alt="blocktitle2"
              />
            </v-avatar>
            <h3 class="headerblack">{{ blocktitle2 }}</h3>
            <div class="contentgray">
              {{ blockdesc2 }}
            </div>
          </v-card>

          <v-card class="carditem" outlined flat>
            <v-avatar class="profile" width="100%" height="auto" tile>
              <img
                class="imageheader"
                :src="require('../image/' + imageNam3 + '')"
                :alt="blocktitle3"
              />
            </v-avatar>
            <h3 class="headerblack">{{ blocktitle3 }}</h3>
            <div class="contentgray">
              {{ blockdesc3 }}
            </div>
          </v-card>
        </div>
      </v-carousel-item>
    </v-carousel>

    <v-carousel
      class="design2 hidden-lg-and-up"
      show-arrows-on-hover
      cycle
      hide-delimiter-background
    >
      <v-carousel-item>
        <v-row dense>
          <v-col>
            <v-card class="carditem" outlined flat>
              <v-avatar class="profile" width="100%" height="auto" tile>
                <img
                  class="imageheader"
                  :src="require('../image/' + imageNam1 + '')"
                  :alt="blocktitle1"
                />
              </v-avatar>
              <h4 class="headerblack">{{ blocktitle1 }}</h4>
              <div class="contentgray">
                {{ blockdesc1 }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
      <v-carousel-item class="hidden-lg-and-up">
        <v-row dense>
          <v-col>
            <v-card class="carditem" outlined flat>
              <v-avatar class="profile" width="100%" height="auto" tile>
                <img
                  class="imageheader"
                  :src="require('../image/' + imageNam2 + '')"
                  :alt="blocktitle2"
                />
              </v-avatar>
              <h4 class="headerblack">{{ blocktitle2 }}</h4>
              <div class="contentgray">
                {{ blockdesc2 }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
      <v-carousel-item class="hidden-lg-and-up">
        <v-row dense>
          <v-col>
            <v-card class="carditem" outlined flat>
              <v-avatar class="profile" width="100%" height="auto" tile>
                <img
                  class="imageheader"
                  :src="require('../image/' + imageNam3 + '')"
                  :alt="blocktitle3"
                />
              </v-avatar>
              <h4 class="headerblack">{{ blocktitle3 }}</h4>
              <div class="contentgray">
                {{ blockdesc3 }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "Design2",
  props: [
    "imageNam1",
    "blocktitle1",
    "blockdesc1",
    "imageNam2",
    "blocktitle2",
    "blockdesc2",
    "imageNam3",
    "blocktitle3",
    "blockdesc3",
  ],
  data() {
    return {
      count: 3,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design2 {
  height: 425px !important;
  padding-left: 8em;
  padding-right: 8em;
  padding-top: 1.25em;
  background-color: white;
}

.carditemblock {
  display: flex;
  justify-content: space-between;
}
.carditem {
  max-width: 22em;
  min-width: 300px;
  height: 20em;
  /* margin: auto; */
}
.headerblack {
  /* color: black;
  padding-top: 10px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.27px;
  color: #151515;
  opacity: 1; */
  padding-top: 1.25em;
  font-family: "Montserrat", sans-serif;

  text-align: center;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
  font-size: 22px;
  font-weight: 600;
}

.content {
  width: 321px;
}
.contentgray {
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  font-size: 16px;
  letter-spacing: 0.24px;
  opacity: 1;
  color: #151515;
  padding-top: 5px;
}

.imageheader {
  /* background: transparent url("../image/image1.svg") 0% 0% no-repeat
    padding-box; */
  max-width: 32em;
  opacity: 1;
  border-radius: 10px;
  max-height: 200px;
}

@media only screen and (max-width: 1264px) {
  .design2 {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
  }

  .carditem {
    width: 100%;
    max-width: none;;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1370px) {


  .carditem {
    max-width: 20em;
  }
}

</style>
