<template>
    <div id="AboutUs" class="sectioncontent">
      <v-row> 
        <v-col lg="12" sm="12">
          <h2 class="contenthead">
            {{ header }}
          </h2>
          <p class="contentdesc">
            {{ desc1 }}
          </p>
          <p v-if="desc2" class="contentdesc">
            {{ desc2 }}
          </p>
  
          <v-row justify="center" class="accordiancont">
            <v-col>
  

            
  
  <p v-if="items2" class="cardtextcontent">
  <ul>
    <li v-for="item in items1" :key="item">
      {{ item }}
    </li>
  </ul>
  </p>
            
        

            </v-col>
            <v-col lg="4" sm="12" class="imageleft hidden-md-and-down">
              <img class="imagewidth" :src="require('../image/' + imagepath + '')" alt="Dynamic Images" />
            </v-col>
            <v-col>
         
  
              <p v-if="items2" class="cardtextcontent">
              <ul>
                <li v-for="item in items2" :key="item">
                  {{ item }}
                </li>
              </ul>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn class="cardtextbutton" to="/contact-us" rounded dark height="35px">
        Contact us
      </v-btn>
    </div>
  </template>
  
  <script>
  export default {
    name: "Design29",
    props: [
      "header",
      "desc1",
      "desc2",
      "imagepath",
      "button",
      "buttonval",
  
    ],
    data() {
      return {
        items1: [
        "Securitization reviews",
"Data integrity services",
"Regulatory compliance reviews",
"Trailing document services",
"Non-Performing “Distressed” Asset Review (NPL)",

"Loan Origination Software (LOS) Implementation Best Practices"

        ],
      
        items2: [
        "Performing/ Re-performing Asset Review (RPL)",
"MSR Loan Reviews",
"New Origination Reviews",
"Onsite or Offsite Originator Audits",
"Onsite or Offsite Servicing Audits",
        ],
  
      };
    },
    methods: {
      redirecttocontact() {
        this.$store.dispatch("changeHeader", "Contactus");
        return this.$router.push(`./contact-us`);
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .sectioncontent {
    margin-top: 35px;
    margin-left: 8em;
    margin-right: 8em;
    padding-bottom: 20px;
  }
  
  .imagewidth {
    /* box-shadow: 0px 0px 40px #0000003c; */
    width: 80%;
    border-radius: 10px;
    padding-top: 36px;
    /* margin-left: 60px; */
  }
  
  .imageleft {
    margin: auto;
    text-align: center;
  }
  
  .bannerbutton {
    margin-left: 0px;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.18px;
    color: #ffffff;
    opacity: 1;
  }

  .cardtextbutton {
  width: 120px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  margin-top: 14px !important;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin: auto;
}
  
  .contenthead {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    letter-spacing: 0.42px;
    font-size: 30px;
    color: #FA7E5C;
    opacity: 1;
    padding-bottom: 22px;
    padding-top: 0px;
  }
  
  .cardtextcontent {
    /* padding-left:16px; */
    /* margin-top: 30px; */
    text-align: justify;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
    padding-bottom: 0px;
  }
  
  ul {
    list-style: none;
    padding-left: 0px;
  }
  
  li {
    padding: 4px 15px 4px 30px;
    list-style: none;
    background-image: url("../image/tickbullet.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
    text-align: left;
  }
  
  .accordiancont {
    margin-top: 20px;
  }
  
  .headcontent {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.24px;
    color: #151515;
    padding-top: 24px;
    padding-bottom: 10px;
  }
  
  .contentdesc {
    text-align: justify;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
  }
  
  
  
  .contentlist {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
  }
  
  @media only screen and (max-width: 960px) {
    .sectioncontent {
      margin-left: 20px;
      margin-right: 20px;
    }
  
    .imagewidth {
      /* width: -webkit-fill-available; */
    }
  
    .bannerbutton {
      width: 100%;
    }
  
    .contenthead {
      text-align: left;
    }
  
    .contentdesc {
      text-align: justify;
    }
  
    .contentlist {
      text-align: center;
    }
  }
  </style>
  