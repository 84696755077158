<template>
  <div>
    <Bannerfulltrans
      imageName="realimage1.svg"
      imageName1="realimage2.svg"
      mobimageName="realimage3.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />

    <Design25
      header="Real Estate Tax Solutions"
      desc1="In line to our vision of positioning ourselves as a technology led services company, we developed FastTaxCert. This product generates customized tax certs to meet your requirement for Portfolio Management, Life of Loan – Tax Monitoring or Title Closing. We have automated 1800+ counties and offer nationwide coverage (Online, Calling & Mailaways).  FastTaxCert processed 2 million tax certs in 2021."
      button="Contact Us"
      :bulletinfo="bulletinfo"
      imagepath="realasset1.svg"
    />

    <Design27 />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design25 from "../components/Design25.vue";
import Design27 from "../components/Design27.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Realestatetax",
  data() {
    return {
      header1: "Real Estate Tax Solutions",
      desc1:
        "Nationwide tax and lien solutions offering quick turnaround time < 4 hours",
      button1: "",
      bulletinfo: [
        "Current Year Tax Summary",
        "Delinquency Reporting ",
        "Redemption ",
        "Municipal Lien ",
        "Reverse Tax Certificates ",
        "Tax Sale Information ",
        "Tax Lien Payoff ",
        "Municipal Lien Search ",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design25,
    Design27,
    // Design16,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
