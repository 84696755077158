<template>
  <div class="design3">
    <v-row>
      <v-col lg="6" sm="12" class="hidden-md-and-down"
        ><img
          class="bannerimage"
          src="../image/Fast_tax_certificate.webp"
          alt="Banner" /></v-col
      ><v-col class="design3content" lg="6" sm="12">
        <v-card class="leftcard" color="white" flat>
          <v-card-title class="cardtitle">
            <span>Our Services</span>
          </v-card-title>
          <v-card-text class="contenttext">
            Stellar Innovations has a thorough understanding of title insurance
            market & are well versed with its requirements. Our team of 600+
            certified examiners understand state statues and underwriting
            guidelines, thus delivering a quality product. We offer nationwide
            coverage through our online coverage and ground network of field
            abstractors. Get your reports delivered in 8-12 hours
            (online)!!</v-card-text
          >
          <v-toolbar color="white" class="tablemenu" align-left height="40">
            <template>
              <v-tabs v-model="tab" show-arrows>
                <v-tabs-slider
                  color="#FA7E5C"
                  width="175px"
                  height="4"
                ></v-tabs-slider>
                <v-tab class="Testtab" v-for="item in items" :key="item.menu">
                  {{ item.menu }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items class="cardtext" v-model="tab">
            <v-tab-item v-for="item in items" :key="item.text">
              <v-card flat>
                <p class="cardtextcontent">
                  {{ item.text }}
                </p>
                <p class="cardtextcontent">
                  <v-row class="hidden-sm-and-down"
                    ><v-col>
                      <ul>
                        <li v-for="item in item.takeaways" :key="item">
                          {{ item }}
                        </li>
                      </ul></v-col
                    ><v-col
                      ><ul>
                        <li v-for="item in item.takeaways1" :key="item">
                          {{ item }}
                        </li>
                      </ul></v-col
                    ></v-row
                  >
                  <v-row class="hidden-md-and-up"
                    ><v-col>
                      <ul>
                        <li v-for="item in item.takeaways" :key="item">
                          {{ item }}
                        </li>

                        <li v-for="item in item.takeaways1" :key="item">
                          {{ item }}
                        </li>
                      </ul></v-col
                    ></v-row
                  >
                </p>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design3",
  props: {},
  data() {
    return {
      tab: null,
      items: [
        {
          menu: "Title Search & Examination",
          text: `We have a Nationwide coverage through our Online and Vendor Network, our team of examiners have a thorough understanding on state Statues & examining guidelines to deliver best in class verified searches.`,
          takeaways: [
            "Equity Reports",
            "Current Owner Searches",
            "2 Owner Searches",
            "Full Searches ",
            "Legal & Vesting ",
          ],
          takeaways1: [
            "Loan Modification",
            "Foreclosure Searches",
            "TSG Searches ",
            "REO Searches ",
          ],
          button: "Get Started",
          route: "taxcert",
        },
        {
          menu: "Settlement Services",
          text: `We understand the criticality of the files when it comes to closing desk, quicker turnaround time and precision in getting tasks completed correctly eases lot of last-minute stress at the closing table. Our 24/5 coverage and weekend working on requirement basis help our clients with addressing consistency, scalability and volume volatility during the peaks and lows.`,
          takeaways: [
            "Open Orders",
            "Tax Certification",
            "CD Preparation ",
            "Ordering Loan Payoffs",
            "Recording Services (Paper & E- Recording)",
          ],
          takeaways1: [
            "Post close QC",
            "Ordering Utilities",
            "Lien Release Tracking ",
            "Ordering HOA’s ",
          ],
          button: "Get Started",
          route: "taxcert",
        },
        {
          menu: "Title Report Indexing & Data Extraction",
          text: `Our hybrid model of leveraging our UNIDEX platform and the blend of well-versed report verifiers are helping our clients deliver a well sequenced and detail-oriented reports helping clients to summarize the report in a consolidated way`,
          takeaways: [
            "Abstract Typing",
            "Commitment Typing",
            "Legal Description Typing",
            "Coversheet Preparation (Multiple product Lines)",
          ],
        },
      ],

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design3 {
  padding: 35px 8em 0px;
  background-color: white;
  /* margin-top: 30px; */
}

.design3 .row {
  margin: 0px;
}
.cardtext {
  width: 100% !important;
  /* height: 440px; */
}
.leftcard {
  padding-right: 20px;
}
.design3content {
  padding: 0px;
}
.bannerimage {
  margin-top: 60px;
  max-width: 100%;
}
.cardtitle {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0px;
  color: #FA7E5C;
  opacity: 1;
  padding: 16px 0px;
}

.Testtab {
  margin-right: 25px;
  text-transform: none;
}

.tablemenu {
  box-shadow: none !important;
  border-bottom: red;
  margin-top: 10px;
  float: left;
  /* padding-left:16px; */
}

.contenttext {
  padding: 0px 0px;
  margin-bottom: 16px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24;
  color: #151515;
  font-weight: 600;
}

.cardtextcontent {
  /* padding-left:16px; */
  margin-top: 30px;
  text-align: justify;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-bottom: 0px;
}

/* ul {
  list-style: none;
}

li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

li {
  padding-bottom: 10px;
} */
ul {
  list-style: none;
  padding-left: 0px;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 0px 0px 4px 40px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");

  background-repeat: no-repeat;
  background-position: 4px 0px;
  background-size: 25px;
  height: 35px;
}

.v-card__actions {
  padding: 0px;
}
.v-tab.v-tab {
  color: #fa7e5c;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  opacity: 1;
  padding: 0px;
}

.v-tab.v-tab--active {
  font-weight: bold;
}

.v-toolbar__content {
  float: left;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.33px;
  color: #fa7e5c;
  opacity: 1;
}

.cardtextbutton {
  font-size: 14px !important;
  font-weight: bold;
  margin-top: 10px;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin-left: 5px;
}

@media only screen and (max-width: 960px) {
  .design3 {
    padding: 40px 20px 0px;
  }

  .cardtitle span {
    word-break: break-word;
  }

  .cardtitle {
    text-align: center;
  }

  .tablemenu {
    float: none;
  }

  .leftcard {
    padding-right: 0px;
  }

  .tablemenu {
    max-width: 350px;
  }
}
</style>
