<template>
  <div class="productmenu">
    <vue-file-toolbar-menu :content="my_menu" class="topmenu" />
  </div>
</template>

<script>
import VueFileToolbarMenu from "vue-file-toolbar-menu";
import { mapState } from "vuex";
export default {
  components: { VueFileToolbarMenu },

  data() {
    return { happy: false };
  },
  methods: {
    changeheader(item) {
      this.$store.dispatch("changeHeader", item);
    },
  },
  computed: {
    ...mapState({
      selectedheader: (state) => state.headerbutton,
    }),
    my_menu() {
      return [
        {
          text: "PRODUCTS",
          active: this.selectedheader == "Products" ? true : false,
          menu_width: 220,
          chevron: true,
          menu: [
            {
              text: "FastTaxCert ",
              click: () => {
                this.$router.push(`./taxcert`);
                this.$store.dispatch("changeHeader", "Products");
              },
            },

            {
              text: "FastTax Monitoring",
              click: () => {
                this.$router.push(`./taxmonitor`);
                this.$store.dispatch("changeHeader", "Products");
              },
            },
            {
              text: "ULRS",
              click: () => {
                this.$router.push(`./ulrs`);
                this.$store.dispatch("changeHeader", "Products");
              },
            },
            {
              text: "UniDex",
              click: () => {
                this.$router.push(`./unidex`);
                this.$store.dispatch("changeHeader", "Products");
              },
            },
            // {
            //   text: "Automated AVR",
            //   click: () => {
            //     this.$router.push(`./automatedavr`);
            //     this.$store.dispatch("changeHeader", "Products");
            //   },
            // },
            
            // {
            //   text: "Fast L & V",
            //   click: () => {
            //     this.$router.push(`./fastlv`);
            //     this.$store.dispatch("changeHeader", "Products");
            //   },
            // },
          ],
        },
      ];
    },
  },
};
</script>


<style lang="scss" scoped>
.topmenu {
  height: 60px !important;
  justify-content: center;
  font-size: 14px;
  color: #858585 !important;
}

.bar .bar-button.active {
  color: #FA7E5C !important;
  text-decoration: underline;
text-underline-offset: 6px;
text-decoration-thickness:2px !important;
}
</style>