<template>
  <div>
    <Bannerfull
      imageName="bannertitle.webp"
      mobimageName="mob_bannertitle.jpg"
      :header="header1"
      :desc="desc1"
      :button="button1"
    />
    <Design14
      header=" Easement"
      desc1="Stellar Innovations provides a full range of title production services
          to agents, agencies, and title companies. Based on the requirements
          specific to the buying/selling/re-financing criteria, our experts
          ensure that the detailed reports are sourced from credible government
          records."
      desc2="Having understood the objectives of title production, Stellar delivers
          the most comprehensive title search reports that include verified
          ownership data, voluntary/involuntary monetary liens, property
          encumbrances, tax records, and much more. To discuss your requirements
          and for a custom quote, contact us today."
      imagepath="titleintro.webp"
      button="Contact Us"
    />
    <Design15 />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfull from "../components/Bannerfull.vue";
import Design14 from "../components/Design14.vue";
import Design15 from "../components/Design15.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Title",
  data() {
    return {
      header1: "",
      desc1: "",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    Bannerfull,
    Design14,
    Design15,
    // Design16,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
