<template>
  <div class="design4">
    <div class="roundcontent">
      <div class="roundcontenttext">
        <div class="titlecontent">
          <h2 class="sectiontitle">What’s Included in FastTaxCert</h2>
          <div class="sectionbody">
            As a customized digital solution, you can get all the tax details
            pertaining to properties and municipal lien searches.
          </div>
        </div>
        <div class="contentpadding hidden-md-and-down">
          <v-card class="cardcontent" flat max-width="350px">
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Property_Tax.svg" alt="Templates"/>
              <div class="cardtitle">Customized Tax Templates</div>
            </v-card-text>
          </v-card>

          <v-card class="cardcontent" flat max-width="350px">
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/County_Calling.svg" alt="Counties"/>
              <div class="cardtitle">1800+ Counties Automated</div>
            </v-card-text>
          </v-card>

          <v-card class="cardcontent" flat max-width="350px">
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Client_Portal.svg" alt="Coverage" />
              <div class="cardtitle">Nationwide Coverage</div>
            </v-card-text>
          </v-card>
        </div>
        <div class="contentpadding1 hidden-md-and-down">
          <v-card class="cardcontent" flat max-width="350px">
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/Tax_reports.svg" alt="Agency"/>
              <div class="cardtitle">Agency Specific Reports</div>
            </v-card-text>
          </v-card>

          <v-card
            class="cardcontent"
            style="padding-right=20px"
            flat
            max-width="350px"
          >
            <v-card-text class="iconview">
              <img
                class="LogoAnimation"
                src="../image/Gauranteed_Accuracy.svg"
                alt="Gauranteed_Accuracy"
              />
              <div class="cardtitle">
                Integrated Feedback & Reporting Module
              </div>
            </v-card-text>
          </v-card>

          <v-card class="cardcontent" flat max-width="350px">
            <v-card-text class="iconview">
              <img class="LogoAnimation" src="../image/IT_compliant.svg" alt=" API Plugins"/>
              <div class="cardtitle">
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;API
                Plugins&#160;&#160;&#160;&#160;&#160;&#160;&#160;
              </div>
            </v-card-text>
          </v-card>

          <!-- </v-row>
        <v-row class="contentpadding"> -->
        </div>

        <v-row class="contentpadding hidden-lg-and-up">
          <v-col>
            <v-card class="cardcontent" flat max-width="350px">
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Property_Tax.svg" alt="Property Tax"/>
                <div class="cardtitle">Customized Tax Templates</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-lg-and-up">
          <v-col>
            <v-card class="cardcontent" flat max-width="350px">
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/County_Calling.svg" alt="Calling"/>
                <div class="cardtitle">1800+ Counties Automated</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-lg-and-up">
          <v-col>
            <v-card class="cardcontent" flat max-width="350px">
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Client_Portal.svg" alt="Coverage"/>
                <div class="cardtitle">Nationwide Coverage</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-lg-and-up">
          <v-col>
            <v-card class="cardcontent" flat max-width="350px">
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Tax_reports.svg" alt="Specific Reports"/>
                <div class="cardtitle">Agency Specific Reports</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-lg-and-up">
          <v-col>
            <v-card
              class="cardcontents"
              style="padding-right=20px"
              flat
              max-width="350px"
            >
              <v-card-text class="iconview">
                <img
                  class="LogoAnimation"
                  src="../image/Gauranteed_Accuracy.svg"
                  alt="Gauranteed_Accuracy"
                />
                <div class="cardtitle">
                  InteIntegrated Feedback & Reporting Module
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="contentpadding hidden-lg-and-up">
          <v-col>
            <v-card class="cardcontent" flat max-width="350px">
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/IT_compliant.svg" alt="API Plugins"/>
                <div class="cardtitle">API Plugins</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Design4",
  props: {},
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.design4 {
  background-image: url(../image/square.webp);
  padding: 30px 8em;
  background-color: white;
  background-position: center;
  background-size: 80% 470px;
}

.iconview {
  text-align: center;

  /* box-shadow: 0 0 3px 2px #fff; */
}

.iconview .v-icon {
  font-size: 55px !important;
}
.titlecontent {
  width: 70%;
  margin: auto;
}

.smallbutton {
  /* padding: 0px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: 250px; */
  text-align: left;
}

.cardcontent {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
}

.cardcontents {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0px;
  padding-left: 25px;
}

.sectiondesc {
  margin-top: 30px;
}
.roundcontent {
  /* padding-bottom: 100px; */
  /* margin: auto; */

  /* background: transparent
    radial-gradient(
      closest-side at 50% 42%,
      #4a7e88 0%,
      #78b2bed1 0%,
      #3a565b00 100%
    )
    0% 300% no-repeat padding-box; */
}

.contentpadding {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}

.contentpadding1 {
  /* padding-left: 30px; */
  /* padding-right: 8px; */
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}

.LogoAnimation {
  max-width: 55px;
}
.roundcontenttext {
  margin: auto;
  text-align: center;
  opacity: 1;
}

.sectiontitle {
  padding-top: 30px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.24px;
  color: #FA7E5C;
  opacity: 1;
}

.sectionbody {
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  /* letter-spacing: 0.3px; */
  color: #151515;
  /* opacity: 0.5; */
}

.cardtitle {
  padding-top: 15px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.cardtitletext {
  padding-top: 12px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}

.v-card__text {
  padding: 0px !important;
}

.imagewidth {
  box-shadow: 0px 0px 40px #0000003c;
  width: 85%;
}

.imageleft {
  margin-top: 30px;
  text-align: center;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  letter-spacing: 0.42px;
  font-size: 24px;
  letter-spacing: 0.42px;
  color: #151515;
  opacity: 1;
  padding-bottom: 22px;
}

.contentlisthead {
  font-weight: bold;
}

.contentlistdesc {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0px;
  font-size: 14px;
}

.cardtextbutton {
  font-size: 0.7em !important;
  font-weight: bold;
  margin-top: 30px !important;
  background: #fa7e5c 0% 0% no-repeat padding-box !important;
  border-radius: 34px;
  opacity: 1;
  margin: auto;
}
.contentdesc {
  text-align: justify;
  font-family: "Mullish", sans-serif;
  font-size: 16px;
  letter-spacing: 0.27px;
  color: #151515;
  opacity: 0.5;
  /* padding-bottom: 40px; */
}

.contentlist {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
}
ul {
  list-style: none;
}
li::before {
  content: "•";
  color: orange;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5em;
}

@media only screen and (max-width: 960px) {
  .design4 {
    padding: 20px 20px;
    background-position: top 50% left 50%;
    background-image: none;
  }
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .titlecontent {
    width: 100%;
  }

  .cardcontent {
    max-width: 100% !important;
  }
  .cardcontents {
    max-width: 100% !important;
  }
  .contentpadding {
    padding-top: 12px;
  }
  .contentpadding1 {
    padding-top: 12px;
  }

  .imagewidth {
    /* width: -webkit-fill-available; */
  }

  .sectiontitle {
    padding-top: 0px;
  }

  .iconview,
  .cardtitle {
    text-align: center;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: justify;
  }
}
</style>
