<template>
  <div>
    <Bannerfulltrans
      imageName="settleimage1.svg"
      imageName1="settleimage2.svg"
      mobimageName="settleimage3.svg"
      :header="header1"
      :desc="desc1"
      :button="button1"
      block="righttransblockwhite"
      headert="headertranstextright"
      content="contenttranstextright"
    />
    <Design14
      header="Title & Settlement Services"
      desc1="Stellar Innovations provides a full range of title production services
          to agents, agencies, and title companies. Based on the requirements
          specific to the buying/selling/re-financing criteria, our experts
          ensure that the detailed reports are sourced from credible government
          records."
      desc2="Having understood the objectives of title production, Stellar delivers
          the most comprehensive title search reports that include verified
          ownership data, voluntary/involuntary monetary liens, property
          encumbrances, tax records, and much more. To discuss your requirements
          and for a custom quote, contact us today."
      imagepath="titlesettlement2.svg"
    />
    <Titlesettlementrange />
    <Rangetitlesettlement />
    <!-- <Design16 /> -->
    <Design10 />
  </div>
</template>

<script>
import Bannerfulltrans from "../components/Bannerfulltrans.vue";
import Design14 from "../components/Design14.vue";
import Titlesettlementrange from "../components/Titlesettlementrange.vue";

import Rangetitlesettlement from "../components/Range_Titlesettlement.vue";
// import Design16 from "../components/Design16.vue";
import Design10 from "../components/Design10.vue";
export default {
  name: "Titlesettlement",
  data() {
    return {
      header1: "Title & Settlement Services",
      desc1:
        "Offering end to end title settlement services in the title insurance space",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Our Services");
  },
  components: {
    Bannerfulltrans,
    Design14,
    Titlesettlementrange,
    Rangetitlesettlement,
    Design10,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}
</style>
