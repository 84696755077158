<template>
    <div class="design4">
      <div class="roundcontent">
        <div class="roundcontenttext">
          <div class="titlecontent">
            <div class="sectiontitle">
              Typical scope of work that we have managed through ULRS includes
            </div>
            <!-- <div class="sectionbody">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by injected humour
            </div> -->
          </div>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <!-- <v-icon large color="teal darken-2">
                    mdi-email
                  </v-icon> -->
                  <img class="LogoAnimation" src="../image/Ulrsmortagage.svg" alt="Mortgage"/>
                  <div class="cardtitle">Mortgage loan documents</div>
                  <p class="cardtitletext">
                    Identify and label received loan documents as per the standard stacking schema based on client/ investor preference.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/UlrsLoan.svg" alt="level"/>
                  <div class="cardtitle">Loan level details</div>
                  <p class="cardtitletext">
                    Capture and validate loan level details to drive quick data integrity
                     check facilitating data report as per the client/ investor requirement.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrscredit.svg" alt="Credit"/>
                  <div class="cardtitle">Credit review</div>
                  <p class="cardtitletext">
                    Review credit, income, and assets to validate and reconcile considered credit calculations.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrscollateral.svg" alt="Collateral"/>
                  <div class="cardtitle">Collateral review</div>
                  <p class="cardtitletext">
                    Review appraisal, title, note, deed, and other collateral documents.
                     Updated title and second appraisal can also be ordered based on client / investor requirement.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrscomplaince.svg" alt="Compliance"/>
                  <div class="cardtitle">Compliance review</div>
                  <p class="cardtitletext">
                    Review initial and closing disclosures along with other closing documents to
                     capture loan estimates and closing disclosures to run and review compliance report.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrspayment.svg" alt="Payment"/>
                  <div class="cardtitle">Payment history</div>
                  <p class="cardtitletext">
                    Review latest 12/24 months of payment history to capture the payment trail if applicable.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrscollection.svg" alt="Collection"/>
                  <div class="cardtitle">Collection comments</div>
                  <p class="cardtitletext">
                    Review latest 12/24 months of collection comments to understand current loan and property status,
                     last borrower contact and ongoing proceedings if any.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrsdefault.svg" alt="Default proceedings" />
                  <div class="cardtitle">Default proceedings</div>
                  <p class="cardtitletext">
                    Review default documents if loan is or have undergone default proceedings including bankruptcy and foreclosure.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="contentpadding hidden-md-and-up">
            <v-col sm="12" md="6">
              <v-card
                class="cardcontent"
                flat
                max-width="21.25em"
                min-width="300px"
              >
                <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrssecurization.svg" alt="Securitization" />
                  <div class="cardtitle">Securitization data & reporting</div>
                  <p class="cardtitletext">
                    Extract and reconcile key reports required from securitization standpoint.
                     Customise reports based on investor requirement can also be configured in ULRS.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
  
         
          <div class="contentpadding hidden-sm-and-down">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                  <img class="LogoAnimation" src="../image/Ulrsmortagage.svg" alt="Mortgage loan"/>
                  <div class="cardtitle">Mortgage loan documents</div>
                  <p class="cardtitletext">
                    Identify and label received loan documents as per the standard stacking schema based on client/ investor preference. 
                  </p>
              </v-card-text>
            </v-card>
  
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/UlrsLoan.svg" alt="Loan level details"/>
                  <div class="cardtitle">Loan level details</div>
                  <p class="cardtitletext">
                    Capture and validate loan level details to drive quick data integrity check facilitating
                     data report as per the client/ investor requirement.
                  </p>
              </v-card-text>
            </v-card>
  
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrscredit.svg" alt="Credit review"/>
                  <div class="cardtitle">Credit review</div>
                  <p class="cardtitletext">
                    Review credit, income, and assets to validate and reconcile considered credit calculations.
                  </p>
              </v-card-text>
            </v-card>
          </div>
          <div class="contentpadding hidden-sm-and-down">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrscollateral.svg" alt="Collateral review"/>
                  <div class="cardtitle">Collateral review</div>
                  <p class="cardtitletext">
                    Review appraisal, title, note, deed, and other collateral documents.
                     Updated title and second appraisal can also be ordered based on client / investor requirement.
                  </p>
              </v-card-text>
            </v-card>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrscomplaince.svg" alt="Compliance review"/>
                  <div class="cardtitle">Compliance review</div>
                  <p class="cardtitletext">
                    Review initial and closing disclosures along with other closing documents to
                     capture loan estimates and closing disclosures to run and review compliance report.
                  </p>
              </v-card-text>
            </v-card>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrspayment.svg" alt="Payment history"/>
                  <div class="cardtitle">Payment history</div>
                  <p class="cardtitletext">
                    Review latest 12/24 months of payment history to capture the payment trail if applicable.
                  </p>
              </v-card-text>
            </v-card>
            </div>
            <div class="contentpadding hidden-sm-and-down">
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrscollection.svg" alt="Collection comments" />
                  <div class="cardtitle">Collection comments</div>
                  <p class="cardtitletext">
                    Review latest 12/24 months of collection comments to understand current loan and property status,
                     last borrower contact and ongoing proceedings if any.
                  </p>
              </v-card-text>
            </v-card>
  
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrsdefault.svg" alt="ULRS"/>
                  <div class="cardtitle">Default proceedings</div>
                  <p class="cardtitletext">
                    Review default documents if loan is or have undergone default proceedings including bankruptcy and foreclosure.
                  </p>
              </v-card-text>
            </v-card>
            <v-card
              class="cardcontent"
              flat
              max-width="21.25em"
              min-width="300px"
            >
              <v-card-text class="iconview">
                <img class="LogoAnimation" src="../image/Ulrssecurization.svg" alt="ULRS Secure"/>
                  <div class="cardtitle">Securitization data & reporting</div>
                  <p class="cardtitletext">
                    Extract and reconcile key reports required from securitization standpoint.
                     Customise reports based on investor requirement can also be configured in ULRS.
                  </p>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Design4",
    props: {},
    data() {
      return {};
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .design4 {
    background-image: url(../image/circle.svg);
    padding: 40px 8em 120px;
    background-color: white;
    background-position: center;
    background-size: 100% 980px;
  }
  
  .iconview {
    text-align: left;
  
    /* box-shadow: 0 0 3px 2px #fff; */
  }
  
  .iconview .v-icon {
    font-size: 55px !important;
  }
  .titlecontent {
    width: 70%;
    margin: auto;
    padding-bottom: 20px;
  }
  
  .cardcontent {
    /* margin: auto; */
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0px;
    padding-right: 15px;
  }
  .roundcontent {
    /* margin-top: -120px auto 0px auto; */
    /* margin: auto; */
  
    /* background: transparent
      radial-gradient(
        closest-side at 50% 42%,
        #4a7e88 0%,
        #78b2bed1 0%,
        #3a565b00 100%
      )
      0% 300% no-repeat padding-box; */
  }
  
  .LogoAnimation {
    width: 55px;
    /* transform: rotateY(560deg);
    animation: turn 3.5s ease-out forwards 1s; */
  }
  
  @keyframes turn {
    100% {
      transform: rotateY(0deg);
    }
  }
  
  .contentpadding {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  .roundcontenttext {
    margin: auto;
    text-align: center;
    opacity: 1;
  }
  
  .sectiontitle {
    padding-top: 30px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.42px;
    color: #FA7E5C;
    opacity: 1;
  }
  
  .sectionbody {
    padding-top: 15px;
    text-align: center;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.24px;
    color: #151515;
    /* opacity: 0.5; */
  }
  
  .cardtitle {
    padding-top: 20px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
  }
  
  .cardtitletext {
    padding-top: 12px;
    text-align: justify;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #151515;
    opacity: 1;
    /* padding-right: 25px; */
  }
  
  .v-card__text {
    padding: 0px !important;
  }
  @media only screen and (max-width: 960px) {
    .design4 {
      padding: 20px 20px;
      background-position: top 50% left 50%;
      background-image: none;
    }
  
    .titlecontent {
      width: 100%;
    }
    .iconview,
    .cardtitle {
      text-align: center;
    }
    .cardcontent {
      max-width: 100% !important;
    }
  
    .contentpadding {
      padding-top: 0px;
    }
  }
  </style>
  