<template>
  <div class="sectioncontent">
    <v-row>
      <v-col lg="6" sm="12" class="imageleft">
        <img class="imagewidth" src="../image/fasttax_img.webp" alt="Benefits Banner" />
      </v-col>
      <v-col lg="6" sm="12">
        <h2 class="contenthead">
          Benefits of Intelligently Curated Tax Reports
        </h2>
        <p class="contentdesc">
          FastTax Certificate is a personalized as well as accelerated property
          tax calculation and reporting solution with enormous benefits for a
          variety of stakeholders. Our tax reports ensure validated data based
          on extensive legal & vesting research, owners’ county assessment,
          active liens and much more.
        </p>
        <ul class="contentlist">
          <li>
            <span>100% Accuracy</span>
          </li>
          <li>
            <span>Zero Delivery Delays</span>
          </li>
          <li>
            <span>1Million+ Tax Searches</span>
          </li>
          <li>
            <span>Guaranteed Data Privacy</span>
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Design16",
  props: {},
  data() {
    return {};
  },
};
</script>


<style scoped>
.sectioncontent {
  margin-top: 35px;
  margin-left: 8em;
  margin-right: 8em;
  padding-bottom: 25px;
}

.imagewidth {
  max-width: 500px;
  max-height: 500px;
  border-radius: 10px;
}

.imageleft {
  margin-top: 25px;
  text-align: left;
}

.contenthead {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: #FA7E5C;
  opacity: 1;
  padding: 16px 0px;
}

.contentdesc {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  /* opacity: 0.5; */
  /* padding-bottom: 40px; */
}

.contentlist {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
  opacity: 1;
  padding-left: 0px;
  padding-top: 10px;
}

ul {
  list-style: none;
}

li {
  margin: 10px 10px 10px 0px;
  padding: 0px 0px 10px 35px;
  list-style: none;
  background-image: url("../image/tickbullet.svg");
  background-repeat: no-repeat;

  background-size: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  color: #151515;
}

@media only screen and (max-width: 960px) {
  .sectioncontent {
    margin-left: 20px;
    margin-right: 20px;
  }

  .imagewidth {
    width: -webkit-fill-available;
  }

  .contenthead {
    text-align: center;
  }
  .contentdesc {
    text-align: justify;
  }
  .contentlist {
    text-align: left;
  }
}

@media only screen and (max-width: 1280px) {
  .imagewidth {
    width: 350px;
  }
}
</style>
