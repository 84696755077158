<template>
  <div>
    <Header />
    <Bannerfull1
      imageName1="Fasttaxcertbg.png"
      imageName2="fasttaximage2.svg"
      mobimageName="fasttaximage2.svg"
      mobimageName1="mobilebg.png"
      :desc="desc1"
      block="rightblockwhite1"
      headert="headertext"
      content="contentprodtextright"
    />
    <div class="smallblock hidden-md-and-down">
      <v-row>
        <v-col lg="3" sm="12">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/customized_reoprt.svg"
                  alt="Report"
                />
                <p class="block-title">Reports in 4 to 8 hours</p>
                <div class="block-desc">
                  Our Automation solutions deliver your customized property
                  reports faster
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/quick_turnaround.svg"
                  alt="TAT"
                />
                <p class="block-title">Guaranteed Accuracy</p>
                <div class="block-desc">
                  Independent QA team performs multilevel checks
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img class="LogoAnimationblock" src="../image/nationwide.svg" alt="Nationwide Coverage"/>
                <p class="block-title">Nationwide Coverage</p>
                <div class="block-desc">
                  Knowledge of every State jurisdiction and sources of retrieval
                  – City, Sewer, School, etc.
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/datasecurity.svg"
                  alt="Data Security"
                />
                <p class="block-title">Data Security</p>
                <div class="block-desc">
                  Data security is of prime importance in financial services
                  sector, and we got you covered. Your data is in safe hands!
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      >
    </div>

    <div class="smallblock hidden-lg-and-up">
      <v-row>
        <v-col lg="3" sm="12">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/customized_reoprt.svg"
                  alt="REport"
                />
                <p class="block-title">Reports in 4 to 8 hours</p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                  Our Automation solutions deliver your customized property
                  reports faster
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col> </v-row
      ><v-row>
        <v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/quick_turnaround.svg"
                  alt="TAT"
                />
                <p class="block-title" :class="{ 'on-hover': hover }">
                  Guaranteed Accuracy
                </p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                  Independent QA team performs multilevel checks
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img class="LogoAnimationblock" src="../image/nationwide.svg" alt="Nationwide"/>
                <p class="block-title" :class="{ 'on-hover': hover }">
                  Nationwide Coverage
                </p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                  Knowledge of every State jurisdiction and sources of retrieval
                  – City, Sewer, School, etc.
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      ><v-row
        ><v-col lg="3" sm="12"
          ><v-hover v-slot="{ hover }" open-delay="200">
            <v-card :elevation="hover ? 0 : 0" class="mx-auto" max-width="300">
              <v-card-text>
                <img
                  class="LogoAnimationblock"
                  src="../image/datasecurity.svg"
                  alt="Data"
                />
                <p class="block-title" :class="{ 'on-hover': hover }">
                  Data Security
                </p>
                <div class="block-desc" :class="{ 'on-hover': hover }">
                  Data security is of prime importance in financial services
                  sector, and we got you covered. Your data is in safe hands!
                </div>
              </v-card-text>
            </v-card>
          </v-hover></v-col
        ></v-row
      >
    </div>

    <Design14
      header="FastTaxCert"
      desc1="With a number of information pointers, property tax reports are not that simple and often cumbersome to prepare. Understanding the requirements of title agents, lenders and govt. agencies, Stellar Innovation has developed a packaged solution in the form of FastTax Certificate."
      desc2="With close to a decade of experience in delivering title production and settlement services, Stellar understands the need for accurate in-time tax reports for quick closures. Moreover, the agency-specific customized mortgage tax statements are what mortgage lenders and financial institutions need for an informed handover."
      imagepath="taxcert_image.webp"
      button="Ask for a quote"
      demobutton = "Login"
      logo="true"
      logoimage="ftc.png"
        demourl="https://www.fasttaxcert.com/"
    />
    <Taxcertrange />
    <Design16 />
    <Design17 />
    <Design10 />
  </div>
</template>

<script>
import Bannerfull1 from "../components/Bannerfull1.vue";
import Design10 from "../components/Design10.vue";
import Design14 from "../components/Design14.vue";
import Design16 from "../components/Design16.vue";
import Design17 from "../components/Design17.vue";
import Header from "../components/Header.vue";
import Taxcertrange from "../components/Range_Taxcert.vue";
export default {
  name: "Taxcert",
  data() {
    return {
      header1: "FastTaxCert",
      desc1: "Technology Driven, Quick and Accurate Tax Reporting",
      button1: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("changeHeader", "Products");
  },
  components: {
    Header,
    Bannerfull1,
    Design10,
    Design14,
    Design16,
    Design17,
    Taxcertrange,
  },
};
</script>

<style>
@media only screen and (max-width: 960px) {
  .v-window__next,
  .v-window__prev {
    top: calc(70% - 20px);
  }
}

.smallblock {
  margin: 70px 8em 20px;
}

.smallblock .v-card {
  max-height: 400px;
}

.LogoAnimationblock {
  max-width: 250px;
  width: auto;
  height: 175px;
}

@media only screen and (max-width: 960px) {
  .smallblock {
    margin: 20px 10px;
  }
}

.v-card.on-hover {
  /* background-color: rgb(236, 246, 248); */
  cursor: none;
}

.block-title {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.42px;
  padding-top: 30px;
  color: #000;
}

.block-desc {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.42px;
  color: #000;
}

/* .v-card.on-hover {
  color: #d7fff6;
} */
</style>
