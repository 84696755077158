<template>
  <div id="banner">
    <v-row no-gutters>
      <v-col class="hidden-md-and-down" lg="12" sm="12">
        <v-card flat tile color="transparent">
          <img
            class="bannerimage"
            :src="require('../image/' + imageName + '')"
            :alt="imageName"
          />
          <v-btn  v-if="ulrsbutton"
                                    class="bannerdembutton"
                                     rounded  
                                     color="#FA7E5C" 
                                     small 
                                      @click="redirecttostudio()">
                                      Explore Now
                                    </v-btn>
          <!-- <v-img class="bannerimage" :src="require('../image/' + imageName + '')" height="100%" eager/> -->
          <v-card :class="block" flat tile color="transparent">
            <p v-if="header" :class="headert">
              {{ header }}
            </p>
            <p v-if="desc" :class="content">
              {{ desc }}
            </p>
            <v-btn
              v-if="button"
              :class="bannerbutton"
              rounded
              height="35px"
              color="#FA7E5C"
              dark
              small
              :to="route"
            >
              <v-icon left dark> mdi-account </v-icon>
              {{ button }}
            </v-btn>
          </v-card>
        </v-card>
      </v-col>
      <v-col class="hidden-lg-and-up" lg="12" sm="12">
        <v-card flat tile color="transparent">
          <img
            class="mob_bannerimage"
            :src="require('../image/' + mobimageName + '')"
            :alt="mobimageName"
          />
          <v-btn  v-if = "ulrsbutton" class="bannermobdemobutton1" @click="redirecttostudio()" rounded color="#FA7E5C" small>
            <!-- <v-icon left dark> mdi-account </v-icon> -->
            Explore Now
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="header || desc || button" class="hidden-lg-and-up">
      <v-col sm="12">
        <v-card flat tile color="transparent">
          <p v-if="header" :class="header">
            {{ header }}
          </p>
          <p v-if="desc" :class="content">
            {{ desc }}
          </p>
          <v-btn
            v-if="button"
            :class="bannerbutton"
            rounded
            color="#FA7E5C"
            dark
            small
          >
            <v-icon left dark> mdi-account </v-icon>
            {{ button }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data: () => ({
    alignments: ["start", "center", "end"],
  }),
  props: [
    "imageName",
    "mobimageName",
    "header",
    "desc",
    "button",
    "ulrsbutton",
    "block",
    "route",
    "headert",
    "content",
    "bannerbutton",
  ],
  computed: {},
  methods: {
    redirecttostudio() {
      window.open(`https://stellarcreativehub.com/`, "_blank");
        },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.bannerimage {
  /* height: 373px; */
  width: 100%;
}

.mob_bannerimage {
  /* height: 200px; */
  width: 100%;
}

.bannermobdemobutton1 {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
position: initial;
  /* float: right; */
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
margin-top:-145px;

}

.bannerbutton {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
  margin-left: 15px;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: 25px;
}

.animate__animated.animate__slideInRight {
  --animate-duration: 3s;
}

.bannerbuttonright {
  margin-left: 15px;
  float: right;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: 25px;
}
.leftblock {
  position: absolute;
  top: 100px;
  max-width: 800px;
  padding-left: 8em;
}

.rightblock {
  position: absolute;
  top: 100px;
  max-width: 700px;

  right: 8em;
}

.bannerdembutton {
  /* margin-top: 19px;
  position: absolute !important;
  left: 20px !important; */
  margin-left: 8.5em;
  float: left;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.18px;
  color: #ffffff;
  opacity: 1;
  margin-top: -200px;
}

.rightblockwhite {
  color: white;
  position: absolute;
  top: 200px;
  max-width: 800px;
  padding-left: 100px;
  padding-right: 100px;
  right: 40px;
}

.rightblockwhite .headertext {
  color: #ffffff;
  text-align: right;
  padding-right: 0px;
}

.rightblockwhite .contenttext {
  color: #ffffff;
  text-align: right;
  padding: 0px;
}

.headertextright {
  padding: 0px;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #1c1c1c;
  opacity: 1;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.contenttextright {
  padding: 0px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: #1c1c1c;
  opacity: 1;
}

.headertext {
  padding: 0px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.32px;
  color: #1c1c1c;
  opacity: 1;
  padding-bottom: 0px;
  padding-right: 60px;
  margin-bottom: 0px;
}

.colorinherit {
  background-color: #bebebe1d;
}

.contenttext {
  padding: 0px 0px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: #1c1c1c;
  opacity: 1;
  padding-right: 100px;
}

#banner {
  margin-top: 60px;
  background-color: White;
}

@media only screen and (max-width: 960px) {
  #banner {
    /* margin-top: 60px; */
  }

  .bannerbutton {
    float: none;
    width: 80%;
  }

  .bannerbuttonright {
    float: none;
    width: 80%;
  }

  .headertext {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #1c1c1c;
    opacity: 1;
  }

  .headertext,
  .contenttext,
  .contenttextright,
  .headertextright {
    padding: 0px 10px;
    text-align: justify;
  }

  .bannerimage {
    max-width: -webkit-fill-available;
  }
}
</style>
