<template>
  <div>
    <v-btn
      :key="label.text"
      color="#FA7E5C"
      text
      class="headerbutton d-flex flex-row-reverse"
      :class="selectedheader === label.value ? 'active_header' : ''"
      @click="changeheader(label.value)"
    >
      {{ label.text }}
    </v-btn>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Headerbutton",
  props: {
    label: {
      type: Object,
      default: null,
    },
    flags: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      selectedheader: (state) => state.headerbutton,
    }),
  },
  methods: {
    changeheader(item) {
      if (item === "Home") {
        this.$router.push(`./`);
      } else {
        this.$router.push(`./${item}`);
      }

      this.$store.dispatch("changeHeader", item);
    },
  },
};
</script>

<style scoped>
.headerbutton {
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
  font-weight: bold;
  padding-right: 25px;
  padding-left: 25px;
  height: 60px !important;
  background-color: unset;
  text-align: left;
  letter-spacing: 0px;
  color: #858585 !important;
  opacity: 1;
}

/* @media only screen and (max-width: 960px) {
  .headerbutton .v-btn__content {
    padding-top: 30px !important;
  }
} */
.headerbutton:hover {
  /* transform: scale(1.05); */
}
.headerbutton_changecolor {
  background-color: grey;
  color: white;
}

.headerbutton_changecolor:hover {
  /* background-color: #16eae5; */
  color: white;
}

.headerbutton:hover {
  color: #FA7E5C !important;
  font-family: "Montserrat", sans-serif;
  /* background: #186e7e 0% 0% no-repeat padding-box; */
  border-radius: 0px 0px 5px 5px;
  opacity: 1;
}

.active_header {
  color: #FA7E5C !important;
  text-decoration: underline;
text-underline-offset: 6px;
text-decoration-thickness:2px !important;
  /* background-color: #186e7e !important; */
}
</style>
